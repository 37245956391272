import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { apiData, localData, runData } from "../../../../jsx/backend";
import { useParams } from "react-router-dom";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import getIcon from "../../../includes/icons";
import bgImg from "../../../../img/background.jpg";
import {
  Button,
  Dropdown,
  FloatingLabel,
  Form,
} from "react-bootstrap";

Modal.setAppElement("#root");

function Register() {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState(null);
  let { token } = useParams();
  const [report, setReporting] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [isValid, setIsValid] = useState({
    length: false,
    lowercase: false,
    uppercase: false,
    number: false,
    specialChar: false,
  });
  const [showChat, setShowChat] = useState(localData("get", "chatting"));
  const handleClick = () => {
    localData("save", "chatting", !showChat);
    setShowChat(!showChat);
  };
  const handleInfoToggle = () => {
    setShowInfo(!showInfo);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    const value = event.target.value;

    setIsValid({
      length: value.length >= 8,
      lowercase: /[a-z]/.test(value),
      uppercase: /[A-Z]/.test(value),
      number: /\d/.test(value),
      specialChar: /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(value),
    });
  };
  function isValidPassword(password) {
    if (!(password.length >= 8 && password.length <= 32)) {
      return false;
    }
    if (password.indexOf(" ") !== -1) {
      return false;
    }
    let count = 0;
    for (let i = 0; i <= 9; i++) {
      if (password.indexOf(i) !== -1) {
        count = 1;
      }
    }
    if (count === 0) {
      return false;
    }
    if (!/[!@#$%^&*()_+=[\]{};':"|,.<>/?]/.test(password)) {
      return false;
    }
    count = 0;
    for (let i = 65; i <= 90; i++) {
      if (password.indexOf(String.fromCharCode(i)) !== -1) {
        count = 1;
      }
    }
    if (count === 0) {
      return false;
    }
    count = 0;
    for (let i = 97; i <= 122; i++) {
      if (password.indexOf(String.fromCharCode(i)) !== -1) {
        count = 1;
      }
    }
    if (count === 0) {
      return false;
    }
    return true;
  }

  const handleSignup = async (event) => {
    event.preventDefault();
    if (isValidPassword(password)) {
      setLoading(true);
      const data = {
        creating: token,
        password: password,
      };

      const responseData = await runData(data, "/query/signup/create/");
      if (responseData.response) {
        switch (responseData.response.data.code) {
          case 200:
            const sessionFull = {
              name: responseData.response.data.name,
              role: responseData.response.data.role,
              token: responseData.response.data.session,
            };
            localData("save", "session", sessionFull);
            const time = new Date().getTime();
            localData("save", "expiry", time);
            setLoading(false);
            setMessage("You have set your password successfully!");
            localData("save", "messages", {
              user: {
                name: responseData.response.data.name,
                email: responseData.response.data.email,
                phone: responseData.response.data.phone,
              },
              thread: [
                {
                  sender: "bot",
                  text: "Hi there! I can assist you with our service information.",
                  date: new Date(),
                },
              ],
            });
            break;

          default:
            setLoading(false);
            setMessage(responseData.response.data.message);
        }
      } else if (responseData.error) {
        setLoading(false);
        setMessage(responseData.error.message);
      } else {
        setLoading(false);
        setMessage("An error occured while processing your request.");
      }
    } else {
    }
  };

  const customStyles = {
    overlay: {
      backgroundColor: "#ffffffeb",
      zIndex: 10000,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "transparent",
      border: "none",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minWidth: "30VW",
    },
  };

  const isAllValid = Object.values(isValid).every((val) => val === true);

  return (
    <div
      className="home"
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        wordWrap: "break-word",
        backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 0.77) 100%, rgb(255, 255, 255) 100%), url(${bgImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Helmet>
        <title>
          Set Password - {localData("get", "branding").company || "Company"}
        </title>
      </Helmet>
      <div className="card">
        <div
          className="card-header d-flex justify-content-between align-items-center mm-padding"
          style={{
            background: "#eee",
            position: "sticky",
            top: 0,
            zIndex: 9,
          }}
        >
          {localData("get", "branding").logo ? (
            <Link to="/">
              <img
                src={`${apiData.defaults.baseURL}${
                  localData("get", "branding").logo
                }`}
                alt="MENU"
                className="logo"
                style={{
                  borderWidth: "3px !important",
                  flexShrink: 0,
                  maxWidth: "100%",
                  maxHeight: "55px",
                }}
              />
            </Link>
          ) : (
            <Link
              to={`/`}
              style={{
                borderWidth: "3px !important",
                flexShrink: 0,
                height: "55px",
                width: "50%",
              }}
              className="parent-img"
            >
              <div
                className="mm-padding mm-margin"
                style={{
                  height: "200px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {getIcon("logo")}
              </div>
            </Link>
          )}
          <Dropdown>
            <Dropdown.Toggle
              variant="transparent"
              id="dropdown-basic"
              style={{ cursor: "pointer", border: "none" }}
              title="Options"
            >
              <span>INFORMATION</span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>
                <Link to="/privacy/" style={{ textDecoration: "none" }}>
                  Privacy Policy
                </Link>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="card-body mm-padding-large">
            <Form onSubmit={handleSignup}>
              <Form.Group controlId="formBasicEmail">
                <FloatingLabel
                  controlId="password"
                  label="New Password"
                  className="mb-3"
                >
                  <Form.Control
                    type="password"
                    placeholder="password"
                    value={password}
                    onChange={handlePasswordChange}
                    className="form-control form-line"
                    required
                  />
                </FloatingLabel>
              </Form.Group>
              <ul>
                <li style={{ color: isValid.length ? "green" : "red" }}>
                  At least 8 characters
                </li>
                <li style={{ color: isValid.lowercase ? "green" : "red" }}>
                  At least one lowercase letter
                </li>
                <li style={{ color: isValid.uppercase ? "green" : "red" }}>
                  At least one uppercase letter
                </li>
                <li style={{ color: isValid.number ? "green" : "red" }}>
                  At least one number
                </li>
                <li style={{ color: isValid.specialChar ? "green" : "red" }}>
                  At least one special character
                </li>
              </ul>
              <Button
                variant="success"
                type="submit"
                style={{ width: "100%" }}
                disabled={!isAllValid}
              >
                Activate Account
              </Button>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span className="btn btn-link" onClick={handleInfoToggle}>
                  Why am I here?
                </span>
                <span className="btn btn-link" onClick={handleClick}>Get Help</span>
              </div>
            </Form>
            {showInfo && (
              <div style={{ marginTop: "20px" }}>
                <h4>Why am I here?</h4>
                <p>
                  You are here to activate your account. Please enter your new
                  password in the field above and click "Activate Account" to
                  proceed. If you need further assistance, please click "Get
                  Help".
                </p>
              </div>
            )}
        </div>
        <div className="card-footer mm-light-grey">
          <br />
          <p className="mm-center">
            &copy;{new Date().getFullYear()}{" "}
            <a
              href={localData("get", "branding").website || "/"}
              style={{ textDecoration: "none", cursor: "pointer" }}
            >
              {localData("get", "branding").company || "Company"}
            </a>{" "}
            - All Rights Reserved
          </p>
        </div>
      </div>

      <Modal
        isOpen={report}
        onRequestClose={() => setReporting(false)}
        style={customStyles}
      >
        <div
          className="bg-secondary text-white"
          style={{
            maxWidth: "100%",
            width: "500px",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <h4>
            RESPONSE{" "}
            <span
              onClick={() => setReporting(false)}
              title="Close"
              className="mm-right"
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={faTimes} color="red" />
            </span>
          </h4>

          <hr />

          <p className="mm-center">{message}</p>

          <br />
          <button
            className="mm-btn mm-red"
            onClick={() => setReporting(false)}
            style={{ borderRadius: "30px", width: "100%" }}
          >
            CLOSE
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={loading}
        onRequestClose={() => setLoading(false)}
        style={customStyles}
      >
        <FontAwesomeIcon icon={faSpinner} color="#aaa" size="7x" spin />
      </Modal>
    </div>
  );
}

export default Register;
