import React from 'react';
import {Helmet} from "react-helmet";
import Top from '../includes/topping';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const Load = () => {

return (
<div style={{minHeight: "100VH", paddingTop: "40px", wordWrap : "break-word"}}>
<Helmet>
<title>Loading</title>
</Helmet>
<div className="mm-container mm-center" style={{marginTop: '20VH', paddingBottom: '5VH'}}>
      <div className="modules">
  <div title="Loading">
    <div className="mm-btn module zoom-on-hover">
      <div className="icon-container">
      <FontAwesomeIcon icon={faSpinner} spin size='5x' color='green' />
      </div>
      <div className="title-container">
        <p style={{color: "black"}}>LOADING</p>
      </div>
    </div>
  </div>
</div>
</div>
<Top />
</div>
);}

export default Load;