import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Helmet } from "react-helmet";
import { localData } from "../../../jsx/backend";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faBrush,
  faCloud,
  faFileAlt,
  faFileWord,
  faFolderClosed,
  faHistory,
  faListDots,
  faMinus,
  faPeopleGroup,
  faProjectDiagram,
  faRobot,
  faUserCog,
  faUsers,
  faUsersCog,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Container, Row } from "react-bootstrap";

const Home = () => {
  let links = {};

  switch (localData("get", "branding").level) {
    case 1:
      links.policies = "/policies/";
      links.templates = "/templates/";
      links.composer = "/composer/";
      links.projects = "/projects/";
      links.drive = "/drive/";
      links.records = "/records/";

      links.iconOne = faPeopleGroup;
      links.titleOne = "STAFF";
      links.linkOne = "/staff/";

      links.iconTwo = faUsers;
      links.titleTwo = "CUSTOMERS";
      links.linkTwo = "/customers/";

      links.iconThree = faBriefcase;
      links.titleThree = "STAFF";
      links.linkThree = "/companies/";
      break;

    case 2:
      links.policies = "/manage/policies/";
      links.templates = "/manage/templates/";
      links.composer = "/composer/";
      links.projects = "/projects/";
      links.drive = "/drive/";
      links.records = "/records/";

      links.iconOne = faPeopleGroup;
      links.titleOne = "STAFF";
      links.linkOne = "/manage/staff/";

      links.iconTwo = faUsers;
      links.titleTwo = "CUSTOMERS";
      links.linkTwo = "/manage/customers/";

      links.iconThree = faBrush;
      links.titleThree = "CUSTOMISATION";
      links.linkThree = "/customisation/";
      break;

    case 3:
      links.policies = "/access/policies/";
      links.templates = "/access/templates/";
      links.composer = "/composer/";
      links.projects = "/projects/";
      links.drive = "/drive/";
      links.records = "/records/";

      links.iconOne = faPeopleGroup;
      links.titleOne = "STAFF";
      links.linkOne = "/staff/";

      links.iconTwo = faBrush;
      links.titleTwo = "CUSTOMISATION";
      links.linkTwo = "/customisation/";

      links.iconThree = faUserCog;
      links.titleThree = "PROFILE";
      links.linkThree = "/account/";
      break;
  }
  return (
    <Container style={{ minHeight: "100VH", wordWrap: "break-word" }} fluid>
      <Helmet>
        <title>
          {localData("get", "branding").title || "Home"} -{" "}
          {localData("get", "branding").company || "Portal"}
        </title>
      </Helmet>
      <Row className="mt-3 mm-padding-large">
        <div
          className="mm-card mm-white mm-padding"
          style={{ borderRadius: "5px", marginBottom: "30px" }}
        >
          <div className="mm-border-bottom">
            {" "}
            <h4 className="mm-text-grey">
              <FontAwesomeIcon icon={faFolderClosed} /> Documents{" "}
              <span className="mm-right" style={{ textDecoration: "none" }}>
                <FontAwesomeIcon icon={faMinus} />
              </span>
            </h4>
          </div>
          <div className="features">
            <Link
              to={links.policies}
              className="feature zoom-on-hover"
              style={{ textDecoration: "none" }}
            >
              <FontAwesomeIcon icon={faFileAlt} size="5x" />
              <h4>POLICIES</h4>
            </Link>
            <Link
              to={links.templates}
              className="feature zoom-on-hover"
              style={{ textDecoration: "none" }}
            >
              <FontAwesomeIcon
                icon={faFileWord}
                size="5x"
                style={{ textDecoration: "none" }}
              />
              <h4>TEMPLATES</h4>
            </Link>
          </div>
        </div>
        <div
          className="mm-card mm-white mm-padding"
          style={{ borderRadius: "5px", marginBottom: "30px" }}
        >
          <div className="mm-border-bottom">
            {" "}
            <h4 className="mm-text-grey">
              <FontAwesomeIcon icon={faListDots} /> Services{" "}
              <span className="mm-right" style={{ textDecoration: "none" }}>
                <FontAwesomeIcon icon={faMinus} />
              </span>
            </h4>
          </div>
          <div className="features">
            <Link
              to={links.composer}
              className="feature zoom-on-hover"
              style={{ textDecoration: "none" }}
            >
              <FontAwesomeIcon icon={faRobot} size="5x" />
              <h4>COMPOSER</h4>
            </Link>
          </div>
        </div>
        <div
          className="mm-card mm-white mm-padding"
          style={{ borderRadius: "5px", marginBottom: "30px" }}
        >
          <div className="mm-border-bottom">
            {" "}
            <h4 className="mm-text-grey">
              <FontAwesomeIcon icon={faUsersCog} /> Accounts{" "}
              <span className="mm-right" style={{ textDecoration: "none" }}>
                <FontAwesomeIcon icon={faMinus} />
              </span>
            </h4>
          </div>
          <div className="features">
            <Link
              to={links.linkOne}
              className="feature zoom-on-hover"
              style={{ textDecoration: "none" }}
            >
              <FontAwesomeIcon icon={links.iconOne} size="5x" />
              <h4> {links.titleOne}</h4>
            </Link>
            {localData("get", "branding").level === 1 && (
              <Link
                to={links.linkTwo}
                className="feature zoom-on-hover"
                style={{ textDecoration: "none" }}
              >
                <FontAwesomeIcon icon={links.iconTwo} size="5x" />
                <h4> {links.titleTwo}</h4>
              </Link>
            )}
          </div>
        </div>
      </Row>
    </Container>
  );
};

export default Home;
