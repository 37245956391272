import React, { useState, useEffect, useRef } from 'react';
import {Helmet} from "react-helmet";
import Top from '../../includes/topping';
import { apiData, localData, cartData, runData }  from '../../../jsx/backend';
import { useLocation, Link } from 'react-router-dom';

const Company = () => {
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const currentPath = location.pathname;

return (
<div  style={{minHeight: "95VH", paddingTop: "60px", wordWrap : "break-word"}}>
<Helmet>
<title>Manage Customer:: { localData('get', 'branding').title || 'Page' } -  { localData('get', 'branding').company || 'Company' }</title>
</Helmet>
<div className="mm-row-padding">
<div className="mm-twothird">
<p className="mm-panel mm-white mm-padding" style={{marginBottom: "30px", position: "sticky", top: "60px"}}><i className="fas fa-home"></i> <Link to="/">HOME</Link> / <Link to="/customers/">CUSTOMERS</Link> / <Link to={currentPath}>CUSTOMER</Link> <span className='mm-right' style={{cursor: "pointer"}} title='Add User'><i className="fas fa-plus"></i> ADD</span></p>
<div className="mm-container">

</div>
</div>
<br />
<div className="mm-third mm-padding" style={{top: "70px", position: "sticky"}}>
<div className="mm-card mm-padding" style={{borderRadius: "10px", width: "100%"}}>
<h4 >Manage Customer <span className='mm-right'><i className="fas fa-cog"></i></span></h4>
<hr />

</div>
</div>
</div>
<br />
<Top />
</div>
);}

export default Company;