import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Top from "../../includes/topping";
import { Link } from "react-router-dom";
import { apiData, localData, runData } from "../../../jsx/backend";
import Loader from "../../includes/loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  faArrowRight,
  faDashboard,
  faFileDownload,
  faHistory,
  faNoteSticky,
  faPlusCircle,
  faPlusSquare,
  faTicketAlt,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import { Button, Card, Col, Container, Dropdown, Form, ListGroup, Pagination, Row, Table } from "react-bootstrap";
import SideBar from "../../sections/sidebar";

Modal.setAppElement("#root");

const Customers = () => {
  const [customers, setCustomers] = useState([]);
  const [providers, setProviders] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const [loading, setLoading] = useState(true);
  const [running, setRunning] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [report, setReporting] = useState(false);

  const [creator, setCreator] = useState(false);

  const [step, setStep] = useState(1);
  const [company, setCompany] = useState("");
  const [logo, setLogo] = useState(null);
  const [website, setWebsite] = useState("");
  const [email, setEmail] = useState("");
  const [verified, setVerified] = useState("");
  const [manager, setManager] = useState("");
  const [nominated, setNominated] = useState("");
  const [region, setRegion] = useState("");
  const [county, setCounty] = useState("");
  const [city, setCity] = useState("");
  const [postcode, setPostcode] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [mobile, setMobile] = useState("");
  const [aname, setAname] = useState("");
  const [aaddress, setAaddress] = useState("");
  const [aphone, setAphone] = useState("");
  const [aemail, setAemail] = useState("");
  const [awebsite, setAwebsite] = useState("");
  const [arep, setArep] = useState("");
  const [provider, setProvider] = useState("");
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 25;

  const filteredCustomers = customers.filter((customer) =>
    `${customer.company}`
      .toLowerCase()
      .includes(search.toLowerCase())
  );

  const totalPages = Math.ceil(filteredCustomers.length / itemsPerPage);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };
  const [stats, setStats] = useState(false);

  
  const listCustomers = async () => {
    const data = {};
    const responseData = await runData(data, "/query/customers/");
    setLoading(false);
    setError(false);
    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          setCustomers(responseData.response.data.customers);
          setProviders(responseData.response.data.providers);
          setLoaded(true);
          break;

        default:
          setMessage(responseData.response.data.message);
          setError(true);
      }
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setError(true);
    } else {
      setMessage("An error occured while processing your request.");
      setError(true);
    }
  };

  useEffect(() => {
    listCustomers();
  }, []);

  const handleReload = () => {
    listCustomers();
  };

  const createCustomer = async (event) => {
    event.preventDefault();
    setCreator(false);
    setRunning(true);
    const data = {
      provider: provider,
      company: company,
      logo: logo,
      website: website,
      email: email,
      verified: verified,
      manager: manager,
      nominated: nominated,
      region: region,
      county: county,
      city: city,
      postcode: postcode,
      address: address,
      phone: phone,
      mobile: mobile,
      aname: aname,
      aaddress: aaddress,
      aphone: aphone,
      aemail: aemail,
      awebsite: awebsite,
      arep: arep,
    };
    for (let key in data) {
      if (data[key] === "") {
        setRunning(false);
        setMessage(`Please fill in the ${key} field. It is required`);
        setReporting(true);
        return;
      }
    }
    const responseData = await runData(
      data,
      "/query/customers/create/profile/"
    );
    setRunning(false);
    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          setMessage(
            <span>
              New customer has been created!{" "}
              <Link to={`/customers/${responseData.response.data.reference}/`}>
                Click here to manage customer.
              </Link>
            </span>
          );
          listCustomers();
          setCompany("");
          setLogo("");
          setWebsite("");
          setEmail("");
          setVerified("");
          setManager("");
          setNominated("");
          setRegion("");
          setCounty("");
          setCity("");
          setPostcode("");
          setAddress("");
          setPhone("");
          setMobile("");
          setAname("");
          setAaddress("");
          setAphone("");
          setAemail("");
          setAwebsite("");
          setArep("");
          setReporting(true);
          setStep(1);
          break;

        default:
          setMessage(responseData.response.data.message);
          setReporting(true);
      }
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setReporting(true);
    } else {
      setMessage("An error occured while processing your request.");
      setReporting(true);
    }
  };

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      zIndex: 10000,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "transparent",
      border: "none",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minWidth: "30VW",
    },
  };

  return (
    <Container
      style={{ minHeight: "95VH", wordWrap: "break-word" }}
      fluid
    >
      <Helmet>
        <title>
          Customers - {localData("get", "branding").customer || "Portal"}
        </title>
      </Helmet>

      <Row className="mt-3 mm-padding-large">
<Col md={9}>

<Card id="customers" className="mb-4">
        <Card.Header>
          <Row className="d-flex align-items-center justify-content-between">
            <Col md={6}>
              <h6>Customer Accounts</h6>
            </Col>
            <Col
              md={6}
              className="d-flex align-items-start justify-content-end"
            >
              <Button
                variant="link"
                onClick={() => setCreator(true)}
                style={{ color: "#aaa", textDecoration: "none" }}
                title="Add Staff"
              >
                <FontAwesomeIcon icon={faPlusSquare} /> Add
              </Button>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Form.Control
            type="text"
            className="no-outline mm-pale-green"
            style={{ borderRadius: "0px" }}
            placeholder="Search customer..."
            value={search}
            onChange={handleSearchChange}
          />

          <Table className="no-border" striped borderless responsive>
            <thead>
              <tr>
                <th>Company</th>
                <th>Email</th>
                <th>Phone</th>
                <th className="mm-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredCustomers
                .slice(
                  (currentPage - 1) * itemsPerPage,
                  currentPage * itemsPerPage
                )
                .map((customer, index) => (
                  <tr key={index}>
                    <td
                      style={{
                        backgroundColor: customer.status === 2 && "red", 
                        verticalAlign: "middle",
                      }}
                    >
                      {customer.company}
                    </td>
                    <td
                      style={{
                        backgroundColor: customer.status === 2 && "red", 
                        verticalAlign: "middle",
                      }}
                    >
                      {customer.email}
                    </td>
                    <td
                      style={{
                        backgroundColor: customer.status === 2 && "red", 
                        verticalAlign: "middle",
                      }}
                    >
                      {customer.phone}
                    </td>
                    <td
                      style={{
                        backgroundColor: customer.status === 2 && "red", 
                        verticalAlign: "middle",
                      }}
                    >
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="light"
                          id="dropdown-basic"
                          className="mm-color no-outline"
                          style={{ width: "100%" }}
                        >
                          Manage Customer
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={{ width: "100%" }}>
                        <Link to={`/customers/${customer.reference}/`} className="dropdown-item">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              View Customer
                              <FontAwesomeIcon icon={faArrowRight} />
                            </div>
                          </Link>
                        {customer.status === 2
                        ?
                          <Dropdown.Item>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              Activate Customer
                              <FontAwesomeIcon icon={faArrowRight} />
                            </div>
                          </Dropdown.Item>
                          : 
                          <Dropdown.Item>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              Suspend Customer
                              <FontAwesomeIcon icon={faArrowRight} />
                            </div>
                          </Dropdown.Item>
}
                          <Dropdown.Item>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              Edit Details
                              <FontAwesomeIcon icon={faArrowRight} />
                            </div>
                          </Dropdown.Item>

                          <Dropdown.Item>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              Delete Customer
                              <FontAwesomeIcon icon={faArrowRight} />
                            </div>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              <br style={{ marginTop: "20px" }} />
            </tbody>
          </Table>

          <Pagination>
            <Pagination.First
              onClick={() => handlePageClick(1)}
              disabled={currentPage === 1}
            />
            <Pagination.Prev
              onClick={() => handlePageClick(currentPage - 1)}
              disabled={currentPage === 1}
            />
            {currentPage > 2 && (
              <Pagination.Item onClick={() => handlePageClick(currentPage - 2)}>
                {currentPage - 2}
              </Pagination.Item>
            )}
            {currentPage > 1 && (
              <Pagination.Item onClick={() => handlePageClick(currentPage - 1)}>
                {currentPage - 1}
              </Pagination.Item>
            )}
            <Pagination.Item active>{currentPage}</Pagination.Item>
            {currentPage < totalPages && (
              <Pagination.Item onClick={() => handlePageClick(currentPage + 1)}>
                {currentPage + 1}
              </Pagination.Item>
            )}
            {currentPage < totalPages - 1 && (
              <Pagination.Item onClick={() => handlePageClick(currentPage + 2)}>
                {currentPage + 2}
              </Pagination.Item>
            )}
            <Pagination.Next
              onClick={() => handlePageClick(currentPage + 1)}
              disabled={currentPage === totalPages}
            />
            <Pagination.Last
              onClick={() => handlePageClick(totalPages)}
              disabled={currentPage === totalPages}
            />
          </Pagination>
        </Card.Body>
      </Card>
          </Col>
          <SideBar />
      </Row>

      <Modal isOpen={running} style={customStyles}>
        <div
          className="mm-center"
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <h4 style={{ color: "#343a40" }}>Please wait...</h4>
          <br />
          <Loader />
        </div>
      </Modal>

      <Modal
        isOpen={report}
        onRequestClose={() => setReporting(false)}
        style={customStyles}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <h4>
            Message{" "}
            <span
              onClick={() => setReporting(false)}
              title="Close"
              className="mm-right"
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={faTimes} color="red" />
            </span>
          </h4>

          <hr />

          <p className="mm-center">{message}</p>

          <br />
          <button
            className="mm-btn mm-red"
            onClick={() => setReporting(false)}
            style={{ borderRadius: "30px", width: "100%" }}
          >
            CLOSE
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={creator}
        onRequestClose={() => setCreator(false)}
        style={customStyles}
      >
        <form
          onSubmit={createCustomer}
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
          encType="multipart/form-data"
        >
          <h4
            style={{
              textAlign: "center",
              color: "#343a40",
              marginBottom: "20px",
            }}
          >
            Create Customer
          </h4>
          <br />
          {step === 1 && (
            <div>
              <label className="mm-label-create">
                What is the company name?
              </label>
              <input
                type="text"
                value={company}
                className="mm-input-create"
                onChange={(e) => setCompany(e.target.value)}
              />
              <br />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <button
                  onClick={() => setCreator(false)}
                  className="mm-left"
                  type="button"
                  style={{
                    backgroundColor:
                      localData("get", "branding").shade || "#eeeeee",
                    width: "48%",
                    color: "#fff",
                    padding: "10px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                    fontSize: "16px",
                  }}
                >
                  CANCEL
                </button>
                <button
                  onClick={nextStep}
                  className="mm-right"
                  type="button"
                  style={{
                    backgroundColor:
                      localData("get", "branding").color || "#eeeeee",
                    width: "48%",
                    color: "#fff",
                    padding: "10px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                    fontSize: "16px",
                  }}
                >
                  NEXT
                </button>
              </div>
            </div>
          )}

          {step === 2 && (
            <div>
              <label className="mm-label-create">
                What is their email address?
              </label>
              <input
                className="mm-input-create"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <br />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <button
                  onClick={prevStep}
                  className="mm-left"
                  type="button"
                  style={{
                    backgroundColor:
                      localData("get", "branding").shade || "#eeeeee",
                    width: "48%",
                    color: "#fff",
                    padding: "10px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                    fontSize: "16px",
                  }}
                >
                  BACK
                </button>
                <button
                  onClick={nextStep}
                  className="mm-right"
                  type="button"
                  style={{
                    backgroundColor:
                      localData("get", "branding").color || "#eeeeee",
                    width: "48%",
                    color: "#fff",
                    padding: "10px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                    fontSize: "16px",
                  }}
                >
                  NEXT
                </button>
              </div>
            </div>
          )}

          {step === 3 && (
            <div>
              <label className="mm-label-create">
                What is their website address?
              </label>
              <input
                className="mm-input-create"
                type="text"
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
              />
              <br />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <button
                  onClick={prevStep}
                  className="mm-left"
                  type="button"
                  style={{
                    backgroundColor:
                      localData("get", "branding").shade || "#eeeeee",
                    width: "48%",
                    color: "#fff",
                    padding: "10px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                    fontSize: "16px",
                  }}
                >
                  BACK
                </button>
                <button
                  onClick={nextStep}
                  className="mm-right"
                  type="button"
                  style={{
                    backgroundColor:
                      localData("get", "branding").color || "#eeeeee",
                    width: "48%",
                    color: "#fff",
                    padding: "10px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                    fontSize: "16px",
                  }}
                >
                  NEXT
                </button>
              </div>
            </div>
          )}
          {step === 4 && (
            <div>
              <label className="mm-label-create">
                What is their office address?
              </label>
              <input
                className="mm-input-create"
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
              <br />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <button
                  onClick={prevStep}
                  className="mm-left"
                  type="button"
                  style={{
                    backgroundColor:
                      localData("get", "branding").shade || "#eeeeee",
                    width: "48%",
                    color: "#fff",
                    padding: "10px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                    fontSize: "16px",
                  }}
                >
                  BACK
                </button>
                <button
                  onClick={nextStep}
                  className="mm-right"
                  type="button"
                  style={{
                    backgroundColor:
                      localData("get", "branding").color || "#eeeeee",
                    width: "48%",
                    color: "#fff",
                    padding: "10px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                    fontSize: "16px",
                  }}
                >
                  NEXT
                </button>
              </div>
            </div>
          )}
          {step === 5 && (
            <div>
              <label className="mm-label-create">
                What is their office phone number?
              </label>
              <input
                className="mm-input-create"
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              <br />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <button
                  onClick={prevStep}
                  className="mm-left"
                  type="button"
                  style={{
                    backgroundColor:
                      localData("get", "branding").shade || "#eeeeee",
                    width: "48%",
                    color: "#fff",
                    padding: "10px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                    fontSize: "16px",
                  }}
                >
                  BACK
                </button>
                <button
                  onClick={nextStep}
                  className="mm-right"
                  type="button"
                  style={{
                    backgroundColor:
                      localData("get", "branding").color || "#eeeeee",
                    width: "48%",
                    color: "#fff",
                    padding: "10px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                    fontSize: "16px",
                  }}
                >
                  NEXT
                </button>
              </div>
            </div>
          )}
          {step === 6 && (
            <div>
              <label className="mm-label-create">
                Who is their registered manager?
              </label>
              <input
                className="mm-input-create"
                type="text"
                value={manager}
                onChange={(e) => setManager(e.target.value)}
              />
              <br />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <button
                  onClick={prevStep}
                  className="mm-left"
                  type="button"
                  style={{
                    backgroundColor:
                      localData("get", "branding").shade || "#eeeeee",
                    width: "48%",
                    color: "#fff",
                    padding: "10px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                    fontSize: "16px",
                  }}
                >
                  BACK
                </button>
                <button
                  onClick={nextStep}
                  className="mm-right"
                  type="button"
                  style={{
                    backgroundColor:
                      localData("get", "branding").color || "#eeeeee",
                    width: "48%",
                    color: "#fff",
                    padding: "10px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                    fontSize: "16px",
                  }}
                >
                  NEXT
                </button>
              </div>
            </div>
          )}
          {step === 7 && (
            <div>
              <label className="mm-label-create">
                Who reviews their policies?
              </label>
              <input
                className="mm-input-create"
                type="text"
                value={verified}
                onChange={(e) => setVerified(e.target.value)}
              />
              <br />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <button
                  onClick={prevStep}
                  className="mm-left"
                  type="button"
                  style={{
                    backgroundColor:
                      localData("get", "branding").shade || "#eeeeee",
                    width: "48%",
                    color: "#fff",
                    padding: "10px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                    fontSize: "16px",
                  }}
                >
                  BACK
                </button>
                <button
                  onClick={nextStep}
                  className="mm-right"
                  type="button"
                  style={{
                    backgroundColor:
                      localData("get", "branding").color || "#eeeeee",
                    width: "48%",
                    color: "#fff",
                    padding: "10px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                    fontSize: "16px",
                  }}
                >
                  NEXT
                </button>
              </div>
            </div>
          )}
          {step === 8 && (
            <div>
              <label className="mm-label-create">
                Who is their nominated individual?
              </label>
              <input
                className="mm-input-create"
                type="text"
                value={nominated}
                onChange={(e) => setNominated(e.target.value)}
              />
              <br />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <button
                  onClick={prevStep}
                  className="mm-left"
                  type="button"
                  style={{
                    backgroundColor:
                      localData("get", "branding").shade || "#eeeeee",
                    width: "48%",
                    color: "#fff",
                    padding: "10px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                    fontSize: "16px",
                  }}
                >
                  BACK
                </button>
                <button
                  onClick={nextStep}
                  className="mm-right"
                  type="button"
                  style={{
                    backgroundColor:
                      localData("get", "branding").color || "#eeeeee",
                    width: "48%",
                    color: "#fff",
                    padding: "10px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                    fontSize: "16px",
                  }}
                >
                  NEXT
                </button>
              </div>
            </div>
          )}
          {step === 9 && (
            <div>
              <label className="mm-label-create">
                What is their mobile phone number?
              </label>
              <input
                className="mm-input-create"
                type="text"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
              />
              <br />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <button
                  onClick={prevStep}
                  className="mm-left"
                  type="button"
                  style={{
                    backgroundColor:
                      localData("get", "branding").shade || "#eeeeee",
                    width: "48%",
                    color: "#fff",
                    padding: "10px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                    fontSize: "16px",
                  }}
                >
                  BACK
                </button>
                <button
                  onClick={nextStep}
                  className="mm-right"
                  type="button"
                  style={{
                    backgroundColor:
                      localData("get", "branding").color || "#eeeeee",
                    width: "48%",
                    color: "#fff",
                    padding: "10px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                    fontSize: "16px",
                  }}
                >
                  NEXT
                </button>
              </div>
            </div>
          )}
          {step === 10 && (
            <div>
              <label className="mm-label-create">Select their region?</label>
              <select
                className="mm-input-create"
                value={region}
                onChange={(e) => setRegion(e.target.value)}
              >
                <option value="">Select a region</option>
                <option value="England">England</option>
                <option value="Scotland">Scotland</option>
                <option value="Wales">Wales</option>
                <option value="Northern Ireland">Northern Ireland</option>
              </select>
              <br />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <button
                  onClick={prevStep}
                  className="mm-left"
                  type="button"
                  style={{
                    backgroundColor:
                      localData("get", "branding").shade || "#eeeeee",
                    width: "48%",
                    color: "#fff",
                    padding: "10px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                    fontSize: "16px",
                  }}
                >
                  BACK
                </button>
                <button
                  onClick={nextStep}
                  className="mm-right"
                  type="button"
                  style={{
                    backgroundColor:
                      localData("get", "branding").color || "#eeeeee",
                    width: "48%",
                    color: "#fff",
                    padding: "10px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                    fontSize: "16px",
                  }}
                >
                  NEXT
                </button>
              </div>
            </div>
          )}
          {step === 11 && (
            <div>
              <label className="mm-label-create">
                They are in which county?
              </label>
              <input
                className="mm-input-create"
                type="text"
                value={county}
                onChange={(e) => setCounty(e.target.value)}
              />
              <br />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <button
                  onClick={prevStep}
                  className="mm-left"
                  type="button"
                  style={{
                    backgroundColor:
                      localData("get", "branding").shade || "#eeeeee",
                    width: "48%",
                    color: "#fff",
                    padding: "10px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                    fontSize: "16px",
                  }}
                >
                  BACK
                </button>
                <button
                  onClick={nextStep}
                  className="mm-right"
                  type="button"
                  style={{
                    backgroundColor:
                      localData("get", "branding").color || "#eeeeee",
                    width: "48%",
                    color: "#fff",
                    padding: "10px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                    fontSize: "16px",
                  }}
                >
                  NEXT
                </button>
              </div>
            </div>
          )}

          {step === 12 && (
            <div>
              <label className="mm-label-create">
                What is the name of their city?
              </label>
              <input
                className="mm-input-create"
                type="text"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
              <br />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <button
                  onClick={prevStep}
                  className="mm-left"
                  type="button"
                  style={{
                    backgroundColor:
                      localData("get", "branding").shade || "#eeeeee",
                    width: "48%",
                    color: "#fff",
                    padding: "10px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                    fontSize: "16px",
                  }}
                >
                  BACK
                </button>
                <button
                  onClick={nextStep}
                  className="mm-right"
                  type="button"
                  style={{
                    backgroundColor:
                      localData("get", "branding").color || "#eeeeee",
                    width: "48%",
                    color: "#fff",
                    padding: "10px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                    fontSize: "16px",
                  }}
                >
                  NEXT
                </button>
              </div>
            </div>
          )}

          {step === 13 && (
            <div>
              <label className="mm-label-create">
                What is their post code?
              </label>
              <input
                className="mm-input-create"
                type="text"
                value={postcode}
                onChange={(e) => setPostcode(e.target.value)}
              />
              <br />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <button
                  onClick={prevStep}
                  className="mm-left"
                  type="button"
                  style={{
                    backgroundColor:
                      localData("get", "branding").shade || "#eeeeee",
                    width: "48%",
                    color: "#fff",
                    padding: "10px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                    fontSize: "16px",
                  }}
                >
                  BACK
                </button>
                <button
                  onClick={nextStep}
                  className="mm-right"
                  type="button"
                  style={{
                    backgroundColor:
                      localData("get", "branding").color || "#eeeeee",
                    width: "48%",
                    color: "#fff",
                    padding: "10px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                    fontSize: "16px",
                  }}
                >
                  NEXT
                </button>
              </div>
            </div>
          )}
          {step === 14 && (
            <div>
              <label className="mm-label-create">Name of local authority</label>
              <input
                className="mm-input-create"
                type="text"
                value={aname}
                onChange={(e) => setAname(e.target.value)}
              />
              <br />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <button
                  onClick={prevStep}
                  className="mm-left"
                  type="button"
                  style={{
                    backgroundColor:
                      localData("get", "branding").shade || "#eeeeee",
                    width: "48%",
                    color: "#fff",
                    padding: "10px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                    fontSize: "16px",
                  }}
                >
                  BACK
                </button>
                <button
                  onClick={nextStep}
                  className="mm-right"
                  type="button"
                  style={{
                    backgroundColor:
                      localData("get", "branding").color || "#eeeeee",
                    width: "48%",
                    color: "#fff",
                    padding: "10px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                    fontSize: "16px",
                  }}
                >
                  NEXT
                </button>
              </div>
            </div>
          )}
          {step === 15 && (
            <div>
              <label className="mm-label-create">
                Address of local authority
              </label>
              <input
                className="mm-input-create"
                type="text"
                value={aaddress}
                onChange={(e) => setAaddress(e.target.value)}
              />
              <br />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <button
                  onClick={prevStep}
                  className="mm-left"
                  type="button"
                  style={{
                    backgroundColor:
                      localData("get", "branding").shade || "#eeeeee",
                    width: "48%",
                    color: "#fff",
                    padding: "10px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                    fontSize: "16px",
                  }}
                >
                  BACK
                </button>
                <button
                  onClick={nextStep}
                  className="mm-right"
                  type="button"
                  style={{
                    backgroundColor:
                      localData("get", "branding").color || "#eeeeee",
                    width: "48%",
                    color: "#fff",
                    padding: "10px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                    fontSize: "16px",
                  }}
                >
                  NEXT
                </button>
              </div>
            </div>
          )}
          {step === 16 && (
            <div>
              <label className="mm-label-create">
                Phone number of local authority
              </label>
              <input
                className="mm-input-create"
                type="text"
                value={aphone}
                onChange={(e) => setAphone(e.target.value)}
              />
              <br />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <button
                  onClick={prevStep}
                  className="mm-left"
                  type="button"
                  style={{
                    backgroundColor:
                      localData("get", "branding").shade || "#eeeeee",
                    width: "48%",
                    color: "#fff",
                    padding: "10px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                    fontSize: "16px",
                  }}
                >
                  BACK
                </button>
                <button
                  onClick={nextStep}
                  className="mm-right"
                  type="button"
                  style={{
                    backgroundColor:
                      localData("get", "branding").color || "#eeeeee",
                    width: "48%",
                    color: "#fff",
                    padding: "10px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                    fontSize: "16px",
                  }}
                >
                  NEXT
                </button>
              </div>
            </div>
          )}
          {step === 17 && (
            <div>
              <label className="mm-label-create">
                Website of local authority
              </label>
              <input
                className="mm-input-create"
                type="text"
                value={awebsite}
                onChange={(e) => setAwebsite(e.target.value)}
              />
              <br />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <button
                  onClick={prevStep}
                  className="mm-left"
                  type="button"
                  style={{
                    backgroundColor:
                      localData("get", "branding").shade || "#eeeeee",
                    width: "48%",
                    color: "#fff",
                    padding: "10px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                    fontSize: "16px",
                  }}
                >
                  BACK
                </button>
                <button
                  onClick={nextStep}
                  className="mm-right"
                  type="button"
                  style={{
                    backgroundColor:
                      localData("get", "branding").color || "#eeeeee",
                    width: "48%",
                    color: "#fff",
                    padding: "10px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                    fontSize: "16px",
                  }}
                >
                  NEXT
                </button>
              </div>
            </div>
          )}
          {step === 18 && (
            <div>
              <label className="mm-label-create">
                Email address of local authority
              </label>
              <input
                className="mm-input-create"
                type="text"
                value={aemail}
                onChange={(e) => setAemail(e.target.value)}
              />
              <br />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <button
                  onClick={prevStep}
                  className="mm-left"
                  type="button"
                  style={{
                    backgroundColor:
                      localData("get", "branding").shade || "#eeeeee",
                    width: "48%",
                    color: "#fff",
                    padding: "10px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                    fontSize: "16px",
                  }}
                >
                  BACK
                </button>
                <button
                  onClick={nextStep}
                  className="mm-right"
                  type="button"
                  style={{
                    backgroundColor:
                      localData("get", "branding").color || "#eeeeee",
                    width: "48%",
                    color: "#fff",
                    padding: "10px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                    fontSize: "16px",
                  }}
                >
                  NEXT
                </button>
              </div>
            </div>
          )}
          {step === 19 && (
            <div>
              <label className="mm-label-create">
                Who is the local authority representative?
              </label>
              <input
                className="mm-input-create"
                type="text"
                value={arep}
                onChange={(e) => setArep(e.target.value)}
              />
              <br />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <button
                  onClick={prevStep}
                  className="mm-left"
                  type="button"
                  style={{
                    backgroundColor:
                      localData("get", "branding").shade || "#eeeeee",
                    width: "48%",
                    color: "#fff",
                    padding: "10px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                    fontSize: "16px",
                  }}
                >
                  BACK
                </button>
                <button
                  onClick={nextStep}
                  className="mm-right"
                  type="button"
                  style={{
                    backgroundColor:
                      localData("get", "branding").color || "#eeeeee",
                    width: "48%",
                    color: "#fff",
                    padding: "10px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                    fontSize: "16px",
                  }}
                >
                  NEXT
                </button>
              </div>
            </div>
          )}

          {step === 20 && (
            <div>
              <label className="mm-label-create">
                Which of these is their service provider?
              </label>
              <select
                className="mm-input-create"
                value={provider}
                onChange={(e) => setProvider(e.target.value)}
              >
                <option value=''>Select provider...</option>
                {providers.map((provider) => (
                  <option key={provider.reference} value={provider.reference}>
                    {provider.company}
                  </option>
                ))}
              </select>
              <br />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <button
                  onClick={prevStep}
                  className="mm-left"
                  type="button"
                  style={{
                    backgroundColor:
                      localData("get", "branding").shade || "#eeeeee",
                    width: "48%",
                    color: "#fff",
                    padding: "10px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                    fontSize: "16px",
                  }}
                >
                  BACK
                </button>
                <button
                  onClick={nextStep}
                  className="mm-right"
                  type="button"
                  style={{
                    backgroundColor:
                      localData("get", "branding").color || "#eeeeee",
                    width: "48%",
                    color: "#fff",
                    padding: "10px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                    fontSize: "16px",
                  }}
                >
                  NEXT
                </button>
              </div>
            </div>
          )}

          {step === 21 && (
            <div>
              <label className="mm-label-create">
                Upload the company's logo
              </label>
              <input
                className="mm-input-create"
                type="file"
                onChange={(e) => setLogo(e.target.files[0])}
              />
              <br />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <button
                  onClick={prevStep}
                  className="mm-left"
                  type="button"
                  style={{
                    backgroundColor:
                      localData("get", "branding").shade || "#eeeeee",
                    width: "48%",
                    color: "#fff",
                    padding: "10px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                    fontSize: "16px",
                  }}
                >
                  BACK
                </button>
                <button
                  className="mm-right"
                  type="submit"
                  style={{
                    backgroundColor:
                      localData("get", "branding").color || "#eeeeee",
                    width: "48%",
                    color: "#fff",
                    padding: "10px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                    fontSize: "16px",
                  }}
                >
                  CREATE
                </button>
              </div>
            </div>
          )}
        </form>
      </Modal>
      <Top />
    </Container>
  );
};

export default Customers;
