import React, { useState } from "react";
import { Dropdown, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faTimes } from "@fortawesome/free-solid-svg-icons";
import { localData, runData } from "../../../jsx/backend";
import Modal from "react-modal";
import Loader from "../../includes/loader";
import parsePhoneNumber from "libphonenumber-js/max";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import validator from "validator";

Modal.setAppElement('#root'); 

const RenderModifyOption = ({ user }) => {
  const userRole = localData("get", "session").role;
  const targetUserRole = user.role;
  const [running, setRunning] = useState(false);
  const [message, setMessage] = useState("");
  const [report, setReporting] = useState(false);
  const [modifying, setModifying] = useState(false);
  const [staff, setStaff] = useState({
    name: user.name,
    surname: user.surname,
    occupation: user.occupation,
    phone: user.phone,
    email: user.email,
    role: user.role,
    status: user.status
  });

  const handleChange = (e) => {
    setStaff({
      ...staff,
      [e.target.name]: e.target.value,
    });
  };

  const modifyUser = async (e) => {
    e.preventDefault();
    setModifying(false);
    setRunning(true);

    const data = {
      reference: user.reference,
      name: staff.name,
      surname: staff.surname,
      email: staff.email,
      occupation: staff.occupation,
      phone: staff.phone,
      role: staff.role,
      status: staff.status,
    };

    for (let key in data) {
      if (data[key] === "") {
        setRunning(false);
        setMessage(`Please fill in the ${key} field. It is required`);
        setReporting(true);
        return;
      }
    }

      const phoneNumber = parsePhoneNumber(staff.phone);
      if (phoneNumber) {
        if (phoneNumber.isValid() && phoneNumber.getType() === "MOBILE") {
          if (!validator.isEmail(staff.email)) {
            setRunning(false);
            setMessage("Invalid email address.");
            setReporting(true);
            return;
          } else {
            if (!validator.matches(staff.name, /^[a-zA-Z ]+$/)) {
              setMessage(
                "Invalid name. The name should contain only letters and spaces."
              );
              setRunning(false);
              setReporting(true);
              return;
            }
          }
        } else {
          setMessage(
            "The phone number is not valid or is not a mobile number."
          );
          setRunning(false);
          setReporting(true);
          return;
        }
      } else {
        setMessage("The phone number is not valid.");
        setRunning(false);
        setReporting(true);
        return;
      }


    try {
      const responseData = await runData(data, `/query/staff/modify/`);

      setRunning(false);

      if (responseData.response) {
        switch (responseData.response.data.code) {
          case 200:
            localData("save", "staff_refresh", 1);
            setMessage("You have successfully updated this staff account!");
            setReporting(true);
            break;
          default:
            setMessage(responseData.response.data.message);
            setReporting(true);
        }
      } else if (responseData.error) {
        setMessage(responseData.error.message);
        setReporting(true);
      } else {
        setMessage("An error occurred while processing the request.");
        setReporting(true);
      }
    } catch (error) {
      setRunning(false);
      setMessage("An error occurred while processing the request.");
      setReporting(true);
    }
  };

  const customStyles = {
    overlay: {
      backgroundColor: "#ffffffeb",
      zIndex: 10000,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "transparent",
      border: "none",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minWidth: "30VW",
    },
  };

  if (
    userRole === 1 ||
    (userRole === 2 && (targetUserRole === 3 || targetUserRole === 4)) ||
    (userRole === 3 && targetUserRole === 4)
  ) {
    return (
      <>
        <Dropdown.Item onClick={() => setModifying(true)}>
          <div className="d-flex justify-content-between">
            Modify
            <FontAwesomeIcon icon={faArrowRight} />
          </div>
        </Dropdown.Item>

        <Modal
          isOpen={modifying}
          onRequestClose={() => setModifying(false)}
          style={customStyles}
        >
          <div
            style={{
              width: "100%",
              backgroundColor: "#f8f9fa",
              padding: "20px",
              borderRadius: "10px",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
              maxWidth: "500px",
            }}
          >
            <h4>
              Modify Staff ({staff.name}){" "}
              <span
                onClick={() => setModifying(false)}
                title="Close"
                className="mm-right"
                style={{ cursor: "pointer" }}
              >
                <FontAwesomeIcon icon={faTimes} color="red" />
              </span>
            </h4>

            <hr />
            <Form onSubmit={modifyUser}>
              <Form.Group>
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  className="no-outline"
                  value={staff.name}
                  onChange={handleChange}
                  placeholder="Name"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  className="no-outline"
                  name="surname"
                  value={staff.surname}
                  onChange={handleChange}
                  placeholder="Surname"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  className="no-outline"
                  name="email"
                  value={staff.email}
                  onChange={handleChange}
                  placeholder="Email"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Occupation</Form.Label>
                <Form.Control
                  type="text"
                  className="no-outline"
                  name="occupation"
                  value={staff.occupation}
                  onChange={handleChange}
                  placeholder="Occupation"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Phone</Form.Label>
                <PhoneInput
                  type="tel"
                  className="form-control no-outline"
                  id="phone"
                  defaultCountry={staff.phone ? undefined : "GB"}
                  placeholder="Enter the phone number"
                  value={staff.phone}
                  onChange={(value) => setStaff({ ...staff, phone: value })}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Role</Form.Label>
                <Form.Control
                  as="select"
                  className="no-outline"
                  name="role"
                  value={staff.role}
                  onChange={handleChange}
                >
                  <option value="1">Admin</option>
                  <option value="2">Manager</option>
                  <option value="3">Supervisor</option>
                  <option value="4">Staff</option>
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>Status</Form.Label>
                <Form.Control
                  as="select"
                  name="status"
                  className="no-outline"
                  value={staff.status}
                  onChange={handleChange}
                >
                  <option value="1">Activated</option>
                  <option value="2">Suspended</option>
                </Form.Control>
              </Form.Group>
              <div className="d-flex justify-content-between mt-3">
                <Button variant="danger" onClick={() => setModifying(false)}>
                  Cancel
                </Button>
                <Button variant="light" className="mm-color" type="submit">
                  Modify
                </Button>
              </div>
            </Form>
          </div>
        </Modal>

        <Modal isOpen={running} style={customStyles}>
          <div
            className="mm-center"
            style={{
              width: "100%",
              backgroundColor: "#f8f9fa",
              padding: "20px",
              borderRadius: "10px",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
            }}
          >
            <h6 style={{ color: "#343a40" }}>Please wait...</h6>
            <br />
            <Loader />
          </div>
        </Modal>

        <Modal
          isOpen={report}
          onRequestClose={() => setReporting(false)}
          style={customStyles}
        >
          <div
            style={{
              width: "100%",
              backgroundColor: "#f8f9fa",
              padding: "20px",
              borderRadius: "10px",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
            }}
          >
            <h6>
              Message{" "}
              <span
                onClick={() => setReporting(false)}
                title="Close"
                className="mm-right"
                style={{ cursor: "pointer" }}
              >
                <FontAwesomeIcon icon={faTimes} color="red" />
              </span>
            </h6>

            <hr />

            <p className="mm-center">{message}</p>

            <br />
            <button
              className="mm-btn mm-red"
              onClick={() => setReporting(false)}
              style={{ borderRadius: "30px", width: "100%" }}
            >
              CLOSE
            </button>
          </div>
        </Modal>
      </>
    );
  }
  return null;
};

export default RenderModifyOption;
