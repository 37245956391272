import React, { useState, useEffect, useRef } from 'react';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import {Helmet} from "react-helmet";
import Top from '../../includes/topping';
import { Link, useParams } from "react-router-dom"
import { localData, runData }  from '../../../jsx/backend';
import Loader from '../../includes/loader';
import { unescape } from "validator";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileAlt,
  faTimes,
 } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import SideBar from '../../sections/sidebar';
import {
  Card,
  Col,
  Container,
  Row,
} from "react-bootstrap";

Modal.setAppElement('#root'); 

const PolicyEditorCus = () => {
  const params = useParams();
    const [policy, setPolicy] = useState('');
    const [policyTitle, setPolicyTitle] = useState('');
    const [date, setDate] = useState('0');
    const [template, setTemplate] = useState(null);
    const editorRef = useRef();
  
    const [loading, setLoading] = useState(true);
    const [running, setRunning] = useState(false);
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [report, setReporting] = useState(false);
    const [loaded, setLoaded] = useState(false);
  

    const viewPolicy = async () => {
      setLoading(true);
      const data = {
    
      }
      const responseData = await runData(data, `/query/customer/policies/${params.package}/${params.category}/${params.policy}/current/`);
      if (responseData.response) {
      switch(responseData.response.data.code){
        case 200:
          setPolicyTitle(responseData.response.data.title);
          setPolicy(unescape(responseData.response.data.details));
          setTemplate(unescape(responseData.response.data.template));
          setLoading(false);
          setLoaded(true);
        break;
        default:
          setMessage(responseData.response.data.message);
          setError(true);
      }
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setError(true);
    } else {
      setMessage("An error occured while processing your request.");
      setError(true);
    }
  };

    useEffect(() => {
      viewPolicy();
    }, []);

    const save = async () => {
      setRunning(true);
      const data = {
        "details": policy
      }
      const responseData = await runData(data, `/query/customer/policies/${params.package}/${params.category}/${params.policy}/save/`);
      setRunning(false)
      if (responseData.response) {
      switch(responseData.response.data.code){
        case 200:
          setDate(responseData.response.data.date);
          setMessage("Your policy has been updated succesfully!");
          setReporting(true);
        break;
        default:
          setReporting(true);
          setMessage(responseData.response.data.message);
      }
    }else if (responseData.response.error) {
      setReporting(false);
      setMessage(responseData.response.error);
    }else{
      setMessage('An error occured while processing your request.');
      setReporting(true);
    }
    };

    const handleChange = async (policy) => {
      setPolicy(policy);
    };

    const templates = [
      {
        name: 'Restore Document',
        html: `<p>${template}</p>`
    }
    ];

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: 10000,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'transparent',
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: '30VW',
    
  },
};

return (
<Container style={{ minHeight: "95VH", wordWrap: "break-word" }} fluid>
  <Helmet>
    <title>
     Edit:: {policyTitle} - {localData("get", "branding").company || "Portal"}
    </title>
  </Helmet>
  <Row className="mt-3 mm-padding-large">
        <Col md={9}>
          <Card id="policies" className="mb-4">
            <Card.Header>
              <Row className="d-flex align-items-center justify-content-between">
                <Col md={6}>
                  <h6>
                    You're editing: {policyTitle}
                  </h6>
                </Col>
                <Col
                  md={6}
                  className="d-flex align-items-start justify-content-end"
                ><Link
                to={`/access/policies/${params.package}/${params.category}/${params.policy}/`}
                style={{
                  cursor: "pointer",
                  textDecoration: "none",
                }}
              >
               <FontAwesomeIcon icon={faFileAlt}  /> Read Online
              </Link></Col>
              </Row>
            </Card.Header>
            <Card.Body>

<SunEditor
ref={editorRef}
setOptions={{
  templates,
  height: "80VH",
  width: "100%",
  buttonList: [
    ['undo', 'redo'],
    ['font'],
    [':p-More Paragraph-default.more_paragraph', 'font', 'fontSize', 'formatBlock', 'paragraphStyle', 'blockquote'],
    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
    ['fontColor', 'hiliteColor', 'textStyle'],
    ['removeFormat'],
    ['outdent', 'indent'],
    ['align', 'horizontalRule', 'list', 'lineHeight'],
    ['-right', ':i-More Misc-default.more_vertical', 'fullScreen', 'showBlocks', 'preview', 'template'],
    ['-right', ':r-More Rich-default.more_plus', 'table'],
    ['-right', 'image', 'link'],
    ['%992', [
      ['undo', 'redo'],
      [':p-More Paragraph-default.more_paragraph', 'font', 'fontSize', 'formatBlock', 'paragraphStyle', 'blockquote'],
      ['bold', 'underline', 'italic', 'strike'],
      [':t-More Text-default.more_text', 'subscript', 'superscript', 'fontColor', 'hiliteColor', 'textStyle'],
      ['removeFormat'],
      ['outdent', 'indent'],
      ['align', 'horizontalRule', 'list', 'lineHeight'],
      ['-right', ':i-More Misc-default.more_vertical', 'fullScreen', 'showBlocks', 'preview', 'template'],
      ['-right', ':r-More Rich-default.more_plus', 'table', 'link', 'image']
    ]],
    ['%767', [
      ['undo', 'redo'],
      [':p-More Paragraph-default.more_paragraph', 'font', 'fontSize', 'formatBlock', 'paragraphStyle', 'blockquote'],
      [':t-More Text-default.more_text', 'bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'fontColor', 'hiliteColor', 'textStyle'],
      ['removeFormat'],
      ['outdent', 'indent'],
      [':e-More Line-default.more_horizontal', 'align', 'horizontalRule', 'list', 'lineHeight'],
      [':r-More Rich-default.more_plus', 'table', 'link', 'image'],
      ['-right', ':i-More Misc-default.more_vertical', 'fullScreen', 'showBlocks', 'preview', 'template']
    ]],
    ['%480', [
      ['undo', 'redo'],
      [':p-More Paragraph-default.more_paragraph', 'font', 'fontSize', 'formatBlock', 'paragraphStyle', 'blockquote'],
      [':t-More Text-default.more_text', 'bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'fontColor', 'hiliteColor', 'textStyle', 'removeFormat'],
      [':e-More Line-default.more_horizontal', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'lineHeight'],
      [':r-More Rich-default.more_plus', 'table', 'link', 'image'],
      ['-right', ':i-More Misc-default.more_vertical', 'fullScreen', 'showBlocks', 'preview', 'template']
    ]]
  ],
  font: [
    'Verdana',
    'Arial',
    'Comic Sans MS',
    'Courier New',
    'Impact',
    'Georgia',
    'Tahoma',
    'Times New Roman',
  ],
}}
      setContents={policy}
      onChange={handleChange}
      />
<br />
<button onClick={save} className='mm-btn mm-hover-grey mm-text-white' style={{width: "100%", borderRadius: "5px", background: localData('get', 'branding').color || '#8b7f83'}}>UPDATE POLICY</button>

</Card.Body>
          </Card>
        </Col>
        <SideBar />
      </Row>


<Modal isOpen={running} style={customStyles}>
        <div
          className="mm-center"
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <h4 style={{ color: "#343a40" }}>Please wait...</h4>
          <br />
          <Loader />
        </div>
      </Modal>

      <Modal
        isOpen={report}
        onRequestClose={() => setReporting(false)}
        style={customStyles}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <h4>
            Message{" "}
            <span
              onClick={() => setReporting(false)}
              title="Close"
              className="mm-right"
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={faTimes} color="red" />
            </span>
          </h4>

          <hr />

          <p className="mm-center">{message}</p>

          <br />
          <button
            className="mm-btn mm-red"
            onClick={() => setReporting(false)}
            style={{ borderRadius: "30px", width: "100%" }}
          >
            CLOSE
          </button>
        </div>
      </Modal>

<Top />
</Container>
);}

export default PolicyEditorCus;