import React from 'react';
import { Col, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDashboard, faArrowUp, faUsers, faFileWord, faFile, faRobot } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { localData } from '../../jsx/backend';

const SideBar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  let links = {};

  switch (localData("get", "branding").level) {
    case 1:
      links.policies = "/policies/";
      links.templates = "/templates/";
      links.composer = "/composer/";
      links.projects = "/staff/projects/";
      links.drive = "/drive/";
      links.records = "/records/";
      break;

    case 2:
      links.policies = "/manage/policies/";
      links.templates = "/manage/templates/";
      links.composer = "/composer/";
      links.projects = "/staff/projects/";
      links.drive = "/drive/";
      links.records = "/records/";

      break;

    case 3:
      links.policies = "/access/policies/";
      links.templates = "/access/templates/";
      links.composer = "/composer/";
      links.projects = "/staff/projects/";
      links.drive = "/drive/";
      links.records = "/records/";
      break;
  }

  const navigateHome = () => {
    navigate('/');
  }

  const navigateStaff = () => {
    navigate('/staff/');
  }
  const navigateTemplates = () => {
    navigate(links.templates);
  }
  const navigatePolicies = () => {
    navigate(links.policies);
  }
  const navigateComposer = () => {
    navigate(links.composer);
  }
  const goUp = () => {
    const currentPath = location.pathname;
    const pathParts = currentPath.split('/').filter(Boolean); 

    if (pathParts.length > 1) {
      pathParts.pop();
      const newPath = '/' + pathParts.join('/');
      navigate(newPath);
    }
  }

  return (
    <Col md={3} style={{ marginBottom: "30px" }}>
      <ListGroup className='border' style={{ position: "sticky", top: "100px" }}>
        <ListGroup.Item onClick={navigateHome} action className='mm-light-grey'>
          <FontAwesomeIcon icon={faDashboard} /> Dashboard
        </ListGroup.Item>
        <ListGroup.Item onClick={navigateStaff} action >
          <FontAwesomeIcon icon={faUsers} /> Staff
        </ListGroup.Item>
        <ListGroup.Item onClick={navigateTemplates} action >
          <FontAwesomeIcon icon={faFile} /> Templates
        </ListGroup.Item>
        <ListGroup.Item onClick={navigatePolicies} action >
          <FontAwesomeIcon icon={faFileWord} /> Policies
        </ListGroup.Item>
        <ListGroup.Item onClick={navigateComposer} action >
          <FontAwesomeIcon icon={faRobot} /> Composer
        </ListGroup.Item>
        <ListGroup.Item action onClick={goUp} disabled={location.pathname === '/'} className='mm-pale-red' >
  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
    <span>GO ONE LEVEL UP</span>
    <FontAwesomeIcon icon={faArrowUp} />
  </div>
</ListGroup.Item>
      </ListGroup>
    </Col>
  );
}

export default SideBar;