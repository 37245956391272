import React, { useState, useEffect, useCallback } from 'react';
import {Helmet} from "react-helmet";
import Top from '../../includes/topping';
import { Link, useParams } from "react-router-dom"
import { localData, runData }  from '../../../jsx/backend';
import Loader from '../../includes/loader';
import { useDropzone } from 'react-dropzone';
import { slugify } from '../../includes/cleaning';
import { saveAs } from 'file-saver';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  faTimes,
  faBars,
  faChevronCircleUp,
  faDotCircle,
  faToggleOn,
  faToggleOff,
  faPlusSquare,
  faChartLine,
  faFileEdit,
  faPencilSquare,
  faCogs,
  faSearch,
  faTimesCircle,
  faSortUp,
  faSortDown,
  faPlusCircle,
  faFile,
  faEye,
  faFileCirclePlus,
  faUpload,
  faTrashAlt
} from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-modal';
import getIcon from '../../includes/icons';

Modal.setAppElement('#root'); 

const FormsData = () => {
    const params = useParams();
    const [packageName, setPackageName] = useState('Package');
    const [category, setFolder] = useState('Category');
    const [policy, setPolicy] = useState('Policy');
    const [forms, setForms] = useState([]);

    const [selectedFile, setSelectedFile] = useState(null);
    const [title, setTitle] = useState('');
    const [creator, setCreator] = useState(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [currentItemReference, setCurrentItemReference] = useState(null);
    const [currentTitle, setCurrentTitle] = useState('');
    

    const [loading, setLoading] = useState(true);
    const [running, setRunning] = useState(false);
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [report, setReporting] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [isOn, setIsOn] = useState(1);
    const [stats, setStats] = useState(false);
  
    const currentDate = new Date();
    const firstDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
  
    const [startDate, setStartDate] = useState(firstDayOfMonth);
    const [endDate, setEndDate] = useState(new Date());
  
    //const [data, setData] = useState([]);
    const data = [
      { date: '2023-11-07', Users: 4000, Errors: 2400, Clones: 2400, Downloads: 4000 },
      { date: '2023-11-08', Users: 3000, Errors: 1398, Clones: 2210, Downloads: 3000 },
      { date: '2023-11-09', Users: 2000, Errors: 9800, Clones: 2290, Downloads: 2000 },
      { date: '2023-11-10', Users: 2780, Errors: 3908, Clones: 2000, Downloads: 2780 },
      { date: '2023-11-11', Users: 1890, Errors: 4800, Clones: 2181, Downloads: 1890 },
      { date: '2023-11-12', Users: 2390, Errors: 3800, Clones: 2500, Downloads: 2390 },
      { date: '2023-11-13', Users: 3490, Errors: 4300, Clones: 2100, Downloads: 3490 },
      { date: '2023-11-14', Users: 4000, Errors: 2400, Clones: 2400, Downloads: 4000 },
      { date: '2023-11-15', Users: 3000, Errors: 1398, Clones: 2210, Downloads: 3000 },
      { date: '2023-11-16', Users: 2000, Errors: 9800, Clones: 2290, Downloads: 2000 },
      { date: '2023-11-17', Users: 2780, Errors: 3908, Clones: 2000, Downloads: 2780 },
      { date: '2023-11-18', Users: 1890, Errors: 4800, Clones: 2181, Downloads: 1890 },
      { date: '2023-11-19', Users: 2390, Errors: 3800, Clones: 2500, Downloads: 2390 },
      { date: '2023-11-20', Users: 3490, Errors: 4300, Clones: 2100, Downloads: 3490 },
      { date: '2023-11-21', Users: 4000, Errors: 2400, Clones: 2400, Downloads: 4000 },
    ];
  
    const filteredData = data.filter((item) => {
      const itemDate = new Date(item.date);
      return itemDate >= startDate && itemDate <= endDate;
    });
  
    const listForms = async () => {
      const data = {
    
      }
      const responseData = await runData(data, `/query/policies/${params.package}/${params.category}/${params.policy}/forms/public/`);
      setLoading(false);
      if (responseData.response) {
      switch(responseData.response.data.code){
        case 200:
          setForms(responseData.response.data.attachments);
          setPackageName(responseData.response.data.package);
          setFolder(responseData.response.data.category);
          setPolicy(responseData.response.data.policy);
          setLoaded(true);
        break;
        default:
            setMessage(responseData.response.data.message);
            setError(true);
        }
      } else if (responseData.error) {
        setMessage(responseData.error.message);
        setError(true);
      } else {
        setMessage("An error occured while processing your request.");
        setError(true);
      }}

    const handleRemoveFile = () => {
      setSelectedFile(null);
  };

    const handleStatus = async () => {
      setRunning(true);
      
      const data = {
        "status": isOn,
      };
      const responseData = await runData(
        data,
        `/query/policies/${params.package}/${params.category}/${params.policy}/status/`
      );
      setRunning(false);
      if (responseData.response) {
        switch (responseData.response.data.code) {
          case 200:
            setIsOn(responseData.response.data.status);
            setReporting(true);
            setMessage("Your request has been processed succesfully!");
            break;
  
            default:
              setMessage(responseData.response.data.message);
              setReporting(true);
          }
        } else if (responseData.error) {
          setMessage(responseData.error.message);
          setReporting(true);
        } else {
          setMessage("An error occured while processing your request.");
          setReporting(true);
        }};

    const handleEdit = (reference) => {
      setCurrentItemReference(reference);
      setEditModalIsOpen(true);
    };
    
    const handleDelete = (reference) => {
      setCurrentItemReference(reference);
      setDeleteModalIsOpen(true);
    };

    const confirmDelete = async () => {
      setDeleteModalIsOpen(false);
      setRunning(true);
      
      const data = {
      
      }
      const responseData = await runData(data, `/query/policies/${params.package}/${params.category}/${params.policy}/forms/delete/${currentItemReference}/`);

      setRunning(false);

      if (responseData.response) {
      switch(responseData.response.data.code){
        case 200:
          setMessage('Deleted Successfully!');
          listForms();
          setReporting(true);
        break;
      
        default:
          setMessage(responseData.response.data.message);
          setReporting(true);
      }
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setReporting(true);
    } else {
      setMessage("An error occured while processing your request.");
      setReporting(true);
    }};
    
      const confirmEdit = async (event) => {
        event.preventDefault();
        setRunning(true);
        setEditModalIsOpen(false);
  
        const data = {
          "title": currentTitle
        }
        const responseData = await runData(data, `/query/policies/${params.package}/${params.category}/${params.policy}/forms/edit/${currentItemReference}/`);
        setRunning(false);
        if (responseData.response) {
        switch(responseData.response.data.code){
          case 200:
            setMessage('Updated Successfully!');
            setReporting(true);
            listForms();
          break;
        
          default:
            setMessage(responseData.response.data.message);
            setReporting(true);
        }
      } else if (responseData.error) {
        setMessage(responseData.error.message);
        setReporting(true);
      } else {
        setMessage("An error occured while processing your request.");
        setReporting(true);
      }};

    const onDrop = useCallback((acceptedFiles) => {
      setSelectedFile(acceptedFiles[0]);
    }, []);
    const [downloadRef, setDownloadRef] = useState('');
    const [downloadName, setDownloadName] = useState('Attachment');
    const [triggerDownload, setTriggerDownload] = useState(false);
    const handleDownload = (ref) => {
      setDownloadRef(ref);
      setTriggerDownload(true);
      };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, maxFiles: 1 });
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(9);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');
    
    const handleSearch = (event) => {
      setSearchTerm(event.target.value);
      setCurrentPage(1);
    };
    
    let filteredAndSortedForms = [];
    if (Array.isArray(forms)) {
      filteredAndSortedForms = forms
        .filter(ipolicy => ipolicy.title.toLowerCase().includes(searchTerm.toLowerCase()))
        .sort((a, b) => {
          if (sortOrder === 'asc') {
            return a.title > b.title ? 1 : -1;
          } else {
            return a.title < b.title ? 1 : -1;
          }
        });
    }
    
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    let currentItems = filteredAndSortedForms.slice(indexOfFirstItem, indexOfLastItem);
    
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(filteredAndSortedForms.length / itemsPerPage); i++) {
      pageNumbers.push(i);
    }
    
    const handleClick = (event) => {
      setCurrentPage(Number(event.target.id));
    };
    
    const handleSort = () => {
      if (sortOrder === 'asc') {
        setSortOrder('desc');
      } else {
        setSortOrder('asc');
      }
    };

    const handleSubmit = async () => {
      setRunning(true);
      setCreator(false);
      if (!selectedFile) {
      setRunning(false);
      setMessage("No file selected. Please select a file to upload.");
      setReporting(true);
      return;
      }
      
      if (selectedFile.size === 0) {
      setRunning(false);
      setMessage("The selected file is empty. Please select a non-empty file.");
      setReporting(true);
      return;
      }
      
      const data = {
      "title": title || selectedFile.name,
      "attachment": selectedFile
      }
 
      const responseData = await runData(data, `/query/policies/${params.package}/${params.category}/${params.policy}/forms/upload/public/`);
      setRunning(false);
      
      if (responseData.response) {
      switch (responseData.response.data.code) {
      case 200:
      setTitle('');
      setSelectedFile(null);
      setMessage("Your upload has been successful.");
      setReporting(true);
      listForms();
      break;
      
      default:
      setMessage(responseData.response.data.message);
      setReporting(true);
      }
      } else if (responseData.error) {
      setMessage(responseData.error.message);
      setReporting(true);
      } else {
      setMessage("An error occurred while processing your request.");
      setReporting(true);
      }};
 


      const downloadFile = async () => {
        setRunning(true);

          const data = {
  
          }
  
        const responseData = await runData(data, `/query/policies/${params.package}/${params.category}/${params.policy}/forms/download/${downloadRef}/`, 'blob');
  
        setRunning(false);
        if (responseData.response && responseData.response.status === 200) {
          setMessage('Your download has been successful!');
          const filename = slugify(downloadName);
          const blob = new Blob([responseData.response.data], { type: responseData.response.headers['content-type'] });
          saveAs(blob, filename || 'download');
          setReporting(true);
      } else if (responseData.error) {
        setMessage(responseData.error.message);
        setReporting(true);
      } else {
        setMessage("An error occured while processing your request.");
        setReporting(true);
      }};

useEffect(() => {
  if (triggerDownload) {
    downloadFile();
    setTriggerDownload(false);
  }
  }, [triggerDownload, downloadRef]);

    useEffect(() => {
      listForms();
    }, []);


    const handleReload = () => {
      listForms();
    }

    const styles = {
      border: 'dashed 3px #eeeeee',
      borderRadius: '5px',
      padding: '60px',
      textAlign: 'center',
      color: '#bdbdbd',
      backgroundColor: '#fafafa',
      cursor: 'pointer',
    };
  
    const activeStyles = {
      borderColor: '#2196f3',
    };

      const customStyles = {
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
          zIndex: 10000,
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'transparent',
          border: 'none',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          minWidth: '30VW',
          
        },
      };
return (
  <div
  className='main mm-container mm-row-padding"'
  style={{ minHeight: "95VH", wordWrap: "break-word" }}
>
  <Helmet>
    <title>
     Forms:: { policy } -{" "}
      {localData("get", "branding").company || "Portal"}
    </title>
  </Helmet>

  <div className="mm-threequarter mm-padding">
    {loading && !error && (
      <div style={{ marginTop: "100px" }}>
        {" "}
        <Loader />{" "}
      </div>
    )}

    {error && !loading && (
      <div className="mm-display-container" style={{ height: "50VH" }}>
        <div className="mm-display-middle mm-center">
          {message ? (
            <h4>{message}</h4>
          ) : (
            <h4>Processing your request was not successful!</h4>
          )}
          <br />
          <button
            onClick={handleReload}
            className="mm-btn my-color"
            style={{ borderRadius: "30px" }}
          >
            RELOAD
          </button>
        </div>
      </div>
    )}

    {loaded &&
      !error &&
      !loading &&
      (forms && forms.length > 0 ? (
      <div className='mm-section'>
<div className='mm-container' style={{display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px", backgroundColor: "#f5f5f5", borderRadius: "5px"}}>
  <div style={{display: "flex", flexGrow: 1, marginRight: "10px", alignItems: "center"}}>
    <FontAwesomeIcon icon={faSearch} style={{marginRight: "10px", color: "#888"}}/>
    <input
  className='mm-input'
  style={{width: "100%", padding: "10px", borderRadius: "5px", border: "1px solid #ddd", outline: "none"}}
  type="text"
  placeholder={`Search from ${forms.length} forms...`}
  value={searchTerm}
  onChange={handleSearch}
/>
    {searchTerm && <FontAwesomeIcon icon={faTimesCircle} style={{marginLeft: "10px", color: "#888", cursor: "pointer"}} onClick={() => setSearchTerm('')}/>}
  </div>
  <button onClick={handleSort} style={{display: "flex", alignItems: "center", padding: "10px 20px", backgroundColor: localData("get", "branding").shade || "#eeeeee",
                    color: "#fff", color: "#fff", border: "none", borderRadius: "5px", cursor: "pointer"}}>
    {sortOrder === 'asc' ? <FontAwesomeIcon icon={faSortDown} style={{marginRight: "10px"}}/> : <FontAwesomeIcon icon={faSortUp} style={{marginRight: "10px"}}/>}
    Sorting
  </button>
</div>
<br />
{pageNumbers.length > 1 && (
<div className="pagination">
{currentPage > 1 && (
<button
onClick={() => setCurrentPage(currentPage - 1)}
>
Back
</button>
)}
{pageNumbers.map((number) => (
<button
key={number}
id={number}
onClick={handleClick}
className={currentPage === number ? 'active' : ''}
>
{number}
</button>
))}
{currentPage < pageNumbers.length && (
<button
onClick={() => setCurrentPage(currentPage + 1)}
>
Next
</button>
)}
</div>
)}
<br />
<div className="category-container">
{currentItems.map((ipolicy) => ( 
  <div className="category zoom-on-hover" key={ipolicy.reference }>
  <ContextMenuTrigger id={ipolicy.reference}>
    <span className="parent-img" style={{cursor: "pointer"}} onClick={() => {
    handleDownload(ipolicy.reference);
    setDownloadName(ipolicy.title);
    }}>
<div className="mm-padding mm-margin" style={{height: "200px", width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>{getIcon(ipolicy.type)}</div>
  <div className="category-name">{ ipolicy.title  } ({(ipolicy.size / 1000000).toFixed(2)} MB)</div>
  </span>
    </ContextMenuTrigger>
  <ContextMenu className='mm-card my-shade mm-padding' style={{borderRadius: "10px", zIndex: "99999"}} id={ipolicy.reference}>
  <span  style={{cursor: "pointer"}} onClick={() => {
    handleDownload(ipolicy.reference);
    setDownloadName(ipolicy.title);
    }}>
  <MenuItem>
      Download
    </MenuItem>
    </span>
    <hr />
    <span style={{cursor: "pointer"}} title='Edit'>
        <MenuItem onClick={() => {handleEdit(ipolicy.reference); setCurrentTitle(ipolicy.title) }}>
          Edit
        </MenuItem>
        </span>
        <hr />
        <span style={{cursor: "pointer"}} title='Delete'>
        <MenuItem onClick={() => {handleDelete(ipolicy.reference); setCurrentTitle(ipolicy.title) }}>
          Delete
        </MenuItem>
        </span>
  </ContextMenu>
  <div className="dropdown">
<span className="dropbtn" style={{padding: "20px"}}><FontAwesomeIcon icon={faBars} /></span>
<div className='dropdown-content mm-card my-shade mm-padding' style={{borderRadius: "10px"}} id={ipolicy.reference}>
<span  style={{cursor: "pointer"}} onClick={() => {
    handleDownload(ipolicy.reference);
    setDownloadName(ipolicy.title);
    }}>
Download
</span>
<hr />
<span onClick={() => {handleEdit(ipolicy.reference); setCurrentTitle(ipolicy.title) }} style={{cursor: "pointer"}} title='Edit'>
            Edit
          </span>
          <hr />
          <span onClick={() => {handleDelete(ipolicy.reference); setCurrentTitle(ipolicy.title) }} style={{cursor: "pointer"}} title='Delete'>
            Delete
          </span>
</div>
</div>
</div>
 ))}
</div>
</div>
          ) : (
            <h4 className="mm-center" style={{ paddingTop: "30VH" }}>
              You have no forms!
              <br />
              <button
                onClick={() => setCreator(true)}
                className="mm-small my-color mm-text-white zoom-on-hover"
                style={{
                  padding: "10px 20px",
                  borderRadius: "5px",
                  border: "none",
                  cursor: "pointer",
                  marginTop: "30px",
                }}
              >
                <FontAwesomeIcon icon={faPlusCircle} /> CREATE FIRST FORM
              </button>
            </h4>
          ))}
</div>

<div
        className="mm-quarter mm-padding"
        style={{ top: "75px", position: "sticky" }}
      >
        <div
          className="mm-card mm-padding in-sidenav"
          style={{ borderRadius: "10px", width: "100%" }}
        >
          <h4 className="mm-text-black">File Options
          <span
              className="mm-right"
              style={{ textDecoration: "none" }}
            >
              <FontAwesomeIcon icon={faCogs} />
            </span>
            </h4>
                    
            <hr />
            {isOn === 1 ? (
              <section className="menu-wrapper" onClick={handleStatus}>
            <span>
            <FontAwesomeIcon icon={faDotCircle} color="green" /> Activated
          </span>
          <FontAwesomeIcon icon={faToggleOff} color="red" />
          </section>
            ) : (
<section className="menu-wrapper" onClick={handleStatus}>
<span>
            <FontAwesomeIcon icon={faDotCircle} color="red" /> Suspended
          </span>
          <FontAwesomeIcon icon={faToggleOn} color="green" />
  </section>
            )}
          <br />
          <section className="menu-wrapper" onClick={() => setStats(true)}>
            <span>
              <FontAwesomeIcon icon={faChartLine} color="orange" /> Statistics
            </span>
            <FontAwesomeIcon icon={faPlusSquare} />
          </section>
          <br />
          <Link to={`/policies/${params.package}/${params.category}/${params.policy}/`} className="menu-wrapper">
            <span>
              <FontAwesomeIcon icon={faFile} color="grey" /> Preview
            </span>
            <FontAwesomeIcon icon={faEye} />
          </Link>
          <br />
          <Link to={`/policies/${params.package}/${params.category}/${params.policy}/edit/`} className="menu-wrapper">
            <span>
              <FontAwesomeIcon icon={faFileEdit} color="grey" /> Edit
            </span>
            <FontAwesomeIcon icon={faPencilSquare} />
          </Link>
          <section className="menu-wrapper" onClick={() => setCreator(true)}>
            <span>
              <FontAwesomeIcon icon={faFileCirclePlus} color="green" /> Upload Form
            </span>
            <FontAwesomeIcon icon={faUpload} />
          </section>
          <br />
            <hr />
        
         <Link to={`/policies/${params.package}/${params.category}/`}>
                <button
                  className="mm-small my-color zoom-on-hover mm-text-white"
                  style={{
                    padding: "10px 20px",
                    borderRadius: "5px",
                    border: "none",
                    cursor: "pointer",
                    width: "100%",
                  }}
                >
                  GO ONE LEVEL UP <FontAwesomeIcon icon={faChevronCircleUp} />
                </button>
            </Link>
          <br />
        </div>
      </div>

<Modal isOpen={editModalIsOpen} onRequestClose={() => setEditModalIsOpen(false)} style={customStyles}>
  <div className='mm-center' style={{width: "100%", backgroundColor: "#f8f9fa", padding: "20px", borderRadius: "10px", boxShadow: "0px 0px 10px rgba(0,0,0,0.1)"}}>
    <h4>Edit Title: {currentTitle}</h4>
    <form onSubmit={confirmEdit} style={{width: "100%", marginTop: "30px"}}>
      <input name="title" placeholder='Package title...' type="text" className='mm-input mm-border' style={{width: "100%", boxSizing: "border-box", display: "block", borderRadius: "10px", outline: "none",}} value={currentTitle} onChange={e => setCurrentTitle(e.target.value)} required/>
      <br />
      <button onClick={() => setEditModalIsOpen(false)} className='mm-left' type="button" style={{ backgroundColor: 'red', width: '48%', color: '#fff', padding: '10px', border: 'none', borderRadius: '5px', cursor: 'pointer', transition: 'background-color 0.3s ease' }}>CANCEL</button>
      <button className='mm-right' type="submit" style={{ backgroundColor: 'green', width: '48%', color: '#fff', padding: '10px', border: 'none', borderRadius: '5px', cursor: 'pointer', transition: 'background-color 0.3s ease' }}>UPDATE</button>
    </form>
  </div>
</Modal>

<Modal isOpen={deleteModalIsOpen} onRequestClose={() => setDeleteModalIsOpen(false)} style={customStyles}>
<div className='mm-center' style={{width: "100%", backgroundColor: "#f8f9fa", padding: "20px", borderRadius: "10px", boxShadow: "0px 0px 10px rgba(0,0,0,0.1)"}}>
        <h4>Delete Form: {currentTitle}</h4>
        <br />
        <div className='mm-container' style={{width: "100%"}}>
        <p>Are you sure you want to delete this item?</p><br />
        <button onClick={() => setDeleteModalIsOpen(false)}className='mm-left' type="button" style={{ backgroundColor: 'red', width: '48%', color: '#fff', padding: '10px', border: 'none', borderRadius: '5px', cursor: 'pointer', transition: 'background-color 0.3s ease' }}>CANCEL</button>
        <button onClick={confirmDelete}className='mm-right my-color' type="submit" style={{width: '48%', color: '#fff', padding: '10px', border: 'none', borderRadius: '5px', cursor: 'pointer', transition: 'background-color 0.3s ease' }}>DELETE</button>
        </div>
</div>
</Modal>

<Modal isOpen={creator} onRequestClose={() => setCreator(false)} style={customStyles}>
<div className='mm-container' style={{width: "100%", backgroundColor: "#f8f9fa", padding: "20px", borderRadius: "10px", boxShadow: "0px 0px 10px rgba(0,0,0,0.1)"}}>
<h4>UPLOAD FORM <span onClick={() => setCreator(false)} className='mm-right mm-text-red' style={{cursor: "pointer"}} title='Close' ><FontAwesomeIcon icon={faTimesCircle} /> CLOSE</span></h4>
<br />
<div {...getRootProps()} style={isDragActive ? { ...styles, ...activeStyles } : styles}>
  <input {...getInputProps()} />
  {isDragActive ? (
    <p>Drop the file here ...</p>
  ) : (
    <p>Drag 'n' drop a file here, or click to select a file</p>
  )}
</div>
{selectedFile && (
                <div className="file-preview">
                    <div className="file-icon">
                    <div style={{height: "50px", width: "50px", display: "flex", justifyContent: "center", alignItems: "center"}}>
{getIcon(selectedFile.type)}
</div>
                    </div>
                    <div className="file-info">
                        <p>{selectedFile.name}</p>
                    </div>
                    <button onClick={handleRemoveFile} className="remove-file-button">
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                </div>
            )}
<br />
<div style={{display: 'flex', border: '1px solid #ccc', borderRadius: '4px', overflow: 'hidden'}}>
<input type="text" style={{flexGrow: 1, border: 'none', padding: '10px'}} placeholder='Enter your file title here...' value={title} onChange={(event) => setTitle(event.target.value)} required/>
<button style={{background: localData('get', 'branding').color || '#8b7f83', cursor: 'pointer', color: '#fff', border: 'none', padding: '10px 20px'}} onClick={handleSubmit}>UPLOAD</button>
</div>
</div>
</Modal>

<Modal
        isOpen={stats}
        onRequestClose={() => setStats(false)}
        style={customStyles}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
            <div
              className="mm-light-grey mm-padding"
              style={{ marginBottom: "30px" }}
            >
              <div className="mm-container">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                  }}
                >
                  <div style={{ position: "relative", width: "45%" }}>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      className="date-picker"
                    />
                    <label for="startDate" className="date-label">
                      Start Date
                    </label>
                  </div>
                  <div style={{ position: "relative", width: "45%" }}>
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      className="date-picker"
                    />
                    <label for="endDate" className="date-label">
                      End Date
                    </label>
                  </div>
                </div>
                <ResponsiveContainer width="100%" height={400}>
                  <LineChart data={filteredData}>
                    <CartesianGrid strokeDasharray="1 1" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line
                      type="monotone"
                      dataKey="Users"
                      stroke="#8884d8"
                      activeDot={{ r: 8 }}
                    />
                    <Line type="monotone" dataKey="Errors" stroke="#82ca9d" />
                    <Line type="monotone" dataKey="Clones" stroke="#ffc658" />
                    <Line
                      type="monotone"
                      dataKey="Downloads"
                      stroke="#ff9933"
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
<h4 >Package: { packageName }</h4>
<p>Category: { category}</p>
<br />
<button onClick={() => setStats(false)}
                  className="mm-small my-color zoom-on-hover mm-text-white"
                  style={{
                    padding: "10px 20px",
                    borderRadius: "5px",
                    border: "none",
                    cursor: "pointer",
                    width: "100%",
                  }}
                >
                  CLOSE STATISTICS
                </button>
</div>
</Modal>
      <Modal isOpen={running} style={customStyles}>
        <div
          className="mm-center"
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <h4 style={{ color: "#343a40" }}>Please wait...</h4>
          <br />
          <Loader />
        </div>
      </Modal>

      <Modal
        isOpen={report}
        onRequestClose={() => setReporting(false)}
        style={customStyles}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <h4>
            Message{" "}
            <span
              onClick={() => setReporting(false)}
              title="Close"
              className="mm-right"
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={faTimes} color="red" />
            </span>
          </h4>

          <hr />

          <p className="mm-center">{message}</p>

          <br />
          <button
            className="mm-btn mm-red"
            onClick={() => setReporting(false)}
            style={{ borderRadius: "30px", width: "100%" }}
          >
            CLOSE
          </button>
        </div>
      </Modal>

<Top />
</div>
);}

export default FormsData;