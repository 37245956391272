import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Top from "../../includes/topping";
import { localData, runData } from "../../../jsx/backend";
import Loader from "../../includes/loader";
import { slugify } from "../../includes/cleaning";
import { saveAs } from "file-saver";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams, Link } from "react-router-dom";
import {
  Card,
  Col,
  Container,
  Dropdown,
  Form,
  Pagination,
  Row,
  Table,
} from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { faFileAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import getIcon from "../../includes/icons";
import SideBar from "../../sections/sidebar";

Modal.setAppElement("#root");

const FormsDataCustomer = () => {
  const [forms, setForms] = useState([]);
  const [running, setRunning] = useState(false);
  const [message, setMessage] = useState("");
  const [report, setReporting] = useState(false);
  const [policy, setPolicy] = useState("Policy");
  const params = useParams();
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const filteredSubForms = forms.filter((iform) =>
    iform.title.toLowerCase().includes(search.toLowerCase())
  );

  const totalPages = Math.ceil(filteredSubForms.length / itemsPerPage);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const [downloadRef, setDownloadRef] = useState("");
  const [downloadName, setDownloadName] = useState("Attachment");
  const [triggerDownload, setTriggerDownload] = useState(false);

  const handleDownload = (ref) => {
    setDownloadRef(ref);
    setTriggerDownload(true);
  };

  const listForms = async () => {
    const data = {
  
    }
    const responseData = await runData(data, `/query/customer/policies/${params.package}/categories/${params.category}/${params.policy}/forms/`);
    if (responseData.response) {
    switch(responseData.response.data.code){
      case 200:
        setForms(responseData.response.data.attachments);
        setPolicy(responseData.response.data.policy);
      break;
      default:
          setMessage(responseData.response.data.message);
          setReporting(true);
      }
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setReporting(true);
    } else {
      setMessage("An error occured while processing your request.");
      setReporting(true);
    }}
    const downloadFile = async () => {
      setRunning(true);

        const data = {

        }

      const responseData = await runData(data, `/query/customer/policies/${params.package}/categories/${params.category}/${params.policy}/forms/${downloadRef}/`, 'blob');

      setRunning(false);
      if (responseData.response && responseData.response.status === 200) {
        setMessage('Your download has been successful!');
        const filename = slugify(downloadName);
        const blob = new Blob([responseData.response.data], { type: responseData.response.headers['content-type'] });
        saveAs(blob, filename || 'download');
        setReporting(true);
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setReporting(true);
    } else {
      setMessage("An error occured while processing your request.");
      setReporting(true);
    }};

useEffect(() => {
if (triggerDownload) {
  downloadFile();
  setTriggerDownload(false);
}
}, [triggerDownload, downloadRef]);

useEffect(() => {
  listForms();
}, []);

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      zIndex: 10000,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "transparent",
      border: "none",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minWidth: "30VW",
    },
  };

  return (
    <Container style={{ minHeight: "95VH", wordWrap: "break-word" }} fluid>
      <Helmet>
        <title>
          Forms:: {policy} -{" "}
          {localData("get", "branding").company || "Portal"}
        </title>
      </Helmet>

      <Row className="mt-3 mm-padding-large">
        <Col md={9}>
          <Card id="forms" className="mb-4">
            <Card.Header>
              <Row className="d-flex align-items-center justify-content-between">
                <Col md={6}>
                  <h6>Forms for: {policy}</h6>
                </Col>
                <Col
                  md={6}
                  className="d-flex align-items-start justify-content-end"
                ><Link
                to={`/access/policies/${params.package}/${params.category}/${params.policy}/`}
                style={{
                  cursor: "pointer",
                  textDecoration: "none",
                }}
              >
               <FontAwesomeIcon icon={faFileAlt}  /> Read Policy
              </Link></Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <Form.Control
                type="text"
                className="no-outline mm-pale-green"
                style={{ borderRadius: "0px" }}
                placeholder={`Type to filter from ${filteredSubForms.length} forms...`}
                value={search}
                onChange={handleSearchChange}
              />

              <Table className="no-border" striped borderless responsive>
                <thead>
                  <tr>
                    <th>Form</th>
                    <th>Size</th>
                    <th className="mm-center">Action</th>
                  </tr>
                </thead>
                <tbody style={{ marginBottom: "20px" }}>
                  {filteredSubForms
                    .slice(
                      (currentPage - 1) * itemsPerPage,
                      currentPage * itemsPerPage
                    )
                    .map((iform, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            height: "50px",
                            verticalAlign: "middle",
                          }}
                        >
                          <ContextMenuTrigger
                            id={iform.reference}
                            style={{}}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                height: "50px",
                                cursor: "pointer",
                              }}
                            >
                              <div style={{ width: "30px" }}>
                                {getIcon(iform.type)}
                              </div>
                              <span style={{ marginLeft: "10px" }}>
                                {iform.title}
                              </span>
                            </div>
                          </ContextMenuTrigger>

                          <ContextMenu
                            className="mm-card my-shade mm-text-white mm-padding"
                            style={{ borderRadius: "10px", zIndex: "99999" }}
                            id={iform.reference}
                          >
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                handleDownload(iform.reference);
                                setDownloadName(iform.title);
                              }}
                            >
                              <MenuItem>Download</MenuItem>
                            </span>
                          </ContextMenu>
                        </td>
                        <td
                          style={{
                            verticalAlign: "middle",
                          }}
                        >
                          {(iform.size / 1000000).toFixed(2)} MB
                        </td>
                        <td
                          style={{
                            verticalAlign: "middle",
                          }}
                        >
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="light"
                              id="dropdown-basic"
                              className="mm-color no-outline"
                              style={{ width: "100%" }}
                            >
                              Options
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                              style={{
                                borderRadius: "10px",
                                zIndex: "99999",
                                width: "100%",
                              }}
                            >
                              <Dropdown.Item
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleDownload(iform.reference);
                                  setDownloadName(iform.title);
                                }}
                              >
                                Download
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>

              <Pagination className="mm-pagination">
                <Pagination.First
                  onClick={() => handlePageClick(1)}
                  disabled={currentPage === 1}
                />
                <Pagination.Prev
                  onClick={() => handlePageClick(currentPage - 1)}
                  disabled={currentPage === 1}
                />
                {currentPage > 2 && (
                  <Pagination.Item
                    onClick={() => handlePageClick(currentPage - 2)}
                  >
                    {currentPage - 2}
                  </Pagination.Item>
                )}
                {currentPage > 1 && (
                  <Pagination.Item
                    onClick={() => handlePageClick(currentPage - 1)}
                  >
                    {currentPage - 1}
                  </Pagination.Item>
                )}
                <Pagination.Item active>{currentPage}</Pagination.Item>
                {currentPage < totalPages && (
                  <Pagination.Item
                    onClick={() => handlePageClick(currentPage + 1)}
                  >
                    {currentPage + 1}
                  </Pagination.Item>
                )}
                {currentPage < totalPages - 1 && (
                  <Pagination.Item
                    onClick={() => handlePageClick(currentPage + 2)}
                  >
                    {currentPage + 2}
                  </Pagination.Item>
                )}
                <Pagination.Next
                  onClick={() => handlePageClick(currentPage + 1)}
                  disabled={currentPage === totalPages}
                />
                <Pagination.Last
                  onClick={() => handlePageClick(totalPages)}
                  disabled={currentPage === totalPages}
                />
              </Pagination>
            </Card.Body>
          </Card>
        </Col>
        <SideBar />
      </Row>

      <Modal isOpen={running} style={customStyles}>
        <div
          className="mm-center"
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <h4 style={{ color: "#343a40" }}>Please wait...</h4>
          <br />
          <Loader />
        </div>
      </Modal>

      <Modal
        isOpen={report}
        onRequestClose={() => setReporting(false)}
        style={customStyles}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <h4>
            Message{" "}
            <span
              onClick={() => setReporting(false)}
              title="Close"
              className="mm-right"
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={faTimes} color="red" />
            </span>
          </h4>

          <hr />

          <p className="mm-center">{message}</p>

          <br />
          <button
            className="mm-btn mm-red"
            onClick={() => setReporting(false)}
            style={{ borderRadius: "30px", width: "100%" }}
          >
            CLOSE
          </button>
        </div>
      </Modal>

      <Top />
    </Container>
  );
};

export default FormsDataCustomer;
