import React, { useState, useEffect } from "react";
import { localData, runData } from "../../../jsx/backend";
import moment from "moment";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  faArrowRight,
  faPlusSquare,
  faSpinner,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Pagination,
  Row,
  Table,
} from "react-bootstrap";

const ProfileSubscriptions = ({ subscriptions, profile, portal }) => {
  const [running, setRunning] = useState(false);
  const [message, setMessage] = useState("");
  const [report, setReporting] = useState(false);
  const [subServices, setSubServices] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [service, setService] = useState("");

  const filteredSubServices = subServices.filter((subscription) =>
    `${subscription.title}`.toLowerCase().includes(search.toLowerCase())
  );

  const totalPages = Math.ceil(filteredSubServices.length / itemsPerPage);
  const [subscribing, setSubscribe] = useState(false);
  const [modify, setModify] = useState(false);

  const currentDate = new Date();
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );

  const [startDate, setStartDate] = useState(firstDayOfMonth);
  const [endDate, setEndDate] = useState(new Date());

  const today = new Date();
  const nextYear = new Date();
  nextYear.setFullYear(today.getFullYear() + 1);

  const [subscriptionDate, setSubscriptionDate] = useState(today);
  const [expiryDate, setExpiryDate] = useState(nextYear);
  const [type, setType] = useState("");
  const [packages, setPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [policies, setPolicies] = useState([]);
  const [selectedPolicy, setSelectedPolicy] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState(false);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handlePackageChange = (event) => {
    setSelectedPackage(event.target.value);
    setService(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setService(event.target.value);
  };

  const [subscr, setSubscr] = useState({
    reference: "",
    title: "",
    type: "",
    start: "",
    end: "",
    status: "",
  });

  const handleModify = (subData) => {
    setSubscr({
      reference: subData.reference,
      title: subData.title,
      type: subData.type,
      start: subData.start,
      end: subData.end,
      status: subData.status,
    });
    setModify(true);
  };

  const makeMode = async (event) => {
    event.preventDefault();
    setModify(false);
    setRunning(true);
    const data = {
      reference: subscr.reference,
      start: subscr.start,
      expiry: subscr.end,
      status: subscr.status,
    };
    for (let key in data) {
      if (data[key] === "") {
        setRunning(false);
        setMessage(`Please fill in the ${key} field. It is required`);
        setReporting(true);
        return;
      }
    }
    const responseData = await runData(
      data,
      `/query/customers/${profile.reference}/subscriptions/modify/`
    );
    setRunning(false);
    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          localData("save", "subscriptions_refresh", 1);
          setMessage(
            "You have successfully modified a subscription for the customer!"
          );
          setReporting(true);
          break;

        default:
          setMessage(responseData.response.data.message);
          setReporting(true);
      }
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setReporting(true);
    } else {
      setMessage("An error occured while processing your request.");
      setReporting(true);
    }
  };

  const handlePolicyChange = (event) => {
    setSelectedPolicy(event.target.value);
    setService(event.target.value);
  };
  const [data, setData] = useState([]);

  const filteredData = data.filter((item) => {
    const itemDate = new Date(item.date);
    return itemDate >= startDate && itemDate <= endDate;
  });

  const handleTypeChange = (event) => {
    setType(event.target.value);
    setSelectedPackage("");
    setSelectedCategory("");
    setSelectedPolicy("");
  };

  const subscribe = async (event) => {
    event.preventDefault();
    setSubscribe(false);
    setRunning(true);
    const data = {
      type: type,
      service:
        type === "composer" || type === "templates"
          ? profile.reference
          : service,
      start: subscriptionDate,
      expiry: expiryDate,
    };
    for (let key in data) {
      if (data[key] === "") {
        setRunning(false);
        setMessage(`Please fill in the ${key} field. It is required`);
        setReporting(true);
        return;
      }
    }
    const responseData = await runData(
      data,
      `/query/customers/${profile.reference}/subscribe`
    );
    setRunning(false);
    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          localData("save", "subscriptions_refresh", 1);
          setMessage("You have successfully added a subscription to customer!");
          setReporting(true);
          break;

        default:
          setMessage(responseData.response.data.message);
          setReporting(true);
      }
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setReporting(true);
    } else {
      setMessage("An error occured while processing your request.");
      setReporting(true);
    }
  };
  const fetchPackages = async () => {
    const data = {};
    const responseData = await runData(data, "/query/policies/");
    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          setPackages(responseData.response.data.packages);
          break;

        default:
          setSubscribe(false);
          setMessage(responseData.response.data.message);
          setReporting(true);
      }
    } else if (responseData.error) {
      setSubscribe(false);
      setMessage(responseData.error.message);
      setReporting(true);
    } else {
      setSubscribe(false);
      setMessage("An error occured while processing your request.");
      setReporting(true);
    }
  };

  const fetchCategories = async () => {
    const data = {};
    const responseData = await runData(
      data,
      `/query/policies/${selectedPackage}/`
    );
    setLoading(false);
    setError(false);
    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          setCategories(responseData.response.data.categories);
          break;

        default:
          setSubscribe(false);
          setMessage(responseData.response.data.message);
          setReporting(true);
      }
    } else if (responseData.error) {
      setSubscribe(false);
      setMessage(responseData.error.message);
      setReporting(true);
    } else {
      setSubscribe(false);
      setMessage("An error occured while processing your request.");
      setReporting(true);
    }
  };

  const fetchPolicies = async () => {
    const data = {};
    const responseData = await runData(
      data,
      `/query/policies/${selectedPackage}/${selectedCategory}/`
    );
    setLoading(false);
    setError(false);
    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          setPolicies(responseData.response.data.policies);
          break;

        default:
          setSubscribe(false);
          setMessage(responseData.response.data.message);
          setReporting(true);
      }
    } else if (responseData.error) {
      setSubscribe(false);
      setMessage(responseData.error.message);
      setReporting(true);
    } else {
      setSubscribe(false);
      setMessage("An error occured while processing your request.");
      setReporting(true);
    }
  };

  useEffect(() => {
    if (type === "package" || type === "policy") {
      fetchPackages();
    }
  }, [type]);

  useEffect(() => {
    if (selectedPackage && (type === "category" || type === "policy")) {
      fetchCategories();
    }
  }, [selectedPackage, type]);

  useEffect(() => {
    if (selectedCategory && type === "policy") {
      fetchPolicies();
    }
  }, [selectedCategory, type]);

  useEffect(() => {
    setSubServices(subscriptions);
  }, [subscriptions]);

  const customStyles = {
    overlay: {
      backgroundColor: "#ffffffeb",
      zIndex: 10000,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "transparent",
      border: "none",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minWidth: "30VW",
    },
  };

  return (
    <div>
      <Card id="subscriptions" className="mb-4">
        <Card.Header>
          <Row className="d-flex align-items-center justify-content-between">
            <Col md={6}>
              <h6>Customer's Subscriptions</h6>
            </Col>
            <Col
              md={6}
              className="d-flex align-items-start justify-content-end"
            >
              <Button
                variant="link"
                onClick={() => setSubscribe(true)}
                style={{ color: "#aaa", textDecoration: "none" }}
                title="Add Services"
              >
                <FontAwesomeIcon icon={faPlusSquare} /> Add
              </Button>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Form.Control
            type="text"
            className="no-outline mm-pale-green"
            style={{ borderRadius: "0px" }}
            placeholder="Search"
            value={search}
            onChange={handleSearchChange}
          />

          <Table className="no-border" striped borderless responsive>
            <thead>
              <tr>
                <th>Subscription</th>
                <th>Type</th>
                <th>Start</th>
                <th>Expiry</th>
                <th>Status</th>
                <th className="mm-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredSubServices
                .slice(
                  (currentPage - 1) * itemsPerPage,
                  currentPage * itemsPerPage
                )
                .map((subscription, index) => (
                  <tr key={index}>
                    <td
                      style={{
                        backgroundColor: subscription.status === 2 && "red",
                        verticalAlign: "middle",
                      }}
                    >
                      {subscription.title}
                    </td>
                    <td
                      style={{
                        backgroundColor: subscription.status === 2 && "red",
                        verticalAlign: "middle",
                      }}
                    >
                      {subscription.type}
                    </td>
                    <td
                      style={{
                        backgroundColor: subscription.status === 2 && "red",
                        verticalAlign: "middle",
                      }}
                    >
                      {moment(subscription.start).format("Do of MMMM YYYY")}
                    </td>
                    <td
                      style={{
                        backgroundColor: subscription.status === 2 && "red",
                        verticalAlign: "middle",
                      }}
                    >
                      {moment(subscription.end).format("Do of MMMM YYYY")}
                    </td>
                    <td
                      style={{
                        backgroundColor: subscription.status === 2 && "red",
                        verticalAlign: "middle",
                      }}
                    >
                      {(() => {
                        switch (subscription.status) {
                          case 1:
                            return "Enabled";
                          case 2:
                            return "Suspended";
                          default:
                            return "Unknown";
                        }
                      })()}
                    </td>
                    <td
                      style={{
                        backgroundColor: subscription.status === 2 && "red",
                        verticalAlign: "middle",
                      }}
                    >
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="light"
                          id="dropdown-basic"
                          className="mm-color no-outline"
                          style={{ width: "100%" }}
                        >
                          Options
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={{ width: "100%" }}>
                          <Dropdown.Item
                            onClick={() => handleModify(subscription)}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              Modify
                              <FontAwesomeIcon icon={faArrowRight} />
                            </div>
                          </Dropdown.Item>

                          <Dropdown.Divider />
                          <Dropdown.Item>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              className="mm-text-red"
                            >
                              Delete
                              <FontAwesomeIcon icon={faArrowRight} />
                            </div>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>

          <Pagination>
            <Pagination.First
              onClick={() => handlePageClick(1)}
              disabled={currentPage === 1}
            />
            <Pagination.Prev
              onClick={() => handlePageClick(currentPage - 1)}
              disabled={currentPage === 1}
            />
            {currentPage > 2 && (
              <Pagination.Item onClick={() => handlePageClick(currentPage - 2)}>
                {currentPage - 2}
              </Pagination.Item>
            )}
            {currentPage > 1 && (
              <Pagination.Item onClick={() => handlePageClick(currentPage - 1)}>
                {currentPage - 1}
              </Pagination.Item>
            )}
            <Pagination.Item active>{currentPage}</Pagination.Item>
            {currentPage < totalPages && (
              <Pagination.Item onClick={() => handlePageClick(currentPage + 1)}>
                {currentPage + 1}
              </Pagination.Item>
            )}
            {currentPage < totalPages - 1 && (
              <Pagination.Item onClick={() => handlePageClick(currentPage + 2)}>
                {currentPage + 2}
              </Pagination.Item>
            )}
            <Pagination.Next
              onClick={() => handlePageClick(currentPage + 1)}
              disabled={currentPage === totalPages}
            />
            <Pagination.Last
              onClick={() => handlePageClick(totalPages)}
              disabled={currentPage === totalPages}
            />
          </Pagination>
        </Card.Body>
      </Card>

      <Modal
        isOpen={modify}
        onRequestClose={() => setModify(false)}
        style={customStyles}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <form
            onSubmit={makeMode}
            className="mm-padding"
            style={{ marginBottom: "30px" }}
          >
            <h4 className="mm-center" style={{ marginBottom: "30px" }}>
              Modify Subscription :: {subscr.title}
            </h4>
            <hr />
            <label className="mm-label-create">Subscription Dates</label>
            <div className="mm-container" style={{ marginTop: "60px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <div style={{ position: "relative", width: "45%" }}>
                  <DatePicker
                    selected={new Date(subscr.start)}
                    onChange={(date) => setSubscr({ ...subscr, start: date })}
                    className="date-picker"
                  />
                  <label for="startDate" className="date-label">
                    Start Date
                  </label>
                </div>
                <div style={{ position: "relative", width: "45%" }}>
                  <DatePicker
                    selected={new Date(subscr.end)}
                    onChange={(date) => setSubscr({ ...subscr, end: date })}
                    className="date-picker"
                  />
                  <label for="endDate" className="date-label">
                    End Date
                  </label>
                </div>
              </div>
            </div>
            <label className="mm-label-create">Subscription Status</label>
            <div className="mm-container" style={{ marginBottom: "30px" }}>
              <select
                className="mm-input-create"
                value={
                  subscr.status === 1
                    ? "enabled"
                    : subscr.status === 2
                    ? "suspended"
                    : ""
                }
                onChange={(e) => {
                  let newStatus =
                    e.target.value === "enabled"
                      ? 1
                      : e.target.value === "suspended"
                      ? 2
                      : 0;
                  setSubscr({ ...subscr, status: newStatus });
                }}
              >
                <option value="">Select Status</option>
                <option value="enabled">Enabled</option>
                <option value="suspended">Suspended</option>
              </select>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <button
                onClick={() => setModify(false)}
                className="mm-left"
                type="button"
                style={{
                  backgroundColor:
                    localData("get", "branding").shade || "#eeeeee",
                  width: "48%",
                  color: "#fff",
                  padding: "10px",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                  transition: "background-color 0.3s ease",
                  fontSize: "16px",
                }}
              >
                CANCEL
              </button>
              <button
                className="mm-right"
                type="submit"
                style={{
                  backgroundColor:
                    localData("get", "branding").color || "#eeeeee",
                  width: "48%",
                  color: "#fff",
                  padding: "10px",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                  transition: "background-color 0.3s ease",
                  fontSize: "16px",
                }}
              >
                MODIFY
              </button>
            </div>
          </form>
        </div>
      </Modal>

      <Modal
        isOpen={subscribing}
        onRequestClose={() => setSubscribe(false)}
        style={customStyles}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <form
            onSubmit={subscribe}
            className="mm-padding"
            style={{ marginBottom: "30px" }}
          >
            <h6 className="mm-center" style={{ marginBottom: "30px" }}>
              Add Subscription
            </h6>
            <div className="mm-container" style={{ marginBottom: "30px" }}>
              <label className="mm-label-create">
                What service do you want to add to {profile.company}?
              </label>
              <select
                className="mm-input-create"
                value={type}
                onChange={handleTypeChange}
              >
                <option value="">Select type</option>
                <option value="package">Package</option>
                <option value="category">Category</option>
                <option value="policy">Policy</option>
                <option value="composer">Composer</option>
                <option value="templates">Templates</option>
                <option value="projects">Projects</option>
              </select>
            </div>
            {(type === "package" ||
              type === "policy" ||
              type === "category") && (
              <div className="mm-container" style={{ marginBottom: "30px" }}>
                <label className="mm-label-create">
                  What package do you want to use?
                </label>
                <select
                  className="mm-input-create"
                  value={selectedPackage}
                  onChange={handlePackageChange}
                >
                  <option value="">Select package</option>
                  {packages.map((pkg) => (
                    <option key={pkg.reference} value={pkg.reference}>
                      {pkg.title}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {(type === "policy" || type === "category") && selectedPackage && (
              <div className="mm-container" style={{ marginBottom: "30px" }}>
                <label className="mm-label-create">
                  What category from this package do you want to use?
                </label>
                <select
                  className="mm-input-create"
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                >
                  <option value="">Select category</option>
                  {categories.map((category) => (
                    <option key={category.reference} value={category.reference}>
                      {category.title}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {type === "policy" && selectedCategory && (
              <div className="mm-container" style={{ marginBottom: "30px" }}>
                <label className="mm-label-create">
                  Select policy from this package and the selected category?
                </label>
                <select
                  className="mm-input-create"
                  value={selectedPolicy}
                  onChange={handlePolicyChange}
                >
                  <option value="">Select policy</option>
                  {policies.map((policy) => (
                    <option key={policy.reference} value={policy.reference}>
                      {policy.title}
                    </option>
                  ))}
                </select>
              </div>
            )}

            <div className="mm-container">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <div style={{ position: "relative", width: "45%" }}>
                  <DatePicker
                    selected={subscriptionDate}
                    onChange={(date) => setSubscriptionDate(date)}
                    className="date-picker"
                  />
                  <label for="startDate" className="date-label">
                    Start Date
                  </label>
                </div>
                <div style={{ position: "relative", width: "45%" }}>
                  <DatePicker
                    selected={expiryDate}
                    onChange={(date) => setExpiryDate(date)}
                    className="date-picker"
                  />
                  <label for="endDate" className="date-label">
                    End Date
                  </label>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <button
                onClick={() => setSubscribe(false)}
                className="mm-left"
                type="button"
                style={{
                  backgroundColor:
                    localData("get", "branding").shade || "#eeeeee",
                  width: "48%",
                  color: "#fff",
                  padding: "10px",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                  transition: "background-color 0.3s ease",
                  fontSize: "16px",
                }}
              >
                CANCEL
              </button>
              <button
                className="mm-right"
                type="submit"
                style={{
                  backgroundColor:
                    localData("get", "branding").color || "#eeeeee",
                  width: "48%",
                  color: "#fff",
                  padding: "10px",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                  transition: "background-color 0.3s ease",
                  fontSize: "16px",
                }}
              >
                SUBSCRIBE
              </button>
            </div>
          </form>
        </div>
      </Modal>

      <Modal isOpen={running} style={customStyles}>
        <div
          className="mm-center"
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <h6 style={{ color: "#343a40" }}>Please wait...</h6>
          <br />
          <FontAwesomeIcon icon={faSpinner} size="5x" spin />
        </div>
      </Modal>

      <Modal
        isOpen={report}
        onRequestClose={() => setReporting(false)}
        style={customStyles}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <h6>
            Message{" "}
            <span
              onClick={() => setReporting(false)}
              title="Close"
              className="mm-right"
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={faTimes} color="red" />
            </span>
          </h6>

          <hr />

          <p className="mm-center">{message}</p>

          <br />
          <button
            className="mm-btn mm-red"
            onClick={() => setReporting(false)}
            style={{ borderRadius: "30px", width: "100%" }}
          >
            CLOSE
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ProfileSubscriptions;
