import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import Top from "../../includes/topping";
import { Link } from "react-router-dom";
import { apiData, localData, cartData, runData } from "../../../jsx/backend";
import Loader from "../../includes/loader";
import { useDropzone } from "react-dropzone";
import { slugify } from "../../includes/cleaning";
import { saveAs } from "file-saver";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  Form,
  ListGroup,
  Pagination,
  Row,
  Table,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  faFilePdf,
  faTimes,
  faBars,
  faChevronCircleUp,
  faDownload,
  faDotCircle,
  faToggleOn,
  faToggleOff,
  faPlusSquare,
  faChartLine,
  faFileArchive,
  faBoxOpen,
  faFileEdit,
  faPencilSquare,
  faCogs,
  faSearch,
  faTimesCircle,
  faSortUp,
  faSortDown,
  faPlusCircle,
  faFile,
  faEye,
  faFileCirclePlus,
  faPencilAlt,
  faUpload,
  faFolderPlus,
  faTrashAlt,
  faDashboard,
  faHistory,
  faFileDownload,
  faTicketAlt,
  faNoteSticky,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import getIcon from "../../includes/icons";

Modal.setAppElement("#root");

const Templates = () => {
  const auth = localStorage.getItem("session");
  const [templates, setTemplates] = useState([]);
  const [updating, setUpdating] = useState(false);
  const [creator, setCreator] = useState(false);
  const [creatingMessage, setCreatingMessage] = useState(null);
  const [creating, setCreating] = useState(false);
  const [created, setCreated] = useState(false);
  const [errorPage, setErrorPage] = useState(null);
  const [randMessage, setRandMessage] = useState(null);
  const [updatingMessage, setUpdatingMessage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [title, setTitle] = useState("");
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [currentItemReference, setCurrentItemReference] = useState(null);
  const [currentTitle, setCurrentTitle] = useState("");

  const [loading, setLoading] = useState(true);
  const [running, setRunning] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [report, setReporting] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [isOn, setIsOn] = useState(1);
  const [stats, setStats] = useState(false);

  const currentDate = new Date();
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );

  const [startDate, setStartDate] = useState(firstDayOfMonth);
  const [endDate, setEndDate] = useState(new Date());

  const [data, setData] = useState([]);

  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const filteredSubTemplates = templates.filter((itemplate) =>
    itemplate.title.toLowerCase().includes(search.toLowerCase())
  );

  const totalPages = Math.ceil(filteredSubTemplates.length / itemsPerPage);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const filteredData = data.filter((item) => {
    const itemDate = new Date(item.date);
    return itemDate >= startDate && itemDate <= endDate;
  });

  const handleRemoveFile = () => {
    setSelectedFile(null);
  };

  const listTemplates = async () => {
    setLoading(true);
    const data = {};

    const responseData = await runData(data, `/query/templates/`);
    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          setTemplates(responseData.response.data.results);
          setLoading(false);
          setLoaded(true);
          break;
        default:
          setRandMessage(responseData.response.data.message);
          setErrorPage(true);
          setLoading(false);
      }
    } else if (responseData.error) {
      setRandMessage(responseData.error.message);
      setLoading(false);
      setErrorPage(true);
    } else {
      setRandMessage("An error occured while processing your request.");
      setLoading(false);
      setErrorPage(true);
    }
  };

  const handleEdit = (reference) => {
    setCurrentItemReference(reference);
    setEditModalIsOpen(true);
  };

  const handleDelete = (reference) => {
    setCurrentItemReference(reference);
    setDeleteModalIsOpen(true);
  };

  const confirmDelete = async () => {
    setDeleteModalIsOpen(false);
    setRunning(true);

    const data = {};
    const responseData = await runData(
      data,
      `/query/templates/delete/${currentItemReference}/`
    );

    setRunning(false);

    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          setMessage("Deleted Successfully!");
          listTemplates();
          setReporting(true);
          break;

        default:
          setMessage(responseData.response.data.message);
          setReporting(true);
      }
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setReporting(true);
    } else {
      setMessage("An error occured while processing your request.");
      setReporting(true);
    }
  };

  const confirmEdit = async (event) => {
    event.preventDefault();
    setRunning(true);
    setEditModalIsOpen(false);

    const data = {
      title: currentTitle,
    };
    const responseData = await runData(
      data,
      `/query/templates/edit/${currentItemReference}/`
    );
    setRunning(false);
    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          setMessage("Updated Successfully!");
          setReporting(true);
          listTemplates();
          break;

        default:
          setMessage(responseData.response.data.message);
          setReporting(true);
      }
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setReporting(true);
    } else {
      setMessage("An error occured while processing your request.");
      setReporting(true);
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    setSelectedFile(acceptedFiles[0]);
  }, []);
  const [downloadRef, setDownloadRef] = useState("");
  const [downloadName, setDownloadName] = useState("Attachment");
  const [triggerDownload, setTriggerDownload] = useState(false);

  const handleDownload = (ref) => {
    setDownloadRef(ref);
    setTriggerDownload(true);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
  });

  const styles = {
    border: "dashed 3px #eeeeee",
    borderRadius: "5px",
    padding: "60px",
    textAlign: "center",
    color: "#bdbdbd",
    backgroundColor: "#fafafa",
    cursor: "pointer",
  };

  const activeStyles = {
    borderColor: "#2196f3",
  };

  const handleSubmit = async () => {
    setRunning(true);
    setCreator(false);
    if (!selectedFile) {
      setRunning(false);
      setMessage("No file selected. Please select a file to upload.");
      setReporting(true);
      return;
    }

    if (selectedFile.size === 0) {
      setRunning(false);
      setMessage("The selected file is empty. Please select a non-empty file.");
      setReporting(true);
      return;
    }

    const data = {
      title: title || selectedFile.name,
      attachment: selectedFile,
    };

    const responseData = await runData(data, `/query/templates/upload/`);
    setRunning(false);

    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          setTitle("");
          setSelectedFile(null);
          setMessage("Your upload has been successful.");
          setReporting(true);
          listTemplates();
          break;

        default:
          setMessage(responseData.response.data.message);
          setReporting(true);
      }
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setReporting(true);
    } else {
      setMessage("An error occurred while processing your request.");
      setReporting(true);
    }
  };

  const downloadFile = async () => {
    setRunning(true);

    const data = {};

    const responseData = await runData(
      data,
      `/query/templates/download/${downloadRef}/`,
      "blob"
    );
    setRunning(false);
    if (responseData.response && responseData.response.status === 200) {
      setMessage("Your download has been successful!");
      const filename = slugify(downloadName);
      const blob = new Blob([responseData.response.data], {
        type: responseData.response.headers["content-type"],
      });
      saveAs(blob, filename || "download");
      setReporting(true);
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setReporting(true);
    } else {
      setMessage("An error occured while processing your request.");
      setReporting(true);
    }
  };

  useEffect(() => {
    if (triggerDownload) {
      downloadFile();
      setTriggerDownload(false);
    }
  }, [triggerDownload, downloadRef]);

  useEffect(() => {
    listTemplates();
  }, []);

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      zIndex: 10000,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "transparent",
      border: "none",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minWidth: "30VW",
    },
  };

  return (
    <Container style={{ minHeight: "95VH", wordWrap: "break-word" }} fluid>
      <Helmet>
        <title>
          Business Templates -{" "}
          {localData("get", "branding").company || "Portal"}
        </title>
      </Helmet>

      <Row className="mt-3 mm-padding-large">
        <Col md={3} style={{ marginBottom: "30px" }}>
          <ListGroup style={{ position: "sticky", top: "100px" }}>
            <ListGroup.Item action>
              <FontAwesomeIcon icon={faDashboard} /> Summary
            </ListGroup.Item>
            <ListGroup.Item action>
              <FontAwesomeIcon icon={faHistory} /> Activity
            </ListGroup.Item>
            <ListGroup.Item action>
              <FontAwesomeIcon icon={faFileDownload} /> Downloads
            </ListGroup.Item>
            <ListGroup.Item action>
              <FontAwesomeIcon icon={faTicketAlt} /> Tickets
            </ListGroup.Item>
            <ListGroup.Item action>
              <FontAwesomeIcon icon={faNoteSticky} /> Comments
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col md={9}>
          <Card id="templates" className="mb-4">
            <Card.Header>
              <Row className="d-flex align-items-center justify-content-between">
                <Col md={6}>
                  <h6>Business Templates</h6>
                </Col>
                <Col
                  md={6}
                  className="d-flex align-items-start justify-content-end"
                >
                  <Button
                    variant="link"
                    onClick={() => setCreator(true)}
                    style={{ color: "black", textDecoration: "none" }}
                    title="Add Template"
                  >
                    <FontAwesomeIcon icon={faUpload} />
                    Upload
                  </Button>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <Form.Control
                type="text"
                className="no-outline mm-pale-green"
                style={{ borderRadius: "0px" }}
                placeholder={`Type to filter from ${filteredSubTemplates.length} templates...`}
                value={search}
                onChange={handleSearchChange}
              />

              <Table className="no-border" striped borderless responsive>
                <thead>
                  <tr>
                    <th>Template</th>
                    <th>Size</th>
                    <th className="mm-center">Action</th>
                  </tr>
                </thead>
                <tbody style={{ marginBottom: "20px" }}>
                  {filteredSubTemplates
                    .slice(
                      (currentPage - 1) * itemsPerPage,
                      currentPage * itemsPerPage
                    )
                    .map((itemplate, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            height: "50px",
                            verticalAlign: "middle",
                          }}
                        >
                          <ContextMenuTrigger
                            id={itemplate.reference}
                            style={{}}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                height: "50px",
                                cursor: "pointer"
                              }}
                            >
                              {getIcon(itemplate.type)}
                              <span style={{ marginLeft: "10px" }}>
                                {itemplate.title}
                              </span>
                            </div>
                          </ContextMenuTrigger>

                          <ContextMenu
                            className="mm-card my-shade mm-text-white mm-padding"
                            style={{ borderRadius: "10px", zIndex: "99999" }}
                            id={itemplate.reference}
                          >
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                handleDownload(itemplate.reference);
                                setDownloadName(itemplate.title);
                              }}
                            >
                              <MenuItem>Download</MenuItem>
                            </span>
                            <hr />
                            <span style={{ cursor: "pointer" }} title="Edit">
                              <MenuItem
                                onClick={() => {
                                  handleEdit(itemplate.reference);
                                  setCurrentTitle(itemplate.title);
                                }}
                              >
                                Edit
                              </MenuItem>
                            </span>
                            <hr />
                            <span style={{ cursor: "pointer" }} title="Delete">
                              <MenuItem
                                onClick={() => {
                                  handleDelete(itemplate.reference);
                                  setCurrentTitle(itemplate.title);
                                }}
                              >
                                Delete
                              </MenuItem>
                            </span>
                          </ContextMenu>
                        </td>
                        <td
                          style={{
                            verticalAlign: "middle",
                          }}
                        >
                          {(itemplate.size / 1000000).toFixed(2)} MB
                        </td>
                        <td
                          style={{
                            verticalAlign: "middle",
                          }}
                        >
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="light"
                              id="dropdown-basic"
                              className="mm-color no-outline"
                              style={{ width: "100%" }}
                            >
                              Options
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                              style={{
                                borderRadius: "10px",
                                zIndex: "99999",
                                width: "100%",
                              }}
                            >
                              <Dropdown.Item
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleDownload(itemplate.reference);
                                  setDownloadName(itemplate.title);
                                }}
                              >
                                Download
                              </Dropdown.Item>
                              <Dropdown.Divider />
                              <Dropdown.Item
                                style={{ cursor: "pointer" }}
                                title="Edit"
                                onClick={() => {
                                  handleEdit(itemplate.reference);
                                  setCurrentTitle(itemplate.title);
                                }}
                              >
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Divider />
                              <Dropdown.Item
                                style={{ cursor: "pointer" }}
                                title="Delete"
                                onClick={() => {
                                  handleDelete(itemplate.reference);
                                  setCurrentTitle(itemplate.title);
                                }}
                              >
                                Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>

              <Pagination className="mm-pagination">
                <Pagination.First
                  onClick={() => handlePageClick(1)}
                  disabled={currentPage === 1}
                />
                <Pagination.Prev
                  onClick={() => handlePageClick(currentPage - 1)}
                  disabled={currentPage === 1}
                />
                {currentPage > 2 && (
                  <Pagination.Item
                    onClick={() => handlePageClick(currentPage - 2)}
                  >
                    {currentPage - 2}
                  </Pagination.Item>
                )}
                {currentPage > 1 && (
                  <Pagination.Item
                    onClick={() => handlePageClick(currentPage - 1)}
                  >
                    {currentPage - 1}
                  </Pagination.Item>
                )}
                <Pagination.Item active>{currentPage}</Pagination.Item>
                {currentPage < totalPages && (
                  <Pagination.Item
                    onClick={() => handlePageClick(currentPage + 1)}
                  >
                    {currentPage + 1}
                  </Pagination.Item>
                )}
                {currentPage < totalPages - 1 && (
                  <Pagination.Item
                    onClick={() => handlePageClick(currentPage + 2)}
                  >
                    {currentPage + 2}
                  </Pagination.Item>
                )}
                <Pagination.Next
                  onClick={() => handlePageClick(currentPage + 1)}
                  disabled={currentPage === totalPages}
                />
                <Pagination.Last
                  onClick={() => handlePageClick(totalPages)}
                  disabled={currentPage === totalPages}
                />
              </Pagination>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal
        isOpen={editModalIsOpen}
        onRequestClose={() => setEditModalIsOpen(false)}
        style={customStyles}
      >
        <div
          className="mm-center"
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <h4>Edit Title: {currentTitle}</h4>
          <form
            onSubmit={confirmEdit}
            style={{ width: "100%", marginTop: "30px" }}
          >
            <input
              name="title"
              placeholder="Package title..."
              type="text"
              className="mm-input mm-border"
              style={{
                width: "100%",
                boxSizing: "border-box",
                display: "block",
                borderRadius: "10px",
                outline: "none",
              }}
              value={currentTitle}
              onChange={(e) => setCurrentTitle(e.target.value)}
              required
            />
            <br />
            <button
              onClick={() => setEditModalIsOpen(false)}
              className="mm-left"
              type="button"
              style={{
                backgroundColor: "red",
                width: "48%",
                color: "#fff",
                padding: "10px",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                transition: "background-color 0.3s ease",
              }}
            >
              CANCEL
            </button>
            <button
              className="mm-right"
              type="submit"
              style={{
                backgroundColor: "green",
                width: "48%",
                color: "#fff",
                padding: "10px",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                transition: "background-color 0.3s ease",
              }}
            >
              UPDATE
            </button>
          </form>
        </div>
      </Modal>

      <Modal
        isOpen={deleteModalIsOpen}
        onRequestClose={() => setDeleteModalIsOpen(false)}
        style={customStyles}
      >
        <div
          className="mm-center"
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <h4>Delete Template: {currentTitle}</h4>
          <br />
          <div className="mm-container" style={{ width: "100%" }}>
            <p>Are you sure you want to delete this item?</p>
            <br />
            <button
              onClick={() => setDeleteModalIsOpen(false)}
              className="mm-left"
              type="button"
              style={{
                backgroundColor: "red",
                width: "48%",
                color: "#fff",
                padding: "10px",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                transition: "background-color 0.3s ease",
              }}
            >
              CANCEL
            </button>
            <button
              onClick={confirmDelete}
              className="mm-right my-color"
              type="submit"
              style={{
                width: "48%",
                color: "#fff",
                padding: "10px",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                transition: "background-color 0.3s ease",
              }}
            >
              DELETE
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={creator}
        onRequestClose={() => setCreator(false)}
        style={customStyles}
      >
        <div
          className="mm-container"
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <h4>
            UPLOAD TEMPLATE{" "}
            <span
              onClick={() => setCreator(false)}
              className="mm-right mm-text-red"
              style={{ cursor: "pointer" }}
              title="Close"
            >
              <FontAwesomeIcon icon={faTimesCircle} /> CLOSE
            </span>
          </h4>
          <br />
          <div
            {...getRootProps()}
            style={isDragActive ? { ...styles, ...activeStyles } : styles}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the file here ...</p>
            ) : (
              <p>Drag 'n' drop a file here, or click to select a file</p>
            )}
          </div>
          {selectedFile && (
            <div className="file-preview">
              <div className="file-icon">
                <div
                  style={{
                    height: "50px",
                    width: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {getIcon(selectedFile.type)}
                </div>
              </div>
              <div className="file-info">
                <p>{selectedFile.name}</p>
              </div>
              <button onClick={handleRemoveFile} className="remove-file-button">
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            </div>
          )}
          <br />
          <div
            style={{
              display: "flex",
              border: "1px solid #ccc",
              borderRadius: "4px",
              overflow: "hidden",
            }}
          >
            <input
              type="text"
              style={{ flexGrow: 1, border: "none", padding: "10px" }}
              placeholder="Enter your file title here..."
              value={title}
              onChange={(event) => setTitle(event.target.value)}
              required
            />
            <button
              style={{
                background: localData("get", "branding").color || "#8b7f83",
                cursor: "pointer",
                color: "#fff",
                border: "none",
                padding: "10px 20px",
              }}
              onClick={handleSubmit}
            >
              UPLOAD
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={stats}
        onRequestClose={() => setStats(false)}
        style={customStyles}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <div
            className="mm-light-grey mm-padding"
            style={{ marginBottom: "30px" }}
          >
            <div className="mm-container">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <div style={{ position: "relative", width: "45%" }}>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    className="date-picker"
                  />
                  <label for="startDate" className="date-label">
                    Start Date
                  </label>
                </div>
                <div style={{ position: "relative", width: "45%" }}>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    className="date-picker"
                  />
                  <label for="endDate" className="date-label">
                    End Date
                  </label>
                </div>
              </div>
              <ResponsiveContainer width="100%" height={400}>
                <LineChart data={filteredData}>
                  <CartesianGrid strokeDasharray="1 1" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="Templates"
                    stroke="#8884d8"
                    activeDot={{ r: 8 }}
                  />
                  <Line type="monotone" dataKey="Errors" stroke="#82ca9d" />
                  <Line type="monotone" dataKey="Clones" stroke="#ffc658" />
                  <Line type="monotone" dataKey="Downloads" stroke="#ff9933" />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
          <h4>Business Templates</h4>
          <br />
          <button
            onClick={() => setStats(false)}
            className="mm-small my-color zoom-on-hover mm-text-white"
            style={{
              padding: "10px 20px",
              borderRadius: "5px",
              border: "none",
              cursor: "pointer",
              width: "100%",
            }}
          >
            CLOSE STATISTICS
          </button>
        </div>
      </Modal>
      <Modal isOpen={running} style={customStyles}>
        <div
          className="mm-center"
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <h4 style={{ color: "#343a40" }}>Please wait...</h4>
          <br />
          <Loader />
        </div>
      </Modal>

      <Modal
        isOpen={report}
        onRequestClose={() => setReporting(false)}
        style={customStyles}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <h4>
            Message{" "}
            <span
              onClick={() => setReporting(false)}
              title="Close"
              className="mm-right"
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={faTimes} color="red" />
            </span>
          </h4>

          <hr />

          <p className="mm-center">{message}</p>

          <br />
          <button
            className="mm-btn mm-red"
            onClick={() => setReporting(false)}
            style={{ borderRadius: "30px", width: "100%" }}
          >
            CLOSE
          </button>
        </div>
      </Modal>

      <Top />
    </Container>
  );
};

export default Templates;
