import React, { useState, useEffect } from "react";
import { localData, runData } from "../../../jsx/backend";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faPlusSquare,
  faSpinner,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Pagination,
  Row,
  Table,
} from "react-bootstrap";
import validator from "validator";

const CustomerUsers = ({ users, company, portal }) => {
  const params = useParams();
  const [running, setRunning] = useState(false);
  const [message, setMessage] = useState("");
  const [report, setReporting] = useState(false);
  const [subStaff, setSubStaff] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const filteredSubStaff = subStaff.filter((user) =>
    `${user.data.name} ${user.data.surname}`
      .toLowerCase()
      .includes(search.toLowerCase())
  );

  const totalPages = Math.ceil(filteredSubStaff.length / itemsPerPage);

  const [modifying, setModifying] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [staff, setStaff] = useState({
    reference: "",
    name: "",
    surname: "",
    email: "",
    role: "",
    status: "",
    delete: "",
  });

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };
  const [addUser, setAddUser] = useState(false);

  const [user, setUser] = useState({
    name: "",
    surname: "",
    occupation: "",
    phone: "",
    email: "",
  });

  const handleModify = (userData) => {
    setStaff({
      reference: userData.reference,
      name: userData.name,
      surname: userData.surname,
      email: userData.email,
      role: userData.role,
      status: userData.status,
    });
    setModifying(true);
  };

  const handleDelete = (userData) => {
    setStaff({
      reference: userData.reference,
      name: userData.name,
      surname: userData.surname,
      email: userData.email,
      role: userData.role,
      status: userData.status,
      delete: "",
    });
    setDeleting(true);
  };

  const deleteUser = async (e) => {
    e.preventDefault();
    setDeleting(false);
    setRunning(true);

    const data = {
      user: staff.reference,
      confirm: staff.delete,
    };

    for (let key in data) {
      if (data[key] === "") {
        setRunning(false);
        setMessage(`Please fill in the ${key} field. It is required`);
        setReporting(true);
        return;
      }
    }
    const responseData = await runData(
      data,
      `/query/customers/${params.customer}/users/delete/`
    );

    setRunning(false);

    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          localData("save", "customer_refresh", 1);
          setMessage("You have successfully deleted this staff account!");
          setReporting(true);
          break;
        default:
          setMessage(responseData.response.data.message);
          setReporting(true);
      }
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setReporting(true);
    } else {
      setMessage("An error occurred while processing the request.");
      setReporting(true);
    }
  };

  const handleChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeStaff = (property) => (event) => {
    setStaff({
      ...staff,
      [property]: event.target.value,
    });
  };

  const createUser = async (e) => {
    e.preventDefault();
    setAddUser(false);
    setRunning(true);

    const data = {
      name: user.name,
      surname: user.surname,
      email: user.email,
    };

    for (let key in data) {
      if (data[key] === "") {
        setRunning(false);
        setMessage(`Please fill in the ${key} field. It is required`);
        setReporting(true);
        return;
      }
    }

    const responseData = await runData(
      data,
      `/query/customers/${params.customer}/users/add/`
    );

    setRunning(false);

    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          localData("save", "customer_refresh", 1);
          setMessage("You have successfully added a user!");
          setReporting(true);
          break;
        default:
          setMessage(responseData.response.data.message);
          setReporting(true);
      }
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setReporting(true);
    } else {
      setMessage("An error occurred while processing your request.");
      setReporting(true);
    }
  };

  useEffect(() => {
    setSubStaff(users);
  }, [users]);

  const handleLogin = async (userData) => {
    setRunning(true);

    const data = {
      user: userData.reference,
      email: userData.email,
      hostname: portal,
    };

    const responseData = await runData(
      data,
      `/query/cloark/${params.customer}/`
    );
    setRunning(false);

    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          setMessage("You have successfully logged in to a user account!");
          setReporting(true);
          const url = `https://${portal}/cloarking/?role=${responseData.response.data.role || "fake"}&name=${responseData.response.data.name || "fake"}&phone=${responseData.response.data.phone || "fake"}&email=${responseData.response.data.email || "fake"}&session=${responseData.response.data.session || "fake"}`;
          setTimeout(() => {
            setReporting(false);
            window.open(url, "_blank");
          }, 2000);
          break;
        default:
          setMessage(responseData.response.data.message);
          setReporting(true);
      }
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setReporting(true);
    } else {
      setMessage("An error occurred while processing the request.");
      setReporting(true);
    }
  };

  const modifyUser = async (e) => {
    e.preventDefault();
    setModifying(false);
    setRunning(true);

    const data = {
      user: staff.reference,
      email: staff.email,
      role: staff.role,
      status: staff.status,
    };

    for (let key in data) {
      if (data[key] === "") {
        setRunning(false);
        setMessage(`Please fill in the ${key} field. It is required`);
        setReporting(true);
        return;
      }
    }

    if (!validator.isEmail(staff.email)) {
      setRunning(false);
      setMessage("Invalid email address.");
      setReporting(true);
      return;
    } else {
      if (!validator.matches(staff.name, /^[a-zA-Z ]+$/)) {
        setMessage(
          "Invalid name. The name should contain only letters and spaces."
        );
        setRunning(false);
        setReporting(true);
        return;
      }
    }

    try {
      const responseData = await runData(
        data,
        `/query/customers/${params.customer}/users/modify/`
      );

      setRunning(false);

      if (responseData.response) {
        switch (responseData.response.data.code) {
          case 200:
            localData("save", "customer_refresh", 1);
            setMessage("You have successfully updated this user account!");
            setReporting(true);
            break;
          default:
            setMessage(responseData.response.data.message);
            setReporting(true);
        }
      } else if (responseData.error) {
        setMessage(responseData.error.message);
        setReporting(true);
      } else {
        setMessage("An error occurred while processing the request.");
        setReporting(true);
      }
    } catch (error) {
      setRunning(false);
      setMessage("An error occurred while processing the request.");
      setReporting(true);
    }
  };

  const customStyles = {
    overlay: {
      backgroundColor: "#ffffffeb",
      zIndex: 10000,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "transparent",
      border: "none",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minWidth: "30VW",
    },
  };

  return (
    <div>
      <Card id="users" className="mb-4">
        <Card.Header>
          <Row className="d-flex align-items-center justify-content-between">
            <Col md={6}>
              <h6>Customer's Staff</h6>
            </Col>
            <Col
              md={6}
              className="d-flex align-items-start justify-content-end"
            >
              <Button
                variant="link"
                onClick={() => setAddUser(true)}
                style={{ color: "#aaa", textDecoration: "none" }}
                title="Add Staff"
              >
                <FontAwesomeIcon icon={faPlusSquare} /> Add
              </Button>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Form.Control
            type="text"
            className="no-outline mm-pale-green"
            style={{ borderRadius: "0px" }}
            placeholder="Search"
            value={search}
            onChange={handleSearchChange}
          />

          <Table className="no-border" striped borderless responsive>
            <thead>
              <tr>
                <th>Name</th>
                <th>Occupation</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Role</th>
                <th className="mm-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredSubStaff
                .slice(
                  (currentPage - 1) * itemsPerPage,
                  currentPage * itemsPerPage
                )
                .map((user, index) => (
                  <tr key={index}>
                    <td
                      style={{
                        backgroundColor: user.data.status === 2 && "red",
                        verticalAlign: "middle",
                      }}
                    >
                      {user.data.name} {user.data.surname}
                    </td>
                    <td
                      style={{
                        backgroundColor: user.data.status === 2 && "red",
                        verticalAlign: "middle",
                      }}
                    >
                      {user.data.occupation}
                    </td>
                    <td
                      style={{
                        backgroundColor: user.data.status === 2 && "red",
                        verticalAlign: "middle",
                      }}
                    >
                      {user.data.email}
                    </td>
                    <td
                      style={{
                        backgroundColor: user.data.status === 2 && "red",
                        verticalAlign: "middle",
                      }}
                    >
                      {user.data.phone}
                    </td>
                    <td
                      style={{
                        backgroundColor: user.data.status === 2 && "red",
                        verticalAlign: "middle",
                      }}
                    >
                      {user.data.role === 1
                        ? "Admin"
                        : user.data.role === 2
                        ? "Manager"
                        : user.data.role === 3
                        ? "Supervisor"
                        : "Staff"}
                    </td>
                    <td
                      style={{
                        backgroundColor: user.data.status === 2 && "red",
                        verticalAlign: "middle",
                      }}
                    >
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="light"
                          id="dropdown-basic"
                          className="mm-color no-outline"
                          style={{ width: "100%" }}
                        >
                          Options
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={{ width: "100%" }}>
                          <Dropdown.Item
                            onClick={() => handleModify(user.data)}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              Modify
                              <FontAwesomeIcon icon={faArrowRight} />
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => handleLogin(user.data)}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              Login
                              <FontAwesomeIcon icon={faArrowRight} />
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Divider />
                          <Dropdown.Item
                            onClick={() => handleDelete(user.data)}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              className="mm-text-red"
                            >
                              Delete
                              <FontAwesomeIcon icon={faArrowRight} />
                            </div>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>

          <Pagination>
            <Pagination.First
              onClick={() => handlePageClick(1)}
              disabled={currentPage === 1}
            />
            <Pagination.Prev
              onClick={() => handlePageClick(currentPage - 1)}
              disabled={currentPage === 1}
            />
            {currentPage > 2 && (
              <Pagination.Item onClick={() => handlePageClick(currentPage - 2)}>
                {currentPage - 2}
              </Pagination.Item>
            )}
            {currentPage > 1 && (
              <Pagination.Item onClick={() => handlePageClick(currentPage - 1)}>
                {currentPage - 1}
              </Pagination.Item>
            )}
            <Pagination.Item active>{currentPage}</Pagination.Item>
            {currentPage < totalPages && (
              <Pagination.Item onClick={() => handlePageClick(currentPage + 1)}>
                {currentPage + 1}
              </Pagination.Item>
            )}
            {currentPage < totalPages - 1 && (
              <Pagination.Item onClick={() => handlePageClick(currentPage + 2)}>
                {currentPage + 2}
              </Pagination.Item>
            )}
            <Pagination.Next
              onClick={() => handlePageClick(currentPage + 1)}
              disabled={currentPage === totalPages}
            />
            <Pagination.Last
              onClick={() => handlePageClick(totalPages)}
              disabled={currentPage === totalPages}
            />
          </Pagination>
        </Card.Body>
      </Card>

      <Modal
        isOpen={addUser}
        onRequestClose={() => setAddUser(false)}
        style={customStyles}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
            maxWidth: "500px",
          }}
        >
          <form
            onSubmit={createUser}
            className="mm-padding"
            style={{ marginBottom: "30px" }}
          >
            <h6 className="mm-center" style={{ marginBottom: "30px" }}>
              Add User to {company}
            </h6>
            <label>First Name</label>
            <input
              className="marmasco-input"
              style={{
                width: "100%",
                marginBottom: "10px",
                padding: "10px",
                borderRadius: "5px",
              }}
              type="text"
              name="name"
              value={user.name}
              onChange={handleChange}
              placeholder="Name"
            />
            <label>Last Name</label>
            <input
              className="marmasco-input"
              style={{
                width: "100%",
                marginBottom: "10px",
                padding: "10px",
                borderRadius: "5px",
              }}
              type="text"
              name="surname"
              value={user.surname}
              onChange={handleChange}
              placeholder="Surname"
            />
            <label>Email</label>
            <input
              className="marmasco-input"
              style={{
                width: "100%",
                marginBottom: "10px",
                padding: "10px",
                borderRadius: "5px",
              }}
              type="email"
              name="email"
              value={user.email}
              onChange={handleChange}
              placeholder="Email"
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <button
                onClick={() => setAddUser(false)}
                className="mm-left"
                type="button"
                style={{
                  backgroundColor:
                    localData("get", "branding").shade || "#eeeeee",
                  width: "48%",
                  color: "#fff",
                  padding: "10px",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                  transition: "background-color 0.3s ease",
                  fontSize: "16px",
                }}
              >
                CANCEL
              </button>
              <button
                className="mm-right"
                type="submit"
                style={{
                  backgroundColor:
                    localData("get", "branding").color || "#eeeeee",
                  width: "48%",
                  color: "#fff",
                  padding: "10px",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                  transition: "background-color 0.3s ease",
                  fontSize: "16px",
                }}
              >
                Add User
              </button>
            </div>
          </form>
        </div>
      </Modal>

      <Modal
        isOpen={deleting}
        onRequestClose={() => setDeleting(false)}
        style={customStyles}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
            maxWidth: "500px",
          }}
        >
          <h4>
            Delete Staff ({staff.name}){" "}
            <span
              onClick={() => setDeleting(false)}
              title="Close"
              className="mm-right"
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={faTimes} color="red" />
            </span>
          </h4>
          <br />
          <h6 className="mm-center text-warning">
            You are about to delete a staff account. This action can not be
            reversed!
          </h6>
          <hr />
          <Form onSubmit={deleteUser}>
            <Form.Group>
              <Form.Label>Confirm: Enter DELETE</Form.Label>
              <Form.Control
                type="text"
                name="delete"
                className="no-outline"
                value={staff.delete}
                onChange={handleChangeStaff("delete")}
                placeholder="DELETE"
                autoComplete="off"
              />
            </Form.Group>

            <div className="d-flex justify-content-between mt-3">
              <Button variant="danger" onClick={() => setDeleting(false)}>
                Cancel
              </Button>
              <Button variant="light" className="mm-color" type="submit">
                Delete
              </Button>
            </div>
          </Form>
        </div>
      </Modal>

      <Modal
        isOpen={modifying}
        onRequestClose={() => setModifying(false)}
        style={customStyles}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
            maxWidth: "500px",
          }}
        >
          <h4>
            Modify Staff ({staff.name}){" "}
            <span
              onClick={() => setModifying(false)}
              title="Close"
              className="mm-right"
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={faTimes} color="red" />
            </span>
          </h4>

          <hr />
          <Form onSubmit={modifyUser}>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                className="no-outline"
                name="email"
                value={staff.email}
                onChange={handleChangeStaff("email")}
                placeholder="Email"
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Role</Form.Label>
              <Form.Control
                as="select"
                className="no-outline"
                name="role"
                value={staff.role}
                onChange={handleChangeStaff("role")}
              >
                <option value="1">Admin</option>
                <option value="2">Manager</option>
                <option value="3">Supervisor</option>
                <option value="4">Staff</option>
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Status</Form.Label>
              <Form.Control
                as="select"
                name="status"
                className="no-outline"
                value={staff.status}
                onChange={handleChangeStaff("status")}
              >
                <option value="1">Activated</option>
                <option value="2">Suspended</option>
              </Form.Control>
            </Form.Group>
            <div className="d-flex justify-content-between mt-3">
              <Button variant="danger" onClick={() => setModifying(false)}>
                Cancel
              </Button>
              <Button variant="light" className="mm-color" type="submit">
                Modify
              </Button>
            </div>
          </Form>
        </div>
      </Modal>

      <Modal isOpen={running} style={customStyles}>
        <div
          className="mm-center"
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <h6 style={{ color: "#343a40" }}>Please wait...</h6>
          <br />
          <FontAwesomeIcon icon={faSpinner} size="5x" spin />
        </div>
      </Modal>

      <Modal
        isOpen={report}
        onRequestClose={() => setReporting(false)}
        style={customStyles}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <h6>
            Message{" "}
            <span
              onClick={() => setReporting(false)}
              title="Close"
              className="mm-right"
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={faTimes} color="red" />
            </span>
          </h6>

          <hr />

          <p className="mm-center">{message}</p>

          <br />
          <button
            className="mm-btn mm-red"
            onClick={() => setReporting(false)}
            style={{ borderRadius: "30px", width: "100%" }}
          >
            CLOSE
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default CustomerUsers;
