import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { apiData, localData, runData } from "../../../../jsx/backend";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faTimes } from "@fortawesome/free-solid-svg-icons";
import getIcon from "../../../includes/icons";
import { Button, Dropdown, FloatingLabel, Form } from "react-bootstrap";
import bgImg from "../../../../img/background.jpg";

Modal.setAppElement("#root");

function Login() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [report, setReporting] = useState(false);
  const [showChat, setShowChat] = useState(localData("get", "chatting"));
  const handleClick = () => {
    localData("save", "chatting", !showChat);
    setShowChat(!showChat);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const data = {
      email: email,
      password: password,
    };

    const responseData = await runData(data, "/query/login/");
    const time = new Date().getTime();
    setLoading(false);
    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          const sessionFull = {
            name: responseData.response.data.name,
            role: responseData.response.data.role,
            token: responseData.response.data.session,
          };
          localData("save", "session", sessionFull);
          localData("save", "expiry", time);
          setMessage("You have been logged in successfully!");
          setReporting(true);
          localData("save", "messages", {
            user: {
              name: responseData.response.data.name,
              email: responseData.response.data.email,
              phone: responseData.response.data.phone,
            },
            thread: [
              {
                sender: "bot",
                text: "Hi there! I can assist you with our service information.",
                date: new Date(),
              },
            ],
          });

          break;

        case 201:
          const sessionPart = {
            name: responseData.response.data.name,
            role: responseData.response.data.role,
            token: responseData.response.data.session,
          };
          localData("save", "session", sessionPart);
          localData("save", "expiry", time);
          setMessage("You have been logged in successfully!");
          setReporting(true);
          localData("save", "messages", {
            user: {
              name: responseData.response.data.name,
              email: responseData.response.data.email,
              phone: responseData.response.data.phone,
            },
            thread: [
              {
                sender: "bot",
                text: "Hi there! I can assist you with our service information.",
                date: new Date(),
              },
            ],
          });
          break;

        default:
          setMessage(
            `${responseData.response.data.message}: Make sure you're entering your correct user email and password.`
          );
          setReporting(true);
      }
    } else if (responseData.error) {
      setLoading(false);
      setMessage(responseData.error.message);
      setReporting(true);
    } else {
      setLoading(false);
      setMessage("An error occured while processing your request.");
      setReporting(true);
    }
  };

  
  const customStyles = {
    overlay: {
      backgroundColor: "#ffffffeb",
      zIndex: 10000,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "transparent",
      border: "none",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minWidth: "30VW",
    },
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  return (
    <div
      className="home"
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        wordWrap: "break-word",
        backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 0.77) 100%, rgb(255, 255, 255) 100%), url(${bgImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Helmet>
        <title>
          Login - {localData("get", "branding").company || "Company"}
        </title>
      </Helmet>

      <div className="card">
        <div
          className="card-header d-flex justify-content-between align-items-center mm-padding"
          style={{
            background: "#eee",
            position: "sticky",
            top: 0,
            zIndex: 9,
          }}
        >
          {localData("get", "branding").logo ? (
            <Link to="/">
              <img
                src={`${apiData.defaults.baseURL}${
                  localData("get", "branding").logo
                }`}
                alt="MENU"
                className="logo"
                style={{
                  borderWidth: "3px !important",
                  flexShrink: 0,
                  maxWidth: "100%",
                  maxHeight: "55px",
                }}
              />
            </Link>
          ) : (
            <Link
              to={`/`}
              style={{
                borderWidth: "3px !important",
                flexShrink: 0,
                height: "55px",
                width: "50%",
              }}
              className="parent-img"
            >
              <div
                className="mm-padding mm-margin"
                style={{
                  height: "200px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {getIcon("logo")}
              </div>
            </Link>
          )}
          <Dropdown>
            <Dropdown.Toggle
              variant="transparent"
              id="dropdown-basic"
              style={{ cursor: "pointer", border: "none" }}
              title="Options"
            >
              <span>INFORMATION</span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>
                <Link to="/privacy/" style={{ textDecoration: "none" }}>
                  Privacy Policy
                </Link>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="card-body mm-padding-large">
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicEmail">
              <FloatingLabel
                controlId="email"
                label="Email Address"
                className="mb-3"
              >
                <Form.Control
                autoComplete="off"
                  type="email"
                  placeholder="name@example.com"
                  value={email}
                  onChange={handleEmailChange}
                  className="form-control form-line"
                  required
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group controlId="formBasicPassword">
              <FloatingLabel
                controlId="email"
                label="Your Password"
                className="mb-3"
              >
                <Form.Control
                  type="password"
                  autoComplete="new-password"
                  placeholder="password"
                  value={password}
                  onChange={handlePasswordChange}
                  className="form-control form-line"
                  required
                />
              </FloatingLabel>
            </Form.Group>
            <Button variant="success" type="submit" style={{ width: "100%" }}>
              Login
            </Button>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Link to="/reset/" className="btn btn-link">
                Forgot Password?
              </Link>
              <span className="btn btn-link" onClick={handleClick}>Get Help</span>
            </div>
          </Form>
          <div className="or-separator">
            <div className="line"></div>
            <span>OR</span>
            <div className="line"></div>
          </div>
          <Button variant="danger" className="mt-3" style={{ width: "100%" }}>
            Login with Google
          </Button>
          <Button variant="primary" className="mt-3" style={{ width: "100%" }}>
            Login with Facebook
          </Button>
        </div>
        <div className="card-footer  mm-light-grey">
          <br />
          <p className="mm-center">
            &copy;{new Date().getFullYear()}{" "}
            <a
              href={localData("get", "branding").website || "/"}
              style={{ textDecoration: "none", cursor: "pointer" }}
            >
              {localData("get", "branding").company || "Company"}
            </a>{" "}
            - All Rights Reserved
          </p>
        </div>
      </div>
      <Modal
        isOpen={report}
        onRequestClose={() => setReporting(false)}
        style={customStyles}
      >
        <div
          className="bg-secondary text-white"
          style={{
            maxWidth: "100%",
            width: "500px",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <h4>
            Response{" "}
            <span
              onClick={() => setReporting(false)}
              title="Close"
              className="mm-right"
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={faTimes} color="red" />
            </span>
          </h4>

          <hr />

          <p className="mm-center">{message}</p>

          <br />
          <button
            className="mm-btn mm-red"
            onClick={() => setReporting(false)}
            style={{ borderRadius: "30px", width: "100%" }}
          >
            CLOSE
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={loading}
        onRequestClose={() => setLoading(false)}
        style={customStyles}
      >
        <FontAwesomeIcon icon={faSpinner} color="#aaa" size="7x" spin />
      </Modal>
    </div>
  );
}

export default Login;
