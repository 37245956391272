function slugify(unslugified) {
    return unslugified
      .toLowerCase()
      .replace(/[^a-z0-9 -]/g, '')
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-');
  }

  function namify(unslugified) {
    let extension = unslugified.split('.').pop();
    let filename = unslugified.slice(0, unslugified.lastIndexOf('.'));
    
    let slugified = filename
    .toLowerCase()
    .replace(/[^a-z0-9]/g, '-')
    .replace(/-+/g, '-');
    
    slugified = `${slugified}.${extension}`;
    
    return slugified;
    }

  module.exports = {slugify, namify};