import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import Top from "../../includes/topping";
import { apiData, localData, cartData, runData } from "../../../jsx/backend";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  faDashboard,
  faEdit,
  faFileDownload,
  faHistory,
  faNoteSticky,
  faPlusSquare,
  faTicketAlt,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import Loader from "../../includes/loader";
import { useDropzone } from "react-dropzone";
import { Button, Card, Col, Container, ListGroup, Row } from "react-bootstrap";
import CustomerUsers from "./customer.users";
import getIcon from "../../includes/icons";
import SideBar from "../../sections/sidebar";
import ProfileSubscriptions from "./customer.users.profile.subscriptions";


const Customer = () => {
  const params = useParams();
  const [loaded, setLoaded] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [title, setTitle] = useState("");
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [currentItemReference, setCurrentItemReference] = useState(null);
  const [currentTitle, setCurrentTitle] = useState("");
  const [loading, setLoading] = useState(true);
  const [running, setRunning] = useState(false);
  const [logofy, setLogofy] = useState(false);
  const [portal, setPortal] = useState(window.location);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [report, setReporting] = useState(false);
  const [profile, setProfile] = useState({});
  const [subscriptions, setSubscriptions] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [subPolicies, setSubPolicies] = useState([]);
  const [subTemplates, setSubTemplates] = useState([]);
  const [subComposers, setSubComposers] = useState([]);
  const [subStaff, setSubStaff] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [logo, setLogo] = useState(null);
  const itemsPerPage = 10;

  const filteredSubStaff = subStaff.filter((user) =>
    `${user.data.name} ${user.data.surname}`
      .toLowerCase()
      .includes(search.toLowerCase())
  );

  const onDrop = useCallback((acceptedFiles) => {
    setSelectedFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: "image/jpeg, image/png, image/svg",
    maxSize: 1048576, // 1MB
  });

  const totalPages = Math.ceil(filteredSubStaff.length / itemsPerPage);

  const handleSubmit = async () => {
    setRunning(true);
    setLogofy(false);
    if (!selectedFile) {
      setRunning(false);
      setMessage("No file selected. Please select a file to upload.");
      setReporting(true);
      return;
    }

    if (selectedFile.size === 0) {
      setRunning(false);
      setMessage("The selected file is empty. Please select a non-empty file.");
      setReporting(true);
      return;
    }

    const data = {
      logo: selectedFile,
      profile: params.customer,
    };

    const responseData = await runData(
      data,
      `/query/customers/${params.customer}/update/logo/`
    );
    setRunning(false);

    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          setTitle("");
          setSelectedFile(null);
          setMessage("Your upload has been successful.");
          setReporting(true);
          viewCustomer();
          break;

        default:
          setMessage(responseData.response.data.message);
          setReporting(true);
      }
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setReporting(true);
    } else {
      setMessage("An error occurred while processing your request.");
      setReporting(true);
    }
  };

  const [service, setService] = useState("");
  const [addUser, setAddUser] = useState(false);

  const [stats, setStats] = useState(false);
  const [subscribing, setSubscribe] = useState(false);

  const currentDate = new Date();
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );

  const [startDate, setStartDate] = useState(firstDayOfMonth);
  const [endDate, setEndDate] = useState(new Date());

  const today = new Date();
  const nextYear = new Date();
  nextYear.setFullYear(today.getFullYear() + 1);

  const [subscriptionDate, setSubscriptionDate] = useState(today);
  const [expiryDate, setExpiryDate] = useState(nextYear);
  const [type, setType] = useState("");
  const [packages, setPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [policies, setPolicies] = useState([]);
  const [selectedPolicy, setSelectedPolicy] = useState("");

  const [user, setUser] = useState({
    name: "",
    surname: "",
    occupation: "",
    phone: "",
    email: "",
  });

  const handleChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const createUser = async (e) => {
    e.preventDefault();
    setAddUser(false);
    setRunning(true);

    const data = {
      name: user.name,
      surname: user.surname,
      email: user.email,
    };

    for (let key in data) {
      if (data[key] === "") {
        setRunning(false);
        setMessage(`Please fill in the ${key} field. It is required`);
        setReporting(true);
        return;
      }
    }

    const responseData = await runData(
      data,
      `/query/customers/${profile.reference}/users/add/`
    );

    setRunning(false);

    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          setMessage("You have successfully added a user!");
          setReporting(true);
          break;
        default:
          setMessage(responseData.response.data.message);
          setReporting(true);
      }
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setReporting(true);
    } else {
      setMessage("An error occurred while processing your request.");
      setReporting(true);
    }
  };

  const activeStyles = {
    borderColor: "#2196f3",
  };

  useEffect(() => {
    if (type === "package" || type === "policy") {
      fetchPackages();
    }
  }, [type]);

  useEffect(() => {
    if (selectedPackage && (type === "category" || type === "policy")) {
      fetchCategories();
    }
  }, [selectedPackage, type]);

  useEffect(() => {
    if (selectedCategory && type === "policy") {
      fetchPolicies();
    }
  }, [selectedCategory, type]);

  const handleTypeChange = (event) => {
    setType(event.target.value);
    setSelectedPackage("");
    setSelectedCategory("");
    setSelectedPolicy("");
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
  };

  const handlePackageChange = (event) => {
    setSelectedPackage(event.target.value);
    setService(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setService(event.target.value);
  };

  const handlePolicyChange = (event) => {
    setSelectedPolicy(event.target.value);
    setService(event.target.value);
  };
  const [data, setData] = useState([]);

  const filteredData = data.filter((item) => {
    const itemDate = new Date(item.date);
    return itemDate >= startDate && itemDate <= endDate;
  });

  const subscribe = async (event) => {
    event.preventDefault();
    setSubscribe(false);
    setRunning(true);
    const data = {
      type: type,
      service:
        type === "composer" || type === "templates"
          ? profile.reference
          : service,
      start: subscriptionDate,
      expiry: expiryDate,
    };
    for (let key in data) {
      if (data[key] === "") {
        setRunning(false);
        setMessage(`Please fill in the ${key} field. It is required`);
        setReporting(true);
        return;
      }
    }
    const responseData = await runData(
      data,
      `/query/customers/${profile.reference}/subscribe`
    );
    setRunning(false);
    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          setMessage("You have successfully added a subscription to customer!");
          viewCustomer();
          setReporting(true);
          break;

        default:
          setMessage(responseData.response.data.message);
          setReporting(true);
      }
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setReporting(true);
    } else {
      setMessage("An error occured while processing your request.");
      setReporting(true);
    }
  };
  const fetchPackages = async () => {
    const data = {};
    const responseData = await runData(data, "/query/policies/");
    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          setPackages(responseData.response.data.packages);
          break;

        default:
          setSubscribe(false);
          setMessage(responseData.response.data.message);
          setReporting(true);
      }
    } else if (responseData.error) {
      setSubscribe(false);
      setMessage(responseData.error.message);
      setReporting(true);
    } else {
      setSubscribe(false);
      setMessage("An error occured while processing your request.");
      setReporting(true);
    }
  };

  const fetchCategories = async () => {
    const data = {};
    const responseData = await runData(
      data,
      `/query/policies/${selectedPackage}/`
    );
    setLoading(false);
    setError(false);
    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          setCategories(responseData.response.data.categories);
          break;

        default:
          setSubscribe(false);
          setMessage(responseData.response.data.message);
          setReporting(true);
      }
    } else if (responseData.error) {
      setSubscribe(false);
      setMessage(responseData.error.message);
      setReporting(true);
    } else {
      setSubscribe(false);
      setMessage("An error occured while processing your request.");
      setReporting(true);
    }
  };

  const fetchPolicies = async () => {
    const data = {};
    const responseData = await runData(
      data,
      `/query/policies/${selectedPackage}/${selectedCategory}/`
    );
    setLoading(false);
    setError(false);
    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          setPolicies(responseData.response.data.policies);
          break;

        default:
          setSubscribe(false);
          setMessage(responseData.response.data.message);
          setReporting(true);
      }
    } else if (responseData.error) {
      setSubscribe(false);
      setMessage(responseData.error.message);
      setReporting(true);
    } else {
      setSubscribe(false);
      setMessage("An error occured while processing your request.");
      setReporting(true);
    }
  };

  const viewCustomer = async () => {
    const data = {};

    const responseData = await runData(
      data,
      `/query/customers/${params.customer}/`
    );
    setLoading(false);
    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          setProfile(responseData.response.data.profile);
          setSubscriptions(responseData.response.data.subscriptions);
          setSubStaff(responseData.response.data.staff);
          setPortal(responseData.response.data.portal);
          setLoaded(true);
          break;
        default:
          setMessage(responseData.response.data.message);
          setReporting(true);
      }
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setReporting(true);
    } else {
      setMessage("An error occured while processing your request.");
      setReporting(true);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (localData("get", "customer_refresh") === 1) {
        viewCustomer();
        localData("save", "customer_refresh", 0);
      }
      if (localData("get", "subscriptions_refresh") === 1) {
        viewCustomer();
        localData("save", "subscriptions_refresh", 0);
      }
    }, 1);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    viewCustomer();
  }, []);

  const customStyles = {
    overlay: {
      backgroundColor: "#ffffffeb",
      zIndex: 10000,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "transparent",
      border: "none",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minWidth: "30VW",
    },
  };

  const styles = {
    border: "dashed 3px #eeeeee",
    borderRadius: "5px",
    padding: "60px",
    textAlign: "center",
    color: "#bdbdbd",
    backgroundColor: "#fafafa",
    cursor: "pointer",
  };

  return (
    <Container style={{ minHeight: "95VH", wordWrap: "break-word" }} fluid>
      <Helmet>
        <title>
          Manage Customer - {localData("get", "branding").company || "Company"}
        </title>
      </Helmet>

      <Row className="mt-3 mm-padding-large">
        <Col md={9}>
          <Card id="profile" className="mb-4">
            <Card.Header>
              <Row>
                <Col>
                  <h6>Profile</h6>
                </Col>
                <Col className="d-flex align-items-start justify-content-end">
                  <Button
                    variant="link"
                    title="Edit Profile"
                    style={{ color: "#aaa", textDecoration: "none" }}
                  >
                    <FontAwesomeIcon icon={faEdit} /> Edit
                  </Button>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <Row className="mb-3">
                <Col md={8}>
                  <Card.Img
                    variant="top"
                    src={`${apiData.defaults.baseURL}${profile.logo}`}
                    style={{ maxHeight: "80px", width: "auto" }}
                  />
                </Col>
                <Col
                  md={4}
                  className="d-flex align-items-start justify-content-end"
                >
                  <Button
                    variant="link"
                    title="Edit Logo"
                    style={{ color: "#aaa", textDecoration: "none" }}
                    onClick={() => setLogofy(true)}
                  >
                    <FontAwesomeIcon icon={faUpload} /> Edit
                  </Button>
                </Col>
              </Row>

              <br />

              <Card.Title className="d-flex justify-content-between align-items-center">
                {profile.company}
              </Card.Title>
              <Card.Text className="d-flex justify-content-between align-items-center">
                Address: {profile.address}
              </Card.Text>
              <Card.Text className="d-flex justify-content-between align-items-center">
                Phone: {profile.phone}
              </Card.Text>
              <Card.Text className="d-flex justify-content-between align-items-center">
                Email: {profile.email}
              </Card.Text>
            </Card.Body>
          </Card>
          <Card id="users" className="mb-4">
            <Card.Header>
              <Row className="d-flex align-items-center justify-content-between">
                <Col md={6}>
                  <h6>Local Authority</h6>
                </Col>
                <Col
                  md={6}
                  className="d-flex align-items-start justify-content-end"
                >
                  <Button
                    variant="link"
                    title="Edit Local Authority"
                    style={{ color: "#aaa", textDecoration: "none" }}
                  >
                    <FontAwesomeIcon icon={faEdit} /> Edit
                  </Button>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body></Card.Body>
          </Card>

          <CustomerUsers users={subStaff} company={profile.company} portal={portal}  />
          <ProfileSubscriptions subscriptions={subscriptions} profile={profile} portal={portal}  />
          
        </Col>
        <SideBar />
      </Row>

      <Modal
        isOpen={subscribing}
        onRequestClose={() => setSubscribe(false)}
        style={customStyles}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <form
            onSubmit={subscribe}
            className="mm-padding"
            style={{ marginBottom: "30px" }}
          >
            <h6 className="mm-center" style={{ marginBottom: "30px" }}>
              Add Subscription
            </h6>
            <div className="mm-container" style={{ marginBottom: "30px" }}>
              <label className="mm-label-create">
                What service do you want to add to {profile.company}?
              </label>
              <select
                className="mm-input-create"
                value={type}
                onChange={handleTypeChange}
              >
                <option value="">Select type</option>
                <option value="package">Package</option>
                <option value="category">Category</option>
                <option value="policy">Policy</option>
                <option value="composer">Composer</option>
                <option value="templates">Templates</option>
                <option value="projects">Projects</option>
              </select>
            </div>
            {(type === "package" ||
              type === "policy" ||
              type === "category") && (
              <div className="mm-container" style={{ marginBottom: "30px" }}>
                <label className="mm-label-create">
                  What package do you want to use?
                </label>
                <select
                  className="mm-input-create"
                  value={selectedPackage}
                  onChange={handlePackageChange}
                >
                  <option value="">Select package</option>
                  {packages.map((pkg) => (
                    <option key={pkg.reference} value={pkg.reference}>
                      {pkg.title}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {(type === "policy" || type === "category") && selectedPackage && (
              <div className="mm-container" style={{ marginBottom: "30px" }}>
                <label className="mm-label-create">
                  What category from this package do you want to use?
                </label>
                <select
                  className="mm-input-create"
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                >
                  <option value="">Select category</option>
                  {categories.map((category) => (
                    <option key={category.reference} value={category.reference}>
                      {category.title}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {type === "policy" && selectedCategory && (
              <div className="mm-container" style={{ marginBottom: "30px" }}>
                <label className="mm-label-create">
                  Select policy from this package and the selected category?
                </label>
                <select
                  className="mm-input-create"
                  value={selectedPolicy}
                  onChange={handlePolicyChange}
                >
                  <option value="">Select policy</option>
                  {policies.map((policy) => (
                    <option key={policy.reference} value={policy.reference}>
                      {policy.title}
                    </option>
                  ))}
                </select>
              </div>
            )}

            <div className="mm-container">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <div style={{ position: "relative", width: "45%" }}>
                  <DatePicker
                    selected={subscriptionDate}
                    onChange={(date) => setSubscriptionDate(date)}
                    className="date-picker"
                  />
                  <label for="startDate" className="date-label">
                    Start Date
                  </label>
                </div>
                <div style={{ position: "relative", width: "45%" }}>
                  <DatePicker
                    selected={expiryDate}
                    onChange={(date) => setExpiryDate(date)}
                    className="date-picker"
                  />
                  <label for="endDate" className="date-label">
                    End Date
                  </label>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <button
                onClick={() => setSubscribe(false)}
                className="mm-left"
                type="button"
                style={{
                  backgroundColor:
                    localData("get", "branding").shade || "#eeeeee",
                  width: "48%",
                  color: "#fff",
                  padding: "10px",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                  transition: "background-color 0.3s ease",
                  fontSize: "16px",
                }}
              >
                CANCEL
              </button>
              <button
                className="mm-right"
                type="submit"
                style={{
                  backgroundColor:
                    localData("get", "branding").color || "#eeeeee",
                  width: "48%",
                  color: "#fff",
                  padding: "10px",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                  transition: "background-color 0.3s ease",
                  fontSize: "16px",
                }}
              >
                SUBSCRIBE
              </button>
            </div>
          </form>
        </div>
      </Modal>

      <Modal
        isOpen={addUser}
        onRequestClose={() => setAddUser(false)}
        style={customStyles}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
            maxWidth: "500px",
          }}
        >
          <form
            onSubmit={createUser}
            className="mm-padding"
            style={{ marginBottom: "30px" }}
          >
            <h6 className="mm-center" style={{ marginBottom: "30px" }}>
              Add User to {profile.company}
            </h6>
            <label>First Name</label>
            <input
              className="marmasco-input"
              style={{
                width: "100%",
                marginBottom: "10px",
                padding: "10px",
                borderRadius: "5px",
              }}
              type="text"
              name="name"
              value={user.name}
              onChange={handleChange}
              placeholder="Name"
            />
            <label>Last Name</label>
            <input
              className="marmasco-input"
              style={{
                width: "100%",
                marginBottom: "10px",
                padding: "10px",
                borderRadius: "5px",
              }}
              type="text"
              name="surname"
              value={user.surname}
              onChange={handleChange}
              placeholder="Surname"
            />
            <label>Email</label>
            <input
              className="marmasco-input"
              style={{
                width: "100%",
                marginBottom: "10px",
                padding: "10px",
                borderRadius: "5px",
              }}
              type="email"
              name="email"
              value={user.email}
              onChange={handleChange}
              placeholder="Email"
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <button
                onClick={() => setAddUser(false)}
                className="mm-left"
                type="button"
                style={{
                  backgroundColor:
                    localData("get", "branding").shade || "#eeeeee",
                  width: "48%",
                  color: "#fff",
                  padding: "10px",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                  transition: "background-color 0.3s ease",
                  fontSize: "16px",
                }}
              >
                CANCEL
              </button>
              <button
                className="mm-right"
                type="submit"
                style={{
                  backgroundColor:
                    localData("get", "branding").color || "#eeeeee",
                  width: "48%",
                  color: "#fff",
                  padding: "10px",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                  transition: "background-color 0.3s ease",
                  fontSize: "16px",
                }}
              >
                Add User
              </button>
            </div>
          </form>
        </div>
      </Modal>

      <Modal
        isOpen={stats}
        onRequestClose={() => setStats(false)}
        style={customStyles}
      >
        <div
          style={{
            width: "75VW",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <div className="mm-white mm-padding" style={{ marginBottom: "30px" }}>
            <div className="mm-container">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <div style={{ position: "relative", width: "45%" }}>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    className="date-picker"
                  />
                  <label for="startDate" className="date-label">
                    Start Date
                  </label>
                </div>
                <div style={{ position: "relative", width: "45%" }}>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    className="date-picker"
                  />
                  <label for="endDate" className="date-label">
                    End Date
                  </label>
                </div>
              </div>
              <ResponsiveContainer width="100%" height={400}>
                <LineChart data={filteredData}>
                  <CartesianGrid strokeDasharray="1 1" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="Users"
                    stroke="#8884d8"
                    activeDot={{ r: 8 }}
                  />
                  <Line type="monotone" dataKey="Errors" stroke="#82ca9d" />
                  <Line type="monotone" dataKey="Clones" stroke="#ffc658" />
                  <Line type="monotone" dataKey="Downloads" stroke="#ff9933" />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>

          <button
            onClick={() => setStats(false)}
            className="mm-small my-color zoom-on-hover mm-text-white"
            style={{
              padding: "10px 20px",
              borderRadius: "5px",
              border: "none",
              cursor: "pointer",
              width: "100%",
            }}
          >
            CLOSE STATISTICS
          </button>
        </div>
      </Modal>

      <Modal isOpen={running} style={customStyles}>
        <div
          className="mm-center"
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <h6 style={{ color: "#343a40" }}>Please wait...</h6>

          <Loader />
        </div>
      </Modal>

      <Modal
        isOpen={report}
        onRequestClose={() => setReporting(false)}
        style={customStyles}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <h6>
            Message{" "}
            <span
              onClick={() => setReporting(false)}
              title="Close"
              className="mm-right"
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={faTimes} color="red" />
            </span>
          </h6>

          <hr />

          <p className="mm-center">{message}</p>

          <button
            className="mm-btn mm-red"
            onClick={() => setReporting(false)}
            style={{ borderRadius: "30px", width: "100%" }}
          >
            CLOSE
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={logofy}
        onRequestClose={() => setLogofy(false)}
        style={customStyles}
      >
        <Container className="mt-5 mm-white">
          <div
            className="mm-container"
            style={{
              width: "100%",
              backgroundColor: "#f8f9fa",
              padding: "20px",
              borderRadius: "10px",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
            }}
          >
            <h4>
              UPDATE LOGO{" "}
              <span
                onClick={() => setLogofy(false)}
                className="mm-right mm-text-red"
                style={{ cursor: "pointer" }}
                title="Close"
              >
                <FontAwesomeIcon icon={faTimesCircle} />{" "}
              </span>
            </h4>
            <br />
            <div
              {...getRootProps()}
              style={isDragActive ? { ...styles, ...activeStyles } : styles}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>Drop the file here ...</p>
              ) : (
                <p>Drag 'n' drop a logo here, or click to select a file</p>
              )}
            </div>
            {selectedFile && (
              <div className="file-preview">
                <div className="file-icon">
                  <div
                    style={{
                      height: "50px",
                      width: "50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {getIcon(selectedFile.type)}
                  </div>
                </div>
                <div className="file-info">
                  <p>{selectedFile.name}</p>
                </div>
                <button
                  onClick={handleRemoveFile}
                  className="remove-file-button"
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </button>
              </div>
            )}
            <br />
            <div
              style={{
                display: "flex",
                border: "1px solid #ccc",
                borderRadius: "4px",
                overflow: "hidden",
              }}
            >
              <button
                style={{
                  background: localData("get", "branding").color || "#8b7f83",
                  cursor: "pointer",
                  color: "#fff",
                  border: "none",
                  padding: "10px 20px",
                  width: "100%",
                }}
                onClick={handleSubmit}
              >
                UPLOAD
              </button>
            </div>
          </div>
        </Container>
      </Modal>

      <Top />
    </Container>
  );
};

export default Customer;
