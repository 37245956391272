import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { localData } from "../../../../jsx/backend";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import bgImg from "../../../../img/background.jpg";

function Cloark() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [message, setMessage] = useState("");
  const [report, setReporting] = useState(false);

  useEffect(() => {
    const data = {
      choice: "accepted",
      code: 1,
    };
    localData("save", "cookies", data);

    const fetchData = async () => {
      let missingParams = [];

      if (!queryParams.get("role")) {
        missingParams.push("role");
      }

      if (!queryParams.get("name")) {
        missingParams.push("name");
      }

      if (!queryParams.get("email")) {
        missingParams.push("email");
      }

      if (!queryParams.get("phone")) {
        missingParams.push("phone");
      }

      if (!queryParams.get("session")) {
        missingParams.push("session");
      }

      if (missingParams.length > 0) {
        console.log(queryParams);
        setMessage(
          `The following user credentials must be set: ${missingParams.join(
            ", "
          )}.`
        );
        setReporting(true);
        return;
      }
      const sessionFull = {
        name: queryParams.get("name"),
        role: parseInt(queryParams.get("role")),
        token: queryParams.get("session"),
      };

      const time = new Date().getTime();
      localData("save", "session", sessionFull);
      localData("save", "expiry", time);
      setMessage("You have been logged in successfully!");
      setReporting(true);
      localData("save", "messages", {
        user: {
          name: queryParams.get("name"),
          email: queryParams.get("email"),
          phone: queryParams.get("phone"),
        },
        thread: [
          {
            sender: "bot",
            text: "Hi there! I can assist you with our service information.",
            date: new Date(),
          },
        ],
      });
    };

    fetchData();
  }, []);

  return (
    <div
      className="home"
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        wordWrap: "break-word",
        backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 0.77) 100%, rgb(255, 255, 255) 100%), url(${bgImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Helmet>
        <title>
          Cloarking - {localData("get", "branding").company || "Company"}
        </title>
      </Helmet>
      {!report ? (
        <div className="mm-animate-zoom mm-center">
          <h4>Cloarking</h4>
          <br />
          <FontAwesomeIcon icon={faSpinner} color="#aaa" size="7x" spin />
          <br />
        </div>
      ) : (
        <div className="mm-animate-zoom mm-center">
          <h4>Cloarking Error!</h4>
          <br />
          <p className="mm-text-red">{message}</p>
          <br />
        </div>
      )}
    </div>
  );
}

export default Cloark;
