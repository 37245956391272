import React, { useState } from "react";
import {
  Dropdown,
  Button,
  Form,
  FloatingLabel,
  Row,
  Col,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faTimes } from "@fortawesome/free-solid-svg-icons";
import { runData } from "../../../jsx/backend";
import Modal from "react-modal";
import Loader from "../../includes/loader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

Modal.setAppElement('#root'); 

const RenderAssignOption = ({ user }) => {
  const [running, setRunning] = useState(false);
  const [message, setMessage] = useState("");
  const [report, setReporting] = useState(false);
  const [modifying, setAssigning] = useState(false);
  const [staff, setStaff] = useState({
    date: new Date(),
    task: "",
    priority: "",
    deadline: new Date(),
  });

  const handleChange = (e) => {
    setStaff({
      ...staff,
      [e.target.name]: e.target.value,
    });
  };

  const assignUser = async (e) => {
    e.preventDefault();
    setAssigning(false);
    setRunning(true);

    const data = {
      reference: user.reference,
      date: moment(staff.date).format("YYYY-MM-DD"),
      task: staff.task,
      priority: staff.priority,
      deadline: moment(staff.deadline).format("YYYY-MM-DD"),
    };

    for (let key in data) {
      if (data[key] === "") {
        setRunning(false);
        setMessage(`Please fill in the ${key} field. It is required`);
        setReporting(true);
        return;
      }
    }
    const responseData = await runData(data, `/query/staff/assign/`);

    setRunning(false);

    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          setMessage(
            "You have successfully assigned a task to this staff account!"
          );
          setReporting(true);
          setStaff({
            ...staff,
            task: "",
            priority: "",
            date: new Date(),
            deadline: new Date(),
          });
          break;
        default:
          setMessage(responseData.response.data.message);
          setReporting(true);
      }
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setReporting(true);
    } else {
      setMessage("An error occurred while processing the request.");
      setReporting(true);
    }
  };

  const customStyles = {
    overlay: {
      backgroundColor: "#ffffffeb",
      zIndex: 10000,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "transparent",
      border: "none",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minWidth: "30VW",
    },
  };

  return (
    <>
      <Dropdown.Item onClick={() => setAssigning(true)}>
        <div className="d-flex justify-content-between">
          Assign
          <FontAwesomeIcon icon={faArrowRight} />
        </div>
      </Dropdown.Item>

      <Modal
        isOpen={modifying}
        onRequestClose={() => setAssigning(false)}
        style={customStyles}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
            maxWidth: "500px",
          }}
        >
          <h4>
            Assign Task To {user.name}{" "}
            <span
              onClick={() => setAssigning(false)}
              title="Close"
              className="mm-right"
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={faTimes} color="red" />
            </span>
          </h4>
          <hr />
          <Form onSubmit={assignUser}>
            <FloatingLabel
              controlId="floatingTextarea"
              label="Task"
              className="mb-3"
            >
              <Form.Control
                style={{
                  boxShadow: "none",
                  border: "solid #aaa 1px",
                }}
                as="textarea"
                name="task"
                placeholder="Enter task..."
                value={staff.task}
                onChange={handleChange}
              />
            </FloatingLabel>
            <FloatingLabel
              controlId="floatingSelect"
              label="Priority"
              className="mb-3"
            >
              <Form.Select
                value={staff.priority}
                onChange={handleChange}
                name="priority"
                style={{
                  boxShadow: "none",
                  border: "solid #aaa 1px",
                }}
              >
                <option value="">Select Priority</option>
                <option value="High">High</option>
                <option value="medium">Medium</option>
                <option value="low">Low</option>
              </Form.Select>
            </FloatingLabel>
            <br />
            <Row>
              <Col>
                <div style={{ position: "relative", width: "100%" }}>
                  <DatePicker
                    selected={staff.date}
                    onChange={(value) => setStaff({ ...staff, date: value })}
                    className="date-picker"
                    minDate={new Date()}
                    style={{
                      boxShadow: "none",
                      border: "solid #aaa 1px",
                    }}
                  />
                  <label htmlFor="deadline" className="date-label">
                    Starting
                  </label>
                </div>
              </Col>
              <Col>
                <div style={{ position: "relative", width: "100%" }}>
                  <DatePicker
                    selected={staff.deadline}
                    onChange={(value) =>
                      setStaff({ ...staff, deadline: value })
                    }
                    className="date-picker"
                    minDate={new Date()}
                    style={{
                      boxShadow: "none",
                      border: "solid #aaa 1px",
                    }}
                  />
                  <label htmlFor="deadline" className="date-label">
                    Deadline
                  </label>
                </div>
              </Col>
              <Button
                variant="light"
                type="submit"
                className="mm-color no-outline"
                style={{ width: "100%", height: "45px" }}
              >
                Add Task
              </Button>
            </Row>
          </Form>
        </div>
      </Modal>

      <Modal isOpen={running} style={customStyles}>
        <div
          className="mm-center"
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <h6 style={{ color: "#343a40" }}>Please wait...</h6>
          <br />
          <Loader />
        </div>
      </Modal>

      <Modal
        isOpen={report}
        onRequestClose={() => setReporting(false)}
        style={customStyles}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <h6>
            Message{" "}
            <span
              onClick={() => setReporting(false)}
              title="Close"
              className="mm-right"
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={faTimes} color="red" />
            </span>
          </h6>

          <hr />

          <p className="mm-center">{message}</p>

          <br />
          <button
            className="mm-btn mm-red"
            onClick={() => setReporting(false)}
            style={{ borderRadius: "30px", width: "100%" }}
          >
            CLOSE
          </button>
        </div>
      </Modal>
    </>
  );
};

export default RenderAssignOption;
