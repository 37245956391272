import React, { useState, useEffect } from "react";
import { localData, runData } from "../../jsx/backend";
import { Helmet } from "react-helmet";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Top from "../includes/topping";

function Expiry() {
  const [countdownTime, setCountdownTime] = useState(60);

  useEffect(() => {
    const handleLogout = async () => {
      const data = {};

      await runData(data, "/query/logout/");
    };
    const countdown = setInterval(() => {
      setCountdownTime((prevTime) => {
        if (prevTime > 0) {
          return prevTime - 1;
        } else {
          localData("remove", "session");
          handleLogout();
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(countdown);
  }, []);

  return (
    <div
      style={{ minHeight: "100VH", paddingTop: "40px", wordWrap: "break-word" }}
    >
      <Helmet>
        <title>Inactive Session</title>
      </Helmet>
      <div
        className="mm-container mm-center"
        style={{ marginTop: "20VH", paddingBottom: "5VH" }}
      >
        <div className="modules">
          <div title="Loading">
            <div className="mm-btn module zoom-on-hover">
              <div className="icon-container">
                <FontAwesomeIcon icon={faClock} size="3x" color="orange" />
              </div>
              <div className="title-container">
                <p style={{ color: "black" }}>LOGGING OUT IN</p>
                <h1>{countdownTime}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Top />
    </div>
  );
}

export default Expiry;
