import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { localData } from "../../../../jsx/backend";
import { useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import bgImg from "../../../../img/background.jpg";

function Auto() {
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const login = async () => {
      let name = params.name.replace(/-/g, " ");
      name = name
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
      const sessionFull = {
        name: name,
        role: params.role,
        token: params.session,
      };
      console.log(sessionFull);
      const time = new Date().getTime();
      localData("save", "session", sessionFull);
      localData("save", "expiry", time);

      navigate("/");
    };
    login();
  }, [params, navigate]);

  return (
<div className='home' style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', wordWrap : "break-word",  
              backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 0.77) 100%, rgb(255, 255, 255) 100%), url(${bgImg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",}}>
      <Helmet>
        <title>
          Auto Login - {localData("get", "branding").company || "Company"}
        </title>
      </Helmet>
      <div className="mm-animate-zoom">
        <br />
        <FontAwesomeIcon icon={faSpinner} color="#aaa" size="7x" spin />
        <br />
      </div>
    </div>
  );
}

export default Auto;
