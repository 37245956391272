import React, { useEffect, useState, useRef } from "react";
import Draggable from "react-draggable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComments,
  faPaperPlane,
  faRobot,
  faUserCog,
  faWindowMinimize,
} from "@fortawesome/free-solid-svg-icons";
import { Badge, Dropdown, FloatingLabel, Form } from "react-bootstrap";
import { Dot } from "react-animated-dots";
import { useLocation } from "react-router-dom";
import { marked } from "marked";
import { unescape } from "validator";
import parsePhoneNumber from "libphonenumber-js/max";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import validator from "validator";
import { useMessageCount } from "../../jsx/hooks/messages";
import { localData, runData } from "../../jsx/backend";

marked.setOptions({
  gfm: true,
  tables: true,
});

const Chat = () => {
  const [showChat, setShowChat] = useState(localData("get", "chatting"));
  const messageCount = useMessageCount();
  const [isBotTyping, setIsBotTyping] = useState(false);
  const [inputMessage, setInputMessage] = useState("");
  const chatBoxRef = useRef(null);
  const [message, setMessage] = useState("");
  const location = useLocation();

  let currentPage = location.pathname;

  let history = localData("get", "messages") || [];
  const [onChat, setOnChat] = useState(history.user || false);
  const [messages, setMessages] = useState(
    history.thread || [
      {
        sender: "bot",
        text: "Hi there! I can assist you with our service information.",
        date: new Date(),
      },
    ]
  );
  const [user, setUser] = useState({
    name: history && history.user ? history.user.name : "",
    email: history && history.user ? history.user.email : "",
    phone: history && history.user ? history.user.phone : "",
  });

  const handleInputChange = (event) => {
    setInputMessage(event.target.value);
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    let doing = true;
    const newMessage = {
      sender: "user",
      text: inputMessage,
      date: new Date(),
    };

    const reversedMessages = [...messages].reverse();

    const skippedLastMessage = reversedMessages.slice(1);

    const lastTenMessages = skippedLastMessage.slice(0, 10);

    const orderedLastTenMessages = lastTenMessages.reverse();

    const thisHistory = JSON.stringify(orderedLastTenMessages);

    const data = {
      name: history.user.name,
      account: history.user.phone,
      email: history.user.email,
      history: thisHistory,
      question: inputMessage,
      page: currentPage,
    };
    setInputMessage("");
    setIsBotTyping(true);
    const responseData = await runData(data, "/query/chat/");
    setIsBotTyping(false);

    let botMessage = {
      sender: "bot",
      text: "There was an error while processing your request, please try again.",
      date: new Date(),
    };

    if (responseData.response) {
      botMessage.text = responseData.response.data.message;
    } else if (responseData.error) {
      botMessage.text = responseData.error.message;
    }

    setMessages((prevMessages) => {
      const updatedMessages = [...prevMessages, newMessage, botMessage];

      let unread = localData("get", "unread");

      if (unread === null) {
        unread = 0;
      }
      if (botMessage.sender === "bot" && doing) {
        unread++;
        doing = false;
      }
      localData("save", "unread", unread);
      localData("save", "messages", {
        user: history.user,
        thread: updatedMessages,
      });
      return updatedMessages;
    });
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (history.user) {
        setOnChat(true);
      } else {
        setOnChat(false);
      }
    }, 1);

    return () => clearInterval(intervalId);
  }, [history.user]);

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    const chatStatus = localData("get", "chatting");
    setShowChat(chatStatus);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const chatStatus = localData("get", "chatting");
      setShowChat(chatStatus);
    }, 1);

    return () => clearInterval(interval);
  }, []);

  const handleClick = () => {
    localData("save", "chatting", !showChat);
    setShowChat(!showChat);
  };

  const handleRead = () => {
    localData("save", "unread", 0);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (
      user.name.trim() !== "" &&
      user.email.trim() !== "" &&
      user.phone.trim() !== ""
    ) {
      const phoneNumber = parsePhoneNumber(user.phone);
      if (phoneNumber) {
        if (phoneNumber.isValid() && phoneNumber.getType() === "MOBILE") {
          if (!validator.isEmail(user.email)) {
            setMessage("Invalid email address.");
          } else {
            if (!validator.matches(user.name, /^[a-zA-Z ]+$/)) {
              setMessage(
                "Invalid name. The name should contain only letters and spaces."
              );
            } else {
              localData("save", "messages", {
                user: {
                  name: user.name,
                  email: user.email,
                  phone: user.phone,
                },
                thread: [
                  {
                    sender: "bot",
                    text: "Hi there! I can assist you with our service information.",
                    date: new Date(),
                  },
                ],
              });

              setOnChat(true);
            }
          }
        } else {
          setMessage(
            "The phone number is not valid or is not a mobile number."
          );
        }
      } else {
        setMessage("The phone number is not valid.");
      }
    } else {
      setMessage(
        "You must enter a valid email, phone number and your name to initiate a chat!"
      );
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage(event);
    }
  };

  messages.sort((a, b) => a.date - b.date);

  return showChat ? (
    <Draggable bounds="parent">
      <div
        style={{
          position: "fixed",
          bottom: "5px",
          right: "5px",
          padding: "20px",
          zIndex: "9",
        }}
      >
        {onChat ? (
          <div className="card chat-box" onClick={handleRead}>
            <Dropdown
              className="card-header d-inline d-flex justify-content-between align-items-center mm-padding"
              style={{
                cursor: "move",
                background: localData("get", "branding").shade || "#8b7f83",
                position: "sticky",
                top: 0,
                zIndex: 1000,
              }}
            >
              <h5 className="mb-0 d-flex align-items-center">
                <FontAwesomeIcon icon={faRobot} /> Support Chat{" "}
                {isBotTyping && (
                  <span>
                    {" "}
                    <Dot>.</Dot>
                    <Dot>.</Dot>
                    <Dot>.</Dot>
                  </span>
                )}
              </h5>
              <Dropdown.Toggle
                variant="transparent"
                className="no-outline btn bg-secondary"
                id="dropdown-basic"
                style={{ cursor: "pointer" }}
                title="Options"
              >
                <FontAwesomeIcon icon={faUserCog} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item variant="transparent">New Chat</Dropdown.Item>
                <Dropdown.Item variant="transparent">Email Chat</Dropdown.Item>
                <Dropdown.Item variant="transparent">End Chat</Dropdown.Item>
                <Dropdown.Item onClick={handleClick} variant="transparent">
                  Close Chat
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <div
              className="card-body chat-messages mm-padding"
              ref={chatBoxRef}
            >
              {messages.map((message, index) => (
                <div key={index} className={`message ${message.sender}`}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: marked.parse(unescape(message.text)),
                    }}
                  ></div>
                </div>
              ))}
            </div>
            <div className="card-footer mm-white">
              <br />
              <form
                onSubmit={(event) => event.preventDefault()}
                className="input-group"
              >
                <textarea
                  className="form-control no-outline-bot no-resize"
                  placeholder="Type your message here..."
                  value={inputMessage}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                />
                <div className="input-group-append">
                  <button
                    className="btn mm-color"
                    type="button"
                    onClick={handleSendMessage}
                  >
                    <FontAwesomeIcon icon={faPaperPlane} />
                  </button>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <form onSubmit={handleFormSubmit}>
            <div className="card chat-box">
              <div
                className="card-header mm-color d-flex justify-content-between align-items-center border-0"
                style={{
                  cursor: "move",
                  background: localData("get", "branding").shade || "#8b7f83",
                }}
              >
                <h5 className="mb-0">
                  <FontAwesomeIcon icon={faRobot} /> Support Chat
                </h5>
                <button
                  className="btn btn-transparent"
                  onClick={handleClick}
                  style={{ cursor: "pointer" }}
                  title="Minimise"
                >
                  <FontAwesomeIcon icon={faWindowMinimize} />
                </button>
              </div>
              <div className="card-body border-0" style={{ borderRadius: 0 }}>
                <div className="form-group">
                  <FloatingLabel
                    controlId="name"
                    label="Your Name"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Masimba Maregedze"
                      value={user.name}
                      onChange={(e) =>
                        setUser({ ...user, name: e.target.value })
                      }
                      className="form-control form-line"
                      required
                    />
                  </FloatingLabel>
                </div>
                <div className="form-group">
                  <FloatingLabel
                    controlId="email"
                    label="Email Address"
                    className="mb-3"
                  >
                    <Form.Control
                      type="email"
                      placeholder="name@example.com"
                      value={user.email}
                      onChange={(e) =>
                        setUser({ ...user, email: e.target.value })
                      }
                      className="form-control form-line"
                      required
                    />
                  </FloatingLabel>
                </div>
                <div
                  className="form-group"
                  style={{
                    border: "solid 1px #ddd",
                    padding: "5px",
                    borderRadius: "5px",
                  }}
                >
                  <label htmlFor="phone">Phone Number</label>
                  <PhoneInput
                    type="tel"
                    className="form-control no-outline"
                    id="phone"
                    defaultCountry="GB"
                    placeholder="Enter your phone number"
                    value={user.phone}
                    onChange={(value) => setUser({ ...user, phone: value })}
                    required
                  />
                </div>
                <br />
                {message ? (
                  <p className="mm-text-red">
                    {message}
                    <hr />
                    We need valid details on our website in order to protect our
                    systems from abuse.
                  </p>
                ) : (
                  <p className="mm-tiny">
                    By proceeding with this chat, you hereby provide your
                    explicit consent to us for the collection, storage, and
                    utilization of your personal data. This data will be used
                    for the purpose of service delivery and marketing
                    communications tailored to your interests. For a
                    comprehensive understanding of how we handle your personal
                    data, we encourage you to review our Privacy Policy. Your
                    continued engagement with this chat signifies your
                    acceptance of these terms.
                  </p>
                )}
              </div>
              <button
                type="submit"
                className="card-footer btn btn-outline-light border-0"
                style={{
                  border: "none",
                  background: localData("get", "branding").shade || "#8b7f83",
                }}
              >
                Start Chat
              </button>
            </div>
          </form>
        )}
      </div>
    </Draggable>
  ) : (
    <div
      className="zoom-on-hover"
      style={{
        position: "fixed",
        bottom: "30px",
        right: "30px",
        padding: "2px",
        cursor: "pointer",
      }}
      onClick={handleClick}
    >
      <FontAwesomeIcon
        icon={faComments}
        size="3x"
        color={localData("get", "branding").color || "#198754f7"}
        beat
      />
      <Badge
        pill
        variant="danger"
        bg="danger"
        className="position-absolute top-0 start-100 translate-middle p-2"
        style={{ transform: "translate(-50%, -50%)" }}
      >
        {messageCount}
      </Badge>
    </div>
  );
};

export default Chat;
