import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { localData } from '../../../../jsx/backend';

const CookieBanner = () => {
  const [isVisible, setIsVisible] = useState(true);

  const handleAccept = () => {
    const data = {
      'choice': 'accepted',
      'code': 1
    }
    localData('save', 'cookies', data);
    setIsVisible(false);
  };

  const handleDecline = () => {
    const data = {
      'choice': 'rejected',
      'code': 0
    }
    localData('save', 'cookies', data);
    setIsVisible(false);
  };

  return isVisible && (
<div className='mm-animate-bottom' style={{ 
    position: 'fixed', 
    bottom: 0, 
    width: '100%', 
    backgroundColor: '#1a237e', 
    padding: '20px', 
    textAlign: 'center',
    color: '#ecf0f1',
    fontFamily: 'Arial, sans-serif',
    boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.2)'
}}>
    <p style={{ 
        fontSize: '16px', 
        margin: '0 0 10px 0'
    }}>
        We use cookies to ensure you get the best experience on our website. Learn more from our <Link to='/privacy-policy/'>Privacy Policy</Link> statement.
    </p>
    <button onClick={handleAccept} style={{ 
        marginRight: '10px', 
        padding: '10px 20px', 
        fontSize: '16px', 
        backgroundColor: '#27ae60', 
        color: '#ecf0f1', 
        border: 'none', 
        borderRadius: '5px', 
        cursor: 'pointer'
    }}>
        Accept
    </button>
    <button onClick={handleDecline} style={{ 
        padding: '10px 20px', 
        fontSize: '16px', 
        backgroundColor: '#c0392b', 
        color: '#ecf0f1', 
        border: 'none', 
        borderRadius: '5px', 
        cursor: 'pointer'
    }}>
        Decline
    </button>
</div>
  );
};

export default CookieBanner;