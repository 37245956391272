import { useEffect, useState } from "react";
import { localData } from "../backend";

const useMessageCount = () => {
  const [messageCount, setMessageCount] = useState(
    localData("get", "unread") || 0
  );

  useEffect(() => {
    const updateMessageCount = () => {
      const count = localData("get", "unread");
      const newCount = count ? Number(count) : 0;

      setMessageCount(newCount);
    };

    updateMessageCount();

    const intervalId = setInterval(updateMessageCount, 1);

    window.addEventListener("storage", updateMessageCount);

    return () => {
      clearInterval(intervalId);
      window.removeEventListener("storage", updateMessageCount);
    };
  }, [messageCount]);

  return messageCount;
};

export { useMessageCount };
