import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import moment from "moment";
import Top from "../../includes/topping";
import { Link, useParams } from "react-router-dom";
import { localData, runData } from "../../../jsx/backend";
import Loader from "../../includes/loader";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faAngleRight,
  faFilePdf,
  faEye,
  faPencilAlt,
  faFileAlt,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import {
  Card,
  Col,
  Container,
  Dropdown,
  Form,
  Pagination,
  Row,
  Table,
  Button,
} from "react-bootstrap";
import getIcon from "../../includes/icons";
import { saveAs } from "file-saver";
import { slugify } from "../../includes/cleaning";
import SideBar from "../../sections/sidebar";

Modal.setAppElement("#root");

const CategoryCustomer = () => {
  const [policies, setPackages] = useState([]);
  const params = useParams();

  const [running, setRunning] = useState(false);
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");
  const [folder, setFolder] = useState("");
  const [report, setReporting] = useState(false);

  const [downloadRef, setDownloadRef] = useState("");
  const [downloadName, setDownloadName] = useState("Attachment");
  const [triggerDownload, setTriggerDownload] = useState(false);

  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const filteredSubPolicies = policies.filter((policy) =>
    policy.title.toLowerCase().includes(search.toLowerCase())
  );

  const totalPages = Math.ceil(filteredSubPolicies.length / itemsPerPage);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const listPackages = async () => {
    const data = {};
    const responseData = await runData(
      data,
      `/query/customer/policies/${params.package}/categories/${params.category}/`
    );
    setReporting(false);
    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          setPackages(responseData.response.data.policies);
          setFolder(responseData.response.data.folder);
          setTitle(responseData.response.data.title);
          break;

        default:
          setMessage(responseData.response.data.message);
          setReporting(true);
      }
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setReporting(true);
    } else {
      setMessage("An error occured while processing your request.");
      setReporting(true);
    }
  };

  useEffect(() => {
    listPackages();
  }, []);

  const handleDownload = (ref) => {
    setDownloadRef(ref);
    setTriggerDownload(true);
  };

  useEffect(() => {
    if (triggerDownload) {
      downloadPDF();
      setTriggerDownload(false);
    }
  }, [triggerDownload, downloadRef]);

  const downloadPDF = async () => {
    setRunning(true);
    const profile = localData("get", "profile").value;
    let data = {};

    if (profile) {
      data = { profile: profile };
    }
    const responseData = await runData(
      data,
      `/query/customer/policies/${params.package}/categories/${params.category}/${downloadRef}/pdf/`,
      "blob"
    );

    setRunning(false);
    if (responseData.response && responseData.response.status === 200) {
      setMessage("Your download has been successful!");
      const filename = slugify(downloadName);
      const blob = new Blob([responseData.response.data], {
        type: responseData.response.headers["content-type"],
      });
      saveAs(blob, filename || "download");
      setReporting(true);
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setReporting(true);
    } else {
      setMessage("An error occured while processing your request.");
      setReporting(true);
    }
  };

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      zIndex: 10000,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "transparent",
      border: "none",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minWidth: "30VW",
    },
  };

  return (
    <Container style={{ minHeight: "95VH", wordWrap: "break-word" }} fluid>
      <Helmet>
        <title>
          {title} :: {folder} -{" "}
          {localData("get", "branding").company || "Portal"}
        </title>
      </Helmet>

      <Row className="mt-3 mm-padding-large">
        <Col md={9}>
          <Card id="policies" className="mb-4">
            <Card.Header>
              <Row className="d-flex align-items-center justify-content-between">
                <Col md={6}>
                  <h6>
                    You're in: {title} <FontAwesomeIcon icon={faAngleRight} />{" "}
                    {folder}
                  </h6>
                </Col>
                <Col
                  md={6}
                  className="d-flex align-items-start justify-content-end"
                ></Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <Form.Control
                type="text"
                className="no-outline mm-pale-green"
                style={{ borderRadius: "0px" }}
                placeholder={`Type to filter from ${filteredSubPolicies.length} items...`}
                value={search}
                onChange={handleSearchChange}
              />

              <Table className="no-border" striped borderless responsive>
                <thead>
                  <tr>
                    <th>Policy</th>
                    <th>File</th>
                    <th className="mm-center">Action</th>
                  </tr>
                </thead>
                <tbody style={{ marginBottom: "20px" }}>
                  {filteredSubPolicies
                    .slice(
                      (currentPage - 1) * itemsPerPage,
                      currentPage * itemsPerPage
                    )
                    .map((policy, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            height: "50px",
                            verticalAlign: "middle",
                          }}
                        >
                          <ContextMenuTrigger id={policy.reference}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                height: "50px",
                                cursor: "pointer",
                              }}
                            >
                              <div style={{ width: "30px" }}>
                                {getIcon("policy")}
                              </div>
                              <div>
                                <span style={{ marginLeft: "10px" }}>
                                  {policy.title}
                                </span>
                              </div>
                            </div>
                          </ContextMenuTrigger>

                          <ContextMenu
                            className="mm-card my-shade mm-text-white mm-padding"
                            style={{ borderRadius: "10px", zIndex: "99999" }}
                            id={policy.reference}
                          >
                            <Link
                                className="dropdown-item"
                                to={`/access/policies/${params.package}/${params.category}/${policy.reference}/`}
                                style={{
                                  cursor: "pointer",
                                  textDecoration: "none",
                                }}
                              >
                               <FontAwesomeIcon icon={faFileAlt}  /> Read Online
                              </Link>
                              <br />
                            {(localData("get", "session").role === 1 || localData("get", "session").role === 2) && (
                                 <Link
                                 className="dropdown-item"
                                 to={`/access/policies/${params.package}/${params.category}/${policy.reference}/edit/`}
                                 style={{
                                   cursor: "pointer",
                                   textDecoration: "none",
                                 }}
                               >
                                 <FontAwesomeIcon icon={faPencilAlt}  /> Edit Online
                               </Link>
                              )}

                              <hr />
                              <Link
                                className="dropdown-item"
                                to={`/access/policies/${params.package}/${params.category}/${policy.reference}/forms/`}
                                style={{
                                  cursor: "pointer",
                                  textDecoration: "none",
                                }}
                              >
                               <FontAwesomeIcon icon={faFileAlt}  /> Policy Forms
                              </Link>
                          </ContextMenu>
                        </td>
                        <td>
                          <Button
                            variant="btn"
                            onClick={() => {
                              handleDownload(policy.reference);
                              setDownloadName(policy.title);
                            }}
                            title="Download"
                          >
                           <FontAwesomeIcon icon={faFilePdf} color="red" /> Download
                          </Button>{" "}
                        </td>
                        <td
                          style={{
                            verticalAlign: "middle",
                          }}
                        >
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="light"
                              id="dropdown-basic"
                              className="mm-color no-outline"
                              style={{ width: "100%" }}
                            >
                              Options
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                              style={{
                                borderRadius: "10px",
                                zIndex: "99999",
                                width: "100%",
                              }}
                            >
                              <Link
                                className="dropdown-item"
                                to={`/access/policies/${params.package}/${params.category}/${policy.reference}/`}
                                style={{
                                  cursor: "pointer",
                                  textDecoration: "none",
                                }}
                              >
                                <FontAwesomeIcon icon={faEye}  /> Read Online
                              </Link>
                              {(localData("get", "session").role === 1 || localData("get", "session").role === 2) && (
                                 <Link
                                 className="dropdown-item"
                                 to={`/access/policies/${params.package}/${params.category}/${policy.reference}/edit/`}
                                 style={{
                                   cursor: "pointer",
                                   textDecoration: "none",
                                 }}
                               >
                                 <FontAwesomeIcon icon={faPencilAlt}  /> Edit Online
                               </Link>
                              )}
                              <Dropdown.Divider />
                               <Link
                                className="dropdown-item"
                                to={`/access/policies/${params.package}/${params.category}/${policy.reference}/forms/`}
                                style={{
                                  cursor: "pointer",
                                  textDecoration: "none",
                                }}
                              >
                               <FontAwesomeIcon icon={faFileAlt}  /> Policy Forms
                              </Link>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>

              <Pagination className="mm-pagination">
                <Pagination.First
                  onClick={() => handlePageClick(1)}
                  disabled={currentPage === 1}
                />
                <Pagination.Prev
                  onClick={() => handlePageClick(currentPage - 1)}
                  disabled={currentPage === 1}
                />
                {currentPage > 2 && (
                  <Pagination.Item
                    onClick={() => handlePageClick(currentPage - 2)}
                  >
                    {currentPage - 2}
                  </Pagination.Item>
                )}
                {currentPage > 1 && (
                  <Pagination.Item
                    onClick={() => handlePageClick(currentPage - 1)}
                  >
                    {currentPage - 1}
                  </Pagination.Item>
                )}
                <Pagination.Item active>{currentPage}</Pagination.Item>
                {currentPage < totalPages && (
                  <Pagination.Item
                    onClick={() => handlePageClick(currentPage + 1)}
                  >
                    {currentPage + 1}
                  </Pagination.Item>
                )}
                {currentPage < totalPages - 1 && (
                  <Pagination.Item
                    onClick={() => handlePageClick(currentPage + 2)}
                  >
                    {currentPage + 2}
                  </Pagination.Item>
                )}
                <Pagination.Next
                  onClick={() => handlePageClick(currentPage + 1)}
                  disabled={currentPage === totalPages}
                />
                <Pagination.Last
                  onClick={() => handlePageClick(totalPages)}
                  disabled={currentPage === totalPages}
                />
              </Pagination>
            </Card.Body>
          </Card>
        </Col>
        <SideBar />
      </Row>

      <Modal isOpen={running} style={customStyles}>
        <div
          className="mm-center"
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <h4 style={{ color: "#343a40" }}>Please wait...</h4>
          <br />
          <Loader />
        </div>
      </Modal>

      <Modal
        isOpen={report}
        onRequestClose={() => setReporting(false)}
        style={customStyles}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <h4>
            Message{" "}
            <span
              onClick={() => setReporting(false)}
              title="Close"
              className="mm-right"
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={faTimes} color="red" />
            </span>
          </h4>

          <hr />

          <p className="mm-center">{message}</p>

          <br />
          <button
            className="mm-btn mm-red"
            onClick={() => setReporting(false)}
            style={{ borderRadius: "30px", width: "100%" }}
          >
            CLOSE
          </button>
        </div>
      </Modal>

      <Top />
    </Container>
  );
};

export default CategoryCustomer;
