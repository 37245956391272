import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/style.css';
import './css/alter.css';

import reportWebVitals from './jsx/reportWebVitals';
import App from './jsx/app';
import { localData } from './jsx/backend';
const backgroundColor = localData('get', 'branding').color || '#8b7f83';
const shadeColor = localData('get', 'branding').shade || '#eeeeee';
document.documentElement.style.setProperty('--primary-color', backgroundColor);
document.documentElement.style.setProperty('--secondary-color', shadeColor);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
reportWebVitals();