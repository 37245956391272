import React, { useState } from "react";
import { Dropdown, Button, Form, DropdownDivider } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faTimes } from "@fortawesome/free-solid-svg-icons";
import { localData, runData } from "../../../jsx/backend";
import Modal from "react-modal";
import Loader from "../../includes/loader";

Modal.setAppElement('#root'); 

const RenderDeleteOption = ({ user }) => {
  const userRole = localData("get", "session").role;
  const targetUserRole = user.role;
  const [running, setRunning] = useState(false);
  const [message, setMessage] = useState("");
  const [report, setReporting] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [staff, setStaff] = useState({
    delete: "",
  });

  const handleChange = (e) => {
    setStaff({
      ...staff,
      [e.target.name]: e.target.value,
    });
  };

  const deleteUser = async (e) => {
    e.preventDefault();
    setDeleting(false);
    setRunning(true);

    const data = {
      reference: user.reference,
      confirm: staff.delete,
    };

    for (let key in data) {
      if (data[key] === "") {
        setRunning(false);
        setMessage(`Please fill in the ${key} field. It is required`);
        setReporting(true);
        return;
      }
    }
    const responseData = await runData(data, `/query/staff/delete/`);

    setRunning(false);

    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          localData("save", "staff_refresh", 1);
          setMessage("You have successfully deleted this staff account!");
          setReporting(true);
          break;
        default:
          setMessage(responseData.response.data.message);
          setReporting(true);
      }
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setReporting(true);
    } else {
      setMessage("An error occurred while processing the request.");
      setReporting(true);
    }
  };

  const customStyles = {
    overlay: {
      backgroundColor: "#ffffffeb",
      zIndex: 10000,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "transparent",
      border: "none",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minWidth: "30VW",
    },
  };

  if (
    userRole === 1 ||
    (userRole === 2 && (targetUserRole === 3 || targetUserRole === 4)) ||
    (userRole === 3 && targetUserRole === 4)
  ) {
    return (
      <>
        <DropdownDivider />
        <Dropdown.Item onClick={() => setDeleting(true)}>
          <div className="d-flex justify-content-between text-danger">
            Delete
            <FontAwesomeIcon icon={faArrowRight} />
          </div>
        </Dropdown.Item>

        <Modal
          isOpen={deleting}
          onRequestClose={() => setDeleting(false)}
          style={customStyles}
        >
          <div
            style={{
              width: "100%",
              backgroundColor: "#f8f9fa",
              padding: "20px",
              borderRadius: "10px",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
              maxWidth: "500px",
            }}
          >
            <h4>
              Delete Staff ({user.name}){" "}
              <span
                onClick={() => setDeleting(false)}
                title="Close"
                className="mm-right"
                style={{ cursor: "pointer" }}
              >
                <FontAwesomeIcon icon={faTimes} color="red" />
              </span>
            </h4>
            <br />
            <h6 className="mm-center text-warning">
              You are about to delete a staff account. This action can not be
              reversed!
            </h6>
            <hr />
            <Form onSubmit={deleteUser}>
              <Form.Group>
                <Form.Label>Confirm: Enter DELETE</Form.Label>
                <Form.Control
                  type="text"
                  name="delete"
                  className="no-outline"
                  value={user.delete}
                  onChange={handleChange}
                  placeholder="DELETE"
                  autoComplete="off"
                />
              </Form.Group>

              <div className="d-flex justify-content-between mt-3">
                <Button variant="danger" onClick={() => setDeleting(false)}>
                  Cancel
                </Button>
                <Button variant="light" className="mm-color" type="submit">
                  Delete
                </Button>
              </div>
            </Form>
          </div>
        </Modal>

        <Modal isOpen={running} style={customStyles}>
          <div
            className="mm-center"
            style={{
              width: "100%",
              backgroundColor: "#f8f9fa",
              padding: "20px",
              borderRadius: "10px",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
            }}
          >
            <h6 style={{ color: "#343a40" }}>Please wait...</h6>
            <br />
            <Loader />
          </div>
        </Modal>

        <Modal
          isOpen={report}
          onRequestClose={() => setReporting(false)}
          style={customStyles}
        >
          <div
            style={{
              width: "100%",
              backgroundColor: "#f8f9fa",
              padding: "20px",
              borderRadius: "10px",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
            }}
          >
            <h6>
              Message{" "}
              <span
                onClick={() => setReporting(false)}
                title="Close"
                className="mm-right"
                style={{ cursor: "pointer" }}
              >
                <FontAwesomeIcon icon={faTimes} color="red" />
              </span>
            </h6>

            <hr />

            <p className="mm-center">{message}</p>

            <br />
            <button
              className="mm-btn mm-red"
              onClick={() => setReporting(false)}
              style={{ borderRadius: "30px", width: "100%" }}
            >
              CLOSE
            </button>
          </div>
        </Modal>
      </>
    );
  }
  return null;
};

export default RenderDeleteOption;
