import React, { useState, useEffect, useRef } from 'react';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import {Helmet} from "react-helmet";
import Top from '../../includes/topping';
import { Link, useParams } from "react-router-dom"
import { apiData, localData, cartData, runData }  from '../../../jsx/backend';
import Loader from '../../includes/loader';
import Modal from 'react-modal';
import { unescape } from 'validator';
import moment from 'moment';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faChevronCircleUp,
  faPlusSquare,
  faChartLine,
  faCogs,} from '@fortawesome/free-solid-svg-icons';

const PackageTemplate = () => {
    const params = useParams();
    const [packageName, setPackageName] = useState('Package');
    const [policy, setPolicy] = useState('');
    const [template, setTemplate] = useState(null);
    const editorRef = useRef();
    const [error, setError] = useState("");

    const [loading, setLoading] = useState(true);
    const [running, setRunning] = useState(false);
    const [message, setMessage] = useState("");
    const [report, setReporting] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [stats, setStats] = useState(false);
  
    const currentDate = new Date();
    const firstDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
  
    const [startDate, setStartDate] = useState(firstDayOfMonth);
    const [endDate, setEndDate] = useState(new Date());
  
    //const [data, setData] = useState([]);
    const data = [
      { date: '2023-11-07', Users: 4000, Errors: 2400, Clones: 2400, Downloads: 4000 },
      { date: '2023-11-08', Users: 3000, Errors: 1398, Clones: 2210, Downloads: 3000 },
      { date: '2023-11-09', Users: 2000, Errors: 9800, Clones: 2290, Downloads: 2000 },
      { date: '2023-11-10', Users: 2780, Errors: 3908, Clones: 2000, Downloads: 2780 },
      { date: '2023-11-11', Users: 1890, Errors: 4800, Clones: 2181, Downloads: 1890 },
      { date: '2023-11-12', Users: 2390, Errors: 3800, Clones: 2500, Downloads: 2390 },
      { date: '2023-11-13', Users: 3490, Errors: 4300, Clones: 2100, Downloads: 3490 },
      { date: '2023-11-14', Users: 4000, Errors: 2400, Clones: 2400, Downloads: 4000 },
      { date: '2023-11-15', Users: 3000, Errors: 1398, Clones: 2210, Downloads: 3000 },
      { date: '2023-11-16', Users: 2000, Errors: 9800, Clones: 2290, Downloads: 2000 },
      { date: '2023-11-17', Users: 2780, Errors: 3908, Clones: 2000, Downloads: 2780 },
      { date: '2023-11-18', Users: 1890, Errors: 4800, Clones: 2181, Downloads: 1890 },
      { date: '2023-11-19', Users: 2390, Errors: 3800, Clones: 2500, Downloads: 2390 },
      { date: '2023-11-20', Users: 3490, Errors: 4300, Clones: 2100, Downloads: 3490 },
      { date: '2023-11-21', Users: 4000, Errors: 2400, Clones: 2400, Downloads: 4000 },
    ];
  
    const filteredData = data.filter((item) => {
      const itemDate = new Date(item.date);
      return itemDate >= startDate && itemDate <= endDate;
    });
  
    const viewTemplate = async () => {
      setRunning(true);
      const data = {
    
      }
      const responseData = await runData(data, `/query/policies/${params.package}/template/`);
      setRunning(false);
      if (responseData.response) {
      switch(responseData.response.data.code){
        case 200:
          setPackageName(responseData.response.data.package);
          setPolicy(unescape(responseData.response.data.template));
          setTemplate(unescape(responseData.response.data.template));
          setLoading(false);
          setLoaded(true);
        break;
    
        default:
          setMessage(responseData.response.data.message);
          setError(true);
      }
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setError(true);
    } else {
      setMessage("An error occured while processing your request.");
      setError(true);
    }}
    

    useEffect(() => {
      viewTemplate();
    }, []);

      const handleChange = (policy) => {
        setPolicy(policy);
      }
    const save = async () => {
      setRunning(true);
      const data = {
        "template": policy
      }
      const responseData = await runData(data, `/query/policies/${params.package}/update/`);
      setRunning(false);
      if (responseData.response) {
      switch(responseData.response.data.code){
        case 200:
          setMessage("Your template has been updated succesfully!");
          setReporting(true);
        break;
    
        default:
          setMessage(responseData.response.data.message);
          setError(true);
      }
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setError(true);
    } else {
      setMessage("An error occured while processing your request.");
      setError(true);
    }};


    const handleReload = () => {
      viewTemplate();
    };

   const templates = [
  {
      name: 'New Policy',
      html: `<p></p>`
  },
  {
    name: 'Restore Editor',
    html: `<p>${template}</p>`
}
];

const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      zIndex: 10000,
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'transparent',
      border: 'none',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minWidth: '30VW',
      
    },
  };
  
return (
  <div
  className='main mm-container mm-row-padding"'
  style={{ minHeight: "95VH", wordWrap: "break-word" }}
>
  <Helmet>
    <title>
     Template:: {packageName} - {localData("get", "branding").company || "Portal"}
    </title>
  </Helmet>

  <div className="mm-threequarter mm-padding">
    {loading && !error && (
      <div style={{ marginTop: "100px" }}>
        {" "}
        <Loader />{" "}
      </div>
    )}

    {error && !loading && (
      <div className="mm-display-container" style={{ height: "50VH" }}>
        <div className="mm-display-middle mm-center">
          {message ? (
            <h4>{message}</h4>
          ) : (
            <h4>Processing your request was not successful!</h4>
          )}
          <br />
          <button
            onClick={handleReload}
            className="mm-btn my-color"
            style={{ borderRadius: "30px" }}
          >
            RELOAD
          </button>
        </div>
      </div>
    )}

    {loaded && !error && !loading && (
<div className="mm-panel">
<SunEditor
ref={editorRef}
setOptions={{
		templates,
        height: "80VH",
        width: "100%",
        buttonList: [
          ['undo', 'redo'],
            [':p-More Paragraph-default.more_paragraph', 'fontSize', 'formatBlock', 'paragraphStyle', 'blockquote'],
            ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
            ['fontColor', 'hiliteColor', 'textStyle'],
            ['removeFormat'],
            ['outdent', 'indent'],
            ['align', 'horizontalRule', 'list', 'lineHeight'],
            ['-right', ':i-More Misc-default.more_vertical', 'fullScreen', 'showBlocks', 'preview', 'template'],
            ['-right', ':r-More Rich-default.more_plus', 'table'],
            ['-right', 'image', 'link'],
            ['%992', [
                ['undo', 'redo'],
                [':p-More Paragraph-default.more_paragraph', 'fontSize', 'formatBlock', 'paragraphStyle', 'blockquote'],
                ['bold', 'underline', 'italic', 'strike'],
                [':t-More Text-default.more_text', 'subscript', 'superscript', 'fontColor', 'hiliteColor', 'textStyle'],
                ['removeFormat'],
                ['outdent', 'indent'],
                ['align', 'horizontalRule', 'list', 'lineHeight'],
                ['-right', ':i-More Misc-default.more_vertical', 'fullScreen', 'showBlocks', 'preview', 'template'],
                ['-right', ':r-More Rich-default.more_plus', 'table', 'link', 'image']
            ]],
            ['%767', [
                ['undo', 'redo'],
                [':p-More Paragraph-default.more_paragraph', 'fontSize', 'formatBlock', 'paragraphStyle', 'blockquote'],
                [':t-More Text-default.more_text', 'bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'fontColor', 'hiliteColor', 'textStyle'],
                ['removeFormat'],
                ['outdent', 'indent'],
                [':e-More Line-default.more_horizontal', 'align', 'horizontalRule', 'list', 'lineHeight'],
                [':r-More Rich-default.more_plus', 'table', 'link', 'image'],
                ['-right', ':i-More Misc-default.more_vertical', 'fullScreen', 'showBlocks', 'preview', 'template']
            ]],
            ['%480', [
                ['undo', 'redo'],
                [':p-More Paragraph-default.more_paragraph', 'fontSize', 'formatBlock', 'paragraphStyle', 'blockquote'],
                [':t-More Text-default.more_text', 'bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'fontColor', 'hiliteColor', 'textStyle', 'removeFormat'],
                [':e-More Line-default.more_horizontal', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'lineHeight'],
                [':r-More Rich-default.more_plus', 'table', 'link', 'image'],
                ['-right', ':i-More Misc-default.more_vertical', 'fullScreen', 'showBlocks', 'preview', 'template']
            ]]
        ]
			}}
      setContents={policy}
      onChange={handleChange} />
<br />
<button onClick={save} className='mm-btn mm-hover-grey mm-text-white' style={{width: "100%", borderRadius: "5px", background: localData('get', 'branding').color || '#8b7f83'}}>UPDATE TEMPLATE</button>
</div>
    )}
    </div>
    
    <div
            className="mm-quarter mm-padding"
            style={{ top: "75px", position: "sticky" }}
          >
            <div
              className="mm-card mm-padding in-sidenav"
              style={{ borderRadius: "10px", width: "100%" }}
            >
              <h4 className="mm-text-black">Template Options
              <span
                  className="mm-right"
                  style={{ textDecoration: "none" }}
                >
                  <FontAwesomeIcon icon={faCogs} />
                </span>
                </h4>
                        
                <hr />

              <section className="menu-wrapper" onClick={() => setStats(true)}>
                <span>
                  <FontAwesomeIcon icon={faChartLine} color="orange" /> Statistics
                </span>
                <FontAwesomeIcon icon={faPlusSquare} />
              </section>

                <hr />
            
             <Link to={`/policies/`}>
                    <button
                      className="mm-small my-color zoom-on-hover mm-text-white"
                      style={{
                        padding: "10px 20px",
                        borderRadius: "5px",
                        border: "none",
                        cursor: "pointer",
                        width: "100%",
                      }}
                    >
                      GO ONE LEVEL UP <FontAwesomeIcon icon={faChevronCircleUp} />
                    </button>
                </Link>
              <br />
            </div>
          </div>
    
    
          <Modal
            isOpen={stats}
            onRequestClose={() => setStats(false)}
            style={customStyles}
          >
            <div
              style={{
                width: "100%",
                backgroundColor: "#f8f9fa",
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
              }}
            >
                <div
                  className="mm-light-grey mm-padding"
                  style={{ marginBottom: "30px" }}
                >
                  <div className="mm-container">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "20px",
                      }}
                    >
                      <div style={{ position: "relative", width: "45%" }}>
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          className="date-picker"
                        />
                        <label for="startDate" className="date-label">
                          Start Date
                        </label>
                      </div>
                      <div style={{ position: "relative", width: "45%" }}>
                        <DatePicker
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          className="date-picker"
                        />
                        <label for="endDate" className="date-label">
                          End Date
                        </label>
                      </div>
                    </div>
                    <ResponsiveContainer width="100%" height={400}>
                      <LineChart data={filteredData}>
                        <CartesianGrid strokeDasharray="1 1" />
                        <XAxis dataKey="date" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line
                          type="monotone"
                          dataKey="Users"
                          stroke="#8884d8"
                          activeDot={{ r: 8 }}
                        />
                        <Line type="monotone" dataKey="Errors" stroke="#82ca9d" />
                        <Line type="monotone" dataKey="Clones" stroke="#ffc658" />
                        <Line
                          type="monotone"
                          dataKey="Downloads"
                          stroke="#ff9933"
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                </div>
    <h4 >Package: { packageName }</h4>
    <br />
    <button onClick={() => setStats(false)}
                      className="mm-small my-color zoom-on-hover mm-text-white"
                      style={{
                        padding: "10px 20px",
                        borderRadius: "5px",
                        border: "none",
                        cursor: "pointer",
                        width: "100%",
                      }}
                    >
                      CLOSE STATISTICS
                    </button>
    </div>
    </Modal>
    
    <Modal isOpen={running} style={customStyles}>
            <div
              className="mm-center"
              style={{
                width: "100%",
                backgroundColor: "#f8f9fa",
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
              }}
            >
              <h4 style={{ color: "#343a40" }}>Please wait...</h4>
              <br />
              <Loader />
            </div>
          </Modal>
    
          <Modal
            isOpen={report}
            onRequestClose={() => setReporting(false)}
            style={customStyles}
          >
            <div
              style={{
                width: "100%",
                backgroundColor: "#f8f9fa",
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
              }}
            >
              <h4>
                Message{" "}
                <span
                  onClick={() => setReporting(false)}
                  title="Close"
                  className="mm-right"
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon icon={faTimes} color="red" />
                </span>
              </h4>
    
              <hr />
    
              <p className="mm-center">{message}</p>
    
              <br />
              <button
                className="mm-btn mm-red"
                onClick={() => setReporting(false)}
                style={{ borderRadius: "30px", width: "100%" }}
              >
                CLOSE
              </button>
            </div>
          </Modal>
    
    <Top />
    </div>
);}

export default PackageTemplate;