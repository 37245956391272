import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { localData, runData } from "../../../jsx/backend";
import moment from "moment";
import { marked } from "marked";
import { unescape } from "validator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Col, Container, Row } from "react-bootstrap";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { faClipboard, faTimes } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import Top from "../../includes/topping";
import Loader from "../../includes/loader";
import SideBar from "../../sections/sidebar";
marked.setOptions({
  gfm: true,
  tables: true,
});

Modal.setAppElement("#root");

const Compose = () => {
  const [question, setQuestion] = useState("");
  const [messages, setMessages] = useState([]);
  const endOfList = useRef(null);

  const [loaded, setLoaded] = useState(false);

  const [loading, setLoading] = useState(true);
  const [running, setRunning] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [report, setReporting] = useState(false);

  const [stats, setStats] = useState(false);

  const currentDate = new Date();
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );

  const [startDate, setStartDate] = useState(firstDayOfMonth);
  const [endDate, setEndDate] = useState(new Date());

  const data = [];

  const filteredData = data.filter((item) => {
    const itemDate = new Date(item.date);
    return itemDate >= startDate && itemDate <= endDate;
  });

  const composeHistory = async () => {
    const data = {};

    const responseData = await runData(data, "/query/compose/history/");
    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          setMessages(responseData.response.data.answer);
          setLoading(false);
          setLoaded(true);
          break;

        default:
          setLoading(false);
      }
    } else if (responseData.error) {
      setError(true);
      setLoading(false);
    } else {
      setError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    composeHistory();
  }, []);

  useEffect(() => {
    if (endOfList.current) {
      endOfList.current.scrollIntoView({
        behavior: "smooth",
      });
      window.scrollBy(0, -200);
    }
  }, [messages]);

  function copyToClipboard(html) {
    var parser = new DOMParser();
    var doc = parser.parseFromString(html, "text/html");
    var text = doc.body.textContent || "";
    navigator.clipboard.writeText(text).then(
      function () {
        setMessage("Copying to clipboard was successful!");
        setReporting(true);
      },
      function (err) {
        setMessage("Could not copy text: ", err.message);
        setReporting(true);
      }
    );
  }

  const handleUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleQuestion = async (e) => {
    e.preventDefault();
    setRunning(true);
    const data = {
      instruction: "",
      question: question,
    };

    const responseData = await runData(data, "/query/compose/");
    setRunning(false);
    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          setQuestion("");
          setMessages([...messages, ...responseData.response.data.answer]);
          break;

        default:
          setMessage(responseData.response.data.message);
          setReporting(true);
      }
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setReporting(true);
    } else {
      setMessage("An error occured while processing your request.");
      setReporting(true);
    }
  };

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      zIndex: 10000,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "transparent",
      border: "none",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minWidth: "30VW",
    },
  };

  return (
    <Container style={{ minHeight: "95VH", wordWrap: "break-word" }} fluid>
      <Helmet>
        <title>
          {localData("get", "branding").bot || "Composer"} -{" "}
          {localData("get", "branding").company || "Portal"}
        </title>
      </Helmet>
      <Row className="mt-3 mm-padding-large">
        <Col md={9}>
          <Card id="policies" className="mb-4">
            <Card.Header style={{ position: "sticky", top: 0, zIndex: 1 }}>
              <Row className="d-flex align-items-center justify-content-between">
                <Col md={6}>
                  <h6>Assistant Chat</h6>
                </Col>
                <Col
                  md={6}
                  className="d-flex align-items-start justify-content-end"
                ></Col>
              </Row>
            </Card.Header>
            <Card.Body
              style={{ overflowY: "auto", maxHeight: "60vh", padding: "20px" }}
            >
              <ul
                className="mm-container mm-justify bot-messages"
                style={{ minHeight: "80vh" }}
              >
                {messages.map((message) => (
                  <li
                    key={message.reference}
                    className="mm-padding mm-border"
                    style={{
                      borderRadius: "5px",
                      marginBottom: "20px",
                      backgroundColor: "#f5f5f5",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <h4>{ localData("get", "session").name }</h4>
                          <h4>
                            {moment(message.date).format(
                              "Do of MMMM YYYY, h:mm"
                            )}
                          </h4>
                        </div>
                        <hr />
                        <div
                          dangerouslySetInnerHTML={{
                            __html: marked.parse(unescape(message.question || "Empty Message")),
                          }}
                          style={{ width: "100%" }}
                        ></div>
                        <br />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <h4>AI Assistant</h4>
                          <FontAwesomeIcon
                            icon={faClipboard}
                            onClick={() =>
                              copyToClipboard(
                                marked.parse(unescape(message.response))
                              )
                            }
                            title="Copy"
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                        <hr />
                        <div
                          dangerouslySetInnerHTML={{
                            __html: marked.parse(unescape(message.response)),
                          }}
                          style={{ width: "100%" }}
                        ></div>
                      </div>
                    </div>
                  </li>
                ))}
                <div ref={endOfList}></div>
              </ul>
            </Card.Body>
            <Card.Footer style={{ position: "sticky", bottom: 0, zIndex: 1 }} className="mm-white">
              <div
                style={{ padding: "20px" }}
              >
                <div className="input-container" onClick={handleUp}>
                  <form onSubmit={handleQuestion} className="chat-form">
                    <div className="chat-input-container">
                      <textarea
                        value={question}
                        onChange={(event) => setQuestion(event.target.value)}
                        placeholder="Type your message here..."
                        className="chat-input"
                        style={{ borderRadius: "5px", padding: "10px" }}
                      />
                    </div>
                    <button
                      type="submit"
                      className="submit-button mm-hover-grey"
                      style={{ borderRadius: "5px", padding: "10px 20px" }}
                    >
                      SEND
                    </button>
                  </form>
                </div>
              </div>
            </Card.Footer>
          </Card>
        </Col>
        <SideBar />
      </Row>

      <Modal
        isOpen={stats}
        onRequestClose={() => setStats(false)}
        style={customStyles}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <div
            className="mm-light-grey mm-padding"
            style={{ marginBottom: "30px" }}
          >
            <div className="mm-container">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <div style={{ position: "relative", width: "45%" }}>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    className="date-picker"
                  />
                  <label for="startDate" className="date-label">
                    Start Date
                  </label>
                </div>
                <div style={{ position: "relative", width: "45%" }}>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    className="date-picker"
                  />
                  <label for="endDate" className="date-label">
                    End Date
                  </label>
                </div>
              </div>
              <ResponsiveContainer width="100%" height={400}>
                <LineChart data={filteredData}>
                  <CartesianGrid strokeDasharray="1 1" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="Users"
                    stroke="#8884d8"
                    activeDot={{ r: 8 }}
                  />
                  <Line type="monotone" dataKey="Errors" stroke="#82ca9d" />
                  <Line type="monotone" dataKey="Clones" stroke="#ffc658" />
                  <Line type="monotone" dataKey="Downloads" stroke="#ff9933" />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
          <h4>{localData("get", "branding").bot || "Composer"} Chat</h4>
          <br />
          <button
            onClick={() => setStats(false)}
            className="mm-small my-color zoom-on-hover mm-text-white"
            style={{
              padding: "10px 20px",
              borderRadius: "5px",
              border: "none",
              cursor: "pointer",
              width: "100%",
            }}
          >
            CLOSE STATISTICS
          </button>
        </div>
      </Modal>

      <Modal isOpen={running} style={customStyles}>
        <div
          className="mm-center"
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <h4 style={{ color: "#343a40" }}>Please wait...</h4>
          <br />
          <Loader />
        </div>
      </Modal>

      <Modal
        isOpen={report}
        onRequestClose={() => setReporting(false)}
        style={customStyles}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <h4>
            Message{" "}
            <span
              onClick={() => setReporting(false)}
              title="Close"
              className="mm-right"
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={faTimes} color="red" />
            </span>
          </h4>

          <hr />

          <p className="mm-center">{message}</p>

          <br />
          <button
            className="mm-btn mm-red"
            onClick={() => setReporting(false)}
            style={{ borderRadius: "30px", width: "100%" }}
          >
            CLOSE
          </button>
        </div>
      </Modal>

      <Top />
    </Container>
  );
};

export default Compose;
