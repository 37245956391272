import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Top from "../../includes/topping";
import { Link, useParams } from "react-router-dom";
import { localData, runData } from "../../../jsx/backend";
import Loader from "../../includes/loader";
import { saveAs } from "file-saver";
import { slugify } from "../../includes/cleaning";
import { unescape } from "validator";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  faFilePdf,
  faTimes,
  faBars,
  faChevronCircleUp,
  faDownload,
  faDotCircle,
  faToggleOn,
  faToggleOff,
  faPlusSquare,
  faChartLine,
  faFileArchive,
  faBoxOpen,
  faFileEdit,
  faPencilSquare,
  faCogs,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-modal";
import Select from "react-select";

Modal.setAppElement("#root");

const Policy = () => {
  const [policy, setPolicy] = useState("");
  const [policyTitle, setPolicyTitle] = useState("");
  const [category, setFolder] = useState("Category");
  const [packageName, setPackageName] = useState("Package");
  const params = useParams();
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const [loading, setLoading] = useState(true);
  const [running, setRunning] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [report, setReporting] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [isOn, setIsOn] = useState(1);
  const [stats, setStats] = useState(false);

  const currentDate = new Date();
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );

  const [startDate, setStartDate] = useState(firstDayOfMonth);
  const [endDate, setEndDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const fetchOptions = (inputValue) => {
    getProfiles();
  };


  const handleSelectChange = (option) => {
    if (option) {
      const optionParts = {
        'label': option.label,
        'value': option.value,
      }
      setRunning(true);
      localData('save', 'profile', optionParts);
      viewPolicy();
      setRunning(false);
    } else {
      localData('remove', 'profile');
      setRunning(true);
      viewPolicy();
      setRunning(false);
    }
  };

  //const [data, setData] = useState([]);
  const data = [
    {
      date: "2023-11-07",
      Users: 4000,
      Errors: 2400,
      Clones: 2400,
      Downloads: 4000,
    },
    {
      date: "2023-11-08",
      Users: 3000,
      Errors: 1398,
      Clones: 2210,
      Downloads: 3000,
    },
    {
      date: "2023-11-09",
      Users: 2000,
      Errors: 9800,
      Clones: 2290,
      Downloads: 2000,
    },
    {
      date: "2023-11-10",
      Users: 2780,
      Errors: 3908,
      Clones: 2000,
      Downloads: 2780,
    },
    {
      date: "2023-11-11",
      Users: 1890,
      Errors: 4800,
      Clones: 2181,
      Downloads: 1890,
    },
    {
      date: "2023-11-12",
      Users: 2390,
      Errors: 3800,
      Clones: 2500,
      Downloads: 2390,
    },
    {
      date: "2023-11-13",
      Users: 3490,
      Errors: 4300,
      Clones: 2100,
      Downloads: 3490,
    },
    {
      date: "2023-11-14",
      Users: 4000,
      Errors: 2400,
      Clones: 2400,
      Downloads: 4000,
    },
    {
      date: "2023-11-15",
      Users: 3000,
      Errors: 1398,
      Clones: 2210,
      Downloads: 3000,
    },
    {
      date: "2023-11-16",
      Users: 2000,
      Errors: 9800,
      Clones: 2290,
      Downloads: 2000,
    },
    {
      date: "2023-11-17",
      Users: 2780,
      Errors: 3908,
      Clones: 2000,
      Downloads: 2780,
    },
    {
      date: "2023-11-18",
      Users: 1890,
      Errors: 4800,
      Clones: 2181,
      Downloads: 1890,
    },
    {
      date: "2023-11-19",
      Users: 2390,
      Errors: 3800,
      Clones: 2500,
      Downloads: 2390,
    },
    {
      date: "2023-11-20",
      Users: 3490,
      Errors: 4300,
      Clones: 2100,
      Downloads: 3490,
    },
    {
      date: "2023-11-21",
      Users: 4000,
      Errors: 2400,
      Clones: 2400,
      Downloads: 4000,
    },
  ];

  const filteredData = data.filter((item) => {
    const itemDate = new Date(item.date);
    return itemDate >= startDate && itemDate <= endDate;
  });

  const downloadPDF = async () => {
    setRunning(true);
    const profile = localData("get", "profile").value;
    let data = {};
    
    if (profile) {
      data = { "profile": profile };
    }

    const responseData = await runData(
      data,
      `/query/policies/${params.package}/${params.category}/${params.policy}/download/pdf/`,
      "blob"
    );
    setRunning(false);
    if (responseData.response && responseData.response.status === 200) {
      setMessage("Your download has been successful!");
      const filename = slugify(policyTitle);
      const blob = new Blob([responseData.response.data], {
        type: responseData.response.headers["content-type"],
      });
      saveAs(blob, filename || "download");
      setReporting(true);
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setReporting(true);
    } else {
      setMessage("An error occured while processing your request.");
      setReporting(true);
    }
  };

  const viewPolicy = async () => {
    
    const profile = localData("get", "profile").value;
    let data = {};
    
    if (profile) {
      data = { "profile": profile };
    }

    const responseData = await runData(
      data,
      `/query/policies/${params.package}/${params.category}/${params.policy}/`
    );
    setLoading(false);
    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          setPolicy(responseData.response.data.details);
          setPolicyTitle(responseData.response.data.title);
          setFolder(responseData.response.data.category);
          setIsOn(responseData.response.data.status);
          setPackageName(responseData.response.data.package);
          setLoaded(true);
          break;
        default:
          setMessage(responseData.response.data.message);
          setError(true);
      }
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setError(true);
    } else {
      setMessage("An error occured while processing your request.");
      setError(true);
    }
  };

  const getProfiles = async () => {
    const data = {};

    const responseData = await  runData(
    data,
    `/query/policies/${params.package}/${params.category}/${params.policy}/subscriptions/`
    );

    if (responseData.response) {
    switch (responseData.response.data.code) {
    case 200:
    setOptions(responseData.response.data.options);
    break;
    default:
    setMessage(responseData.response.message);
    setReporting(true);
    }
    } else if (responseData.error) {
    setMessage(responseData.error.message);
    setReporting(true);
    } else {
    setMessage("An error occured while processing your request.");
    setReporting(true);
    }
  }

  useEffect(() => {
    viewPolicy();
    getProfiles();
  }, [params.policy]);

  const handleReload = () => {
    viewPolicy();
  };

  const handleStatus = async () => {
    setRunning(true);
    const data = {
      status: isOn,
    };
    const responseData = await runData(
      data,
      `/query/policies/${params.package}/${params.category}/${params.policy}/status/`
    );
    setRunning(false);
    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          setIsOn(responseData.response.data.status);
          setReporting(true);
          setMessage("Your request has been processed succesfully!");
          break;

        default:
          setMessage(responseData.response.data.message);
          setError(true);
      }
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setError(true);
    } else {
      setMessage("An error occured while processing your request.");
      setError(true);
    }
  };

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      zIndex: 10000,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "transparent",
      border: "none",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minWidth: "30VW",
    },
  };

  return (
    <div
      className='main mm-container mm-row-padding"'
      style={{ minHeight: "95VH", wordWrap: "break-word" }}
    >
      <Helmet>
        <title>
          {policyTitle} - {localData("get", "branding").company || "Portal"}
        </title>
      </Helmet>

      <div className="mm-threequarter mm-padding">
        {loading && !error && (
          <div style={{ marginTop: "100px" }}>
            {" "}
            <Loader />{" "}
          </div>
        )}

        {error && !loading && (
          <div className="mm-display-container" style={{ height: "50VH" }}>
            <div className="mm-display-middle mm-center">
              {message ? (
                <h4>{message}</h4>
              ) : (
                <h4>Processing your request was not successful!</h4>
              )}
              <br />
              <button
                onClick={handleReload}
                className="mm-btn my-color"
                style={{ borderRadius: "30px" }}
              >
                RELOAD
              </button>
            </div>
          </div>
        )}

        {loaded && !error && !loading && (
          <div className="mm-section mm-padding">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "10px",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "30px",
                }}
              >
                <h4 style={{ flexWrap: "wrap" }}>{policyTitle}</h4>
              </div>
              <div>
                <div className="dropdownList">
                  {!dropdownVisible ? (
                    <FontAwesomeIcon
                      onClick={() => setDropdownVisible(!dropdownVisible)}
                      className="dropbtn"
                      icon={faBars}
                      size="2x"
                      style={{ cursor: "pointer" }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      onClick={() => setDropdownVisible(!dropdownVisible)}
                      className="dropbtn"
                      icon={faTimes}
                      size="2x"
                      style={{ cursor: "pointer" }}
                    />
                  )}
                  {dropdownVisible && (
                    <div
                      className="dropdown-content mm-card my-shade mm-padding"
                      style={{ borderRadius: "10px" }}
                    >
                      <Link
                        onClick={() => setDropdownVisible(!dropdownVisible)}
                        to={`/policies/${params.package}/${params.category}/${params.policy}/forms/`}
                        style={{ textDecoration: "none" }}
                        title="Manage Forms"
                      >
                        Forms
                      </Link>
                      <hr />
                      <Link
                        onClick={() => setDropdownVisible(!dropdownVisible)}
                        to={`/policies/${params.package}/${params.category}/${params.policy}/edit/`}
                        style={{ textDecoration: "none" }}
                        title="Edit Policy"
                      >
                        Edit
                      </Link>
                      <hr />
                      <span
                        title="Download PDF"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setDropdownVisible(!dropdownVisible);
                          downloadPDF();
                        }}
                      >
                        Download
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div
              style={{ padding: "20px", marginBottom: "30px" }}
              dangerouslySetInnerHTML={{ __html: unescape(policy) }}
            />
          </div>
        )}
      </div>
      <div
        className="mm-quarter mm-padding"
        style={{ top: "75px", position: "sticky" }}
      >
        <div
          className="mm-card mm-padding in-sidenav"
          style={{ borderRadius: "10px", width: "100%" }}
        >
          <h4 className="mm-text-black">
            File Options
            <span className="mm-right" style={{ textDecoration: "none" }}>
              <FontAwesomeIcon icon={faCogs} />
            </span>
          </h4>

          <hr />

          <span style={{ marginBottom: "30px" }}>
            <Select
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? "grey" : "grey",
                  outline: "none",
                  boxShadow: "none",
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isSelected
                    ? localData("get", "branding").shade || "green"
                    : state.isFocused
                    ? "lightgray"
                    : "white",
                  color: "black",
                  cursor: "pointer",
                }),
              }}
              isClearable
              defaultValue={localData("get", "profile") || null}
              options={options}
              onChange={handleSelectChange}
              onInputChange={fetchOptions}
              placeholder='Choose a profile...'
              isLoading={isLoading}
              noOptionsMessage={() => "No subscriptions found"}
            />
          </span>
          <br />
          {isOn === 1 ? (
            <section className="menu-wrapper" onClick={handleStatus}>
              <span>
                <FontAwesomeIcon icon={faDotCircle} color="green" /> Activated
              </span>
              <FontAwesomeIcon icon={faToggleOff} color="red" />
            </section>
          ) : (
            <section className="menu-wrapper" onClick={handleStatus}>
              <span>
                <FontAwesomeIcon icon={faDotCircle} color="red" /> Suspended
              </span>
              <FontAwesomeIcon icon={faToggleOn} color="green" />
            </section>
          )}
          <br />
          <section className="menu-wrapper" onClick={downloadPDF}>
            <span>
              <FontAwesomeIcon icon={faFilePdf} color="red" /> Download
            </span>
            <FontAwesomeIcon icon={faDownload} />
          </section>
          <br />
          <section className="menu-wrapper" onClick={() => setStats(true)}>
            <span>
              <FontAwesomeIcon icon={faChartLine} color="orange" /> Statistics
            </span>
            <FontAwesomeIcon icon={faPlusSquare} />
          </section>
          <br />
          <Link
            to={`/policies/${params.package}/${params.category}/${params.policy}/forms/`}
            className="menu-wrapper"
          >
            <span>
              <FontAwesomeIcon icon={faFileArchive} color="grey" /> Forms
            </span>
            <FontAwesomeIcon icon={faBoxOpen} />
          </Link>
          <br />
          <Link
            to={`/policies/${params.package}/${params.category}/${params.policy}/edit/`}
            className="menu-wrapper"
          >
            <span>
              <FontAwesomeIcon icon={faFileEdit} color="grey" /> Edit
            </span>
            <FontAwesomeIcon icon={faPencilSquare} />
          </Link>
          <hr />

          <Link to={`/policies/${params.package}/${params.category}/`}>
            <button
              className="mm-small my-color zoom-on-hover mm-text-white"
              style={{
                padding: "10px 20px",
                borderRadius: "5px",
                border: "none",
                cursor: "pointer",
                width: "100%",
              }}
            >
              GO ONE LEVEL UP <FontAwesomeIcon icon={faChevronCircleUp} />
            </button>
          </Link>
          <br />
        </div>
      </div>

      <Modal
        isOpen={stats}
        onRequestClose={() => setStats(false)}
        style={customStyles}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <div
            className="mm-light-grey mm-padding"
            style={{ marginBottom: "30px" }}
          >
            <div className="mm-container">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <div style={{ position: "relative", width: "45%" }}>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    className="date-picker"
                  />
                  <label for="startDate" className="date-label">
                    Start Date
                  </label>
                </div>
                <div style={{ position: "relative", width: "45%" }}>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    className="date-picker"
                  />
                  <label for="endDate" className="date-label">
                    End Date
                  </label>
                </div>
              </div>
              <ResponsiveContainer width="100%" height={400}>
                <LineChart data={filteredData}>
                  <CartesianGrid strokeDasharray="1 1" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="Users"
                    stroke="#8884d8"
                    activeDot={{ r: 8 }}
                  />
                  <Line type="monotone" dataKey="Errors" stroke="#82ca9d" />
                  <Line type="monotone" dataKey="Clones" stroke="#ffc658" />
                  <Line type="monotone" dataKey="Downloads" stroke="#ff9933" />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
          <h4>Package: {packageName}</h4>
          <p>Category: {category}</p>
          <br />
          <button
            onClick={() => setStats(false)}
            className="mm-small my-color zoom-on-hover mm-text-white"
            style={{
              padding: "10px 20px",
              borderRadius: "5px",
              border: "none",
              cursor: "pointer",
              width: "100%",
            }}
          >
            CLOSE STATISTICS
          </button>
        </div>
      </Modal>
      <Modal isOpen={running} style={customStyles}>
        <div
          className="mm-center"
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <h4 style={{ color: "#343a40" }}>Please wait...</h4>
          <br />
          <Loader />
        </div>
      </Modal>

      <Modal
        isOpen={report}
        onRequestClose={() => setReporting(false)}
        style={customStyles}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <h4>
            Message{" "}
            <span
              onClick={() => setReporting(false)}
              title="Close"
              className="mm-right"
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={faTimes} color="red" />
            </span>
          </h4>

          <hr />

          <p className="mm-center">{message}</p>

          <br />
          <button
            className="mm-btn mm-red"
            onClick={() => setReporting(false)}
            style={{ borderRadius: "30px", width: "100%" }}
          >
            CLOSE
          </button>
        </div>
      </Modal>

      <Top />
    </div>
  );
};

export default Policy;
