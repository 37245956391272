import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { cartData, localData, runData } from "./backend";
import HeaderSection from "../web/sections/header";
import FooterSection from "../web/sections/footer";
import Home from "../web/pages/private/home";
import FourOFour from "../web/pages/public/404";
import Compose from "../web/pages/private/compose";
import Packages from "../web/pages/admin/packages";
import Package from "../web/pages/admin/package";
import Category from "../web/pages/admin/category";
import Policy from "../web/pages/admin/policy";
import PolicyEditor from "../web/pages/admin/policy.editor";
import FormsData from "../web/pages/admin/policy.forms";
import Templates from "../web/pages/admin/templates";
import PackageTemplate from "../web/pages/admin/package.template";
import Companies from "../web/pages/admin/companies";
import Company from "../web/pages/admin/company";
import Load from "../web/states/load";
import ErrorPage from "../web/states/error";
import { useCheckActivity } from "./hooks/activity";
import Expiry from "../web/states/expiry";
import Login from "../web/pages/public/auth/login";
import Reset from "../web/pages/public/auth/reset";
import Recover from "../web/pages/public/auth/recover";
import Block from "../web/states/block";
import CookieBanner from "../web/pages/public/legal/cookies";
import Cart from "../web/includes/cart";
import Customers from "../web/pages/admin/customers";
import Customer from "../web/pages/admin/customer";
import Register from "../web/pages/public/auth/register";
import PackagesCustomer from "../web/pages/customer/packages";
import PackageCustomer from "../web/pages/customer/package";
import CategoryCustomer from "../web/pages/customer/category";
import PolicyCustomer from "../web/pages/customer/policy";
import FormsDataCustomer from "../web/pages/customer/policy.forms";
import TemplatesCus from "../web/pages/customer/templates";
import PolicyEditorCus from "../web/pages/customer/policy.editor";
import Auto from "../web/pages/public/auth/auto";
import Chat from "../web/includes/chat";
import Staff from "../web/pages/private/staff";
import Cloark from "../web/pages/public/auth/cloark";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isBranded, setIsBranded] = useState(false);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isActive = useCheckActivity(localData("get", "branding").timeout || 5);
  const [isBuying, setIsBuying] = useState(false);
  const [isSaving, setIsSaving] = useState(true);
  const [historicalPath, setHistoricalPath] = useState(
    localData("get", "historical_path")
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const savedPath = localData("get", "historical_path");
      setHistoricalPath(savedPath);
    }, 1);

    return () => clearInterval(interval);
  }, [historicalPath]);

  const startApplication = async () => {
    setIsLoading(true);
    const brandingResponse = await runData({}, "/query/branding/");
    if (brandingResponse.response) {
      switch (brandingResponse.response.data.code) {
        case 200:
          const branding = {
            title: brandingResponse.response.data.title,
            color: brandingResponse.response.data.color,
            shade: brandingResponse.response.data.shade,
            company: brandingResponse.response.data.company,
            logo: brandingResponse.response.data.logo,
            website: brandingResponse.response.data.website,
            level: brandingResponse.response.data.level,
            bot: brandingResponse.response.data.bot,
            timeout: brandingResponse.response.data.timeout,
          };
          const start = localData("save", "branding", branding);
          setIsBranded(start);
          break;
        default:
          setError(true);
      }
    } else {
      setError(true);
    }
  };

  const validateApplication = () => {
    const authentication = localData("get", "session");
    const customisation = localData("get", "branding");
    if (
      !(Object.keys(authentication).length > 0) ||
      !(Object.keys(customisation).length > 0)
    ) {
      setIsAuthenticated(false);
      setIsBranded(false);
      setIsBuying(cartData("get"));
      setIsSaving(localData("get", "cookies"));
      setIsLoading(false);
    } else {
      setIsAuthenticated(true);
      setIsBranded(true);
      setIsBuying(cartData("get"));
      setIsSaving(localData("get", "cookies"));
      setIsLoading(false);
    }
  };

  useEffect(() => {
    startApplication();
    const intervalId = setInterval(() => {
      validateApplication();
    }, 500);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Router>
      {isBranded && isAuthenticated && isActive && <HeaderSection />}
      <Routes>
        <Route
          exact
          path="/"
          element={
            error ? (
              <ErrorPage />
            ) : isLoading ? (
              <Load />
            ) : isBranded && isAuthenticated ? (
              isActive ? (
                <Home />
              ) : (
                <Expiry />
              )
            ) : isLoading ? (
              <Load />
            ) : (
              <Navigate to="/login/" />
            )
          }
        />

        <Route
          exact
          path="/login/"
          element={
            error ? (
              <ErrorPage />
            ) : isLoading ? (
              <Load />
            ) : isBranded && isAuthenticated ? (
              <Navigate to={historicalPath || "/"} />
            ) : (
              <Login />
            )
          }
        />
        <Route
          exact
          path="/reset/"
          element={
            error ? (
              <ErrorPage />
            ) : isLoading ? (
              <Load />
            ) : isBranded && isAuthenticated ? (
              <Navigate to="/" />
            ) : isLoading ? (
              <Load />
            ) : (
              <Reset />
            )
          }
        />

        <Route
          exact
          path="/signup/:token/"
          element={
            error ? (
              <ErrorPage />
            ) : isLoading ? (
              <Load />
            ) : isBranded && isAuthenticated ? (
              <Navigate to="/" />
            ) : isLoading ? (
              <Load />
            ) : (
              <Register />
            )
          }
        />

        <Route
          exact
          path="/reset/:token/"
          element={
            error ? (
              <ErrorPage />
            ) : isLoading ? (
              <Load />
            ) : isBranded && isAuthenticated ? (
              <Navigate to="/" />
            ) : isLoading ? (
              <Load />
            ) : (
              <Recover />
            )
          }
        />

        <Route
          exact
          path="/services/composer/"
          element={
            error ? (
              <ErrorPage />
            ) : isLoading ? (
              <Load />
            ) : isBranded && isAuthenticated ? (
              isActive ? (
                <Compose />
              ) : (
                <Expiry />
              )
            ) : isLoading ? (
              <Load />
            ) : (
              <Navigate to="/login/" />
            )
          }
        />

        <Route
          exact
          path="/policies/"
          element={
            error ? (
              <ErrorPage />
            ) : isLoading ? (
              <Load />
            ) : isBranded && isAuthenticated ? (
              localData("get", "branding").level === 1 ? (
                isActive ? (
                  <Packages />
                ) : (
                  <Expiry />
                )
              ) : (
                <Block />
              )
            ) : isLoading ? (
              <Load />
            ) : (
              <Navigate to="/login/" />
            )
          }
        />

        <Route
          exact
          path="/access/policies/"
          element={
            error ? (
              <ErrorPage />
            ) : isLoading ? (
              <Load />
            ) : isBranded && isAuthenticated ? (
              localData("get", "branding").level === 3 ? (
                isActive ? (
                  <PackagesCustomer />
                ) : (
                  <Expiry />
                )
              ) : (
                <Block />
              )
            ) : isLoading ? (
              <Load />
            ) : (
              <Navigate to="/login/" />
            )
          }
        />

        <Route
          exact
          path="/access/templates/"
          element={
            error ? (
              <ErrorPage />
            ) : isLoading ? (
              <Load />
            ) : isBranded && isAuthenticated ? (
              localData("get", "branding").level === 3 ? (
                isActive ? (
                  <TemplatesCus />
                ) : (
                  <Expiry />
                )
              ) : (
                <Block />
              )
            ) : isLoading ? (
              <Load />
            ) : (
              <Navigate to="/login/" />
            )
          }
        />

        <Route
          exact
          path="/access/composer/"
          element={
            error ? (
              <ErrorPage />
            ) : isLoading ? (
              <Load />
            ) : isBranded && isAuthenticated ? (
              localData("get", "branding").level === 3 ? (
                isActive ? (
                  <Compose />
                ) : (
                  <Expiry />
                )
              ) : (
                <Block />
              )
            ) : isLoading ? (
              <Load />
            ) : (
              <Navigate to="/login/" />
            )
          }
        />

        <Route
          exact
          path="/access/policies/:package/"
          element={
            error ? (
              <ErrorPage />
            ) : isLoading ? (
              <Load />
            ) : isBranded && isAuthenticated ? (
              localData("get", "branding").level === 3 ? (
                isActive ? (
                  <PackageCustomer />
                ) : (
                  <Expiry />
                )
              ) : (
                <Block />
              )
            ) : isLoading ? (
              <Load />
            ) : (
              <Navigate to="/login/" />
            )
          }
        />

        <Route
          exact
          path="/policies/:package/"
          element={
            error ? (
              <ErrorPage />
            ) : isLoading ? (
              <Load />
            ) : isBranded && isAuthenticated ? (
              localData("get", "branding").level === 1 ? (
                isActive ? (
                  <Package />
                ) : (
                  <Expiry />
                )
              ) : (
                <Block />
              )
            ) : isLoading ? (
              <Load />
            ) : (
              <Navigate to="/login/" />
            )
          }
        />
        <Route
          exact
          path="/policies/:package/:category/"
          element={
            error ? (
              <ErrorPage />
            ) : isLoading ? (
              <Load />
            ) : isBranded && isAuthenticated ? (
              localData("get", "branding").level === 1 ? (
                isActive ? (
                  <Category />
                ) : (
                  <Expiry />
                )
              ) : (
                <Block />
              )
            ) : isLoading ? (
              <Load />
            ) : (
              <Navigate to="/login/" />
            )
          }
        />

        <Route
          exact
          path="/access/policies/:package/:category/"
          element={
            error ? (
              <ErrorPage />
            ) : isLoading ? (
              <Load />
            ) : isBranded && isAuthenticated ? (
              localData("get", "branding").level === 3 ? (
                isActive ? (
                  <CategoryCustomer />
                ) : (
                  <Expiry />
                )
              ) : (
                <Block />
              )
            ) : isLoading ? (
              <Load />
            ) : (
              <Navigate to="/login/" />
            )
          }
        />

        <Route
          exact
          path="/policies/:package/:category/:policy/"
          element={
            error ? (
              <ErrorPage />
            ) : isLoading ? (
              <Load />
            ) : isBranded && isAuthenticated ? (
              localData("get", "branding").level === 1 ? (
                isActive ? (
                  <Policy />
                ) : (
                  <Expiry />
                )
              ) : (
                <Block />
              )
            ) : isLoading ? (
              <Load />
            ) : (
              <Navigate to="/login/" />
            )
          }
        />

        <Route
          exact
          path="/access/policies/:package/:category/:policy/"
          element={
            error ? (
              <ErrorPage />
            ) : isLoading ? (
              <Load />
            ) : isBranded && isAuthenticated ? (
              localData("get", "branding").level === 3 ? (
                isActive ? (
                  <PolicyCustomer />
                ) : (
                  <Expiry />
                )
              ) : (
                <Block />
              )
            ) : isLoading ? (
              <Load />
            ) : (
              <Navigate to="/login/" />
            )
          }
        />

        <Route
          exact
          path="/access/policies/:package/:category/:policy/forms/"
          element={
            error ? (
              <ErrorPage />
            ) : isLoading ? (
              <Load />
            ) : isBranded && isAuthenticated ? (
              localData("get", "branding").level === 3 ? (
                isActive ? (
                  <FormsDataCustomer />
                ) : (
                  <Expiry />
                )
              ) : (
                <Block />
              )
            ) : isLoading ? (
              <Load />
            ) : (
              <Navigate to="/login/" />
            )
          }
        />
        <Route
          exact
          path="/policies/:package/:category/:policy/edit/"
          element={
            error ? (
              <ErrorPage />
            ) : isLoading ? (
              <Load />
            ) : isBranded && isAuthenticated ? (
              localData("get", "branding").level === 1 ? (
                isActive ? (
                  <PolicyEditor />
                ) : (
                  <Expiry />
                )
              ) : (
                <Block />
              )
            ) : isLoading ? (
              <Load />
            ) : (
              <Navigate to="/login/" />
            )
          }
        />
        <Route
          exact
          path="/access/policies/:package/:category/:policy/edit/"
          element={
            error ? (
              <ErrorPage />
            ) : isLoading ? (
              <Load />
            ) : isBranded && isAuthenticated ? (
              localData("get", "branding").level === 3 ? (
                isActive ? (
                  <PolicyEditorCus />
                ) : (
                  <Expiry />
                )
              ) : (
                <Block />
              )
            ) : isLoading ? (
              <Load />
            ) : (
              <Navigate to="/login/" />
            )
          }
        />
        <Route
          exact
          path="/policies/:package/template/"
          element={
            error ? (
              <ErrorPage />
            ) : isLoading ? (
              <Load />
            ) : isBranded && isAuthenticated ? (
              localData("get", "branding").level === 1 ? (
                isActive ? (
                  <PackageTemplate />
                ) : (
                  <Expiry />
                )
              ) : (
                <Block />
              )
            ) : isLoading ? (
              <Load />
            ) : (
              <Navigate to="/login/" />
            )
          }
        />
        <Route
          exact
          path="/policies/:package/:category/:policy/forms/"
          element={
            error ? (
              <ErrorPage />
            ) : isLoading ? (
              <Load />
            ) : isBranded && isAuthenticated ? (
              localData("get", "branding").level === 1 ? (
                isActive ? (
                  <FormsData />
                ) : (
                  <Expiry />
                )
              ) : (
                <Block />
              )
            ) : isLoading ? (
              <Load />
            ) : (
              <Navigate to="/login/" />
            )
          }
        />

        <Route
          exact
          path="/templates/"
          element={
            error ? (
              <ErrorPage />
            ) : isLoading ? (
              <Load />
            ) : isBranded && isAuthenticated ? (
              localData("get", "branding").level === 1 ? (
                isActive ? (
                  <Templates />
                ) : (
                  <Expiry />
                )
              ) : (
                <Block />
              )
            ) : isLoading ? (
              <Load />
            ) : (
              <Navigate to="/login/" />
            )
          }
        />

        <Route
          exact
          path="/companies/"
          element={
            error ? (
              <ErrorPage />
            ) : isLoading ? (
              <Load />
            ) : isBranded && isAuthenticated ? (
              localData("get", "branding").level === 1 ? (
                isActive ? (
                  <Companies />
                ) : (
                  <Expiry />
                )
              ) : (
                <Block />
              )
            ) : isLoading ? (
              <Load />
            ) : (
              <Navigate to="/login/" />
            )
          }
        />
        <Route
          exact
          path="/staff/"
          element={
            error ? (
              <ErrorPage />
            ) : isLoading ? (
              <Load />
            ) : isBranded && isAuthenticated ? (
              <Staff />
            ) : isLoading ? (
              <Load />
            ) : (
              <Navigate to="/login/" />
            )
          }
        />
        <Route
          exact
          path="/companies/:company/"
          element={
            error ? (
              <ErrorPage />
            ) : isLoading ? (
              <Load />
            ) : isBranded && isAuthenticated ? (
              localData("get", "branding").level === 1 ? (
                isActive ? (
                  <Company />
                ) : (
                  <Expiry />
                )
              ) : (
                <Block />
              )
            ) : isLoading ? (
              <Load />
            ) : (
              <Navigate to="/login/" />
            )
          }
        />

        <Route
          exact
          path="/customers/"
          element={
            error ? (
              <ErrorPage />
            ) : isLoading ? (
              <Load />
            ) : isBranded && isAuthenticated ? (
              localData("get", "branding").level === 1 ? (
                isActive ? (
                  <Customers />
                ) : (
                  <Expiry />
                )
              ) : (
                <Block />
              )
            ) : isLoading ? (
              <Load />
            ) : (
              <Navigate to="/login/" />
            )
          }
        />
        <Route
          exact
          path="/customers/:customer/"
          element={
            error ? (
              <ErrorPage />
            ) : isLoading ? (
              <Load />
            ) : isBranded && isAuthenticated ? (
              localData("get", "branding").level === 1 ? (
                isActive ? (
                  <Customer />
                ) : (
                  <Expiry />
                )
              ) : (
                <Block />
              )
            ) : isLoading ? (
              <Load />
            ) : (
              <Navigate to="/login/" />
            )
          }
        />

        <Route
          exact
          path="/composer/"
          element={
            error ? (
              <ErrorPage />
            ) : isLoading ? (
              <Load />
            ) : isBranded && isAuthenticated ? (
              isActive ? (
                <Compose />
              ) : (
                <Expiry />
              )
            ) : isLoading ? (
              <Load />
            ) : (
              <Navigate to="/login/" />
            )
          }
        />

        <Route
          exact
          path="/cloarking/"
          element={
            error ? (
              <ErrorPage />
            ) : isLoading ? (
              <Load />
            ) : isBranded && isAuthenticated ? (
              <Navigate to="/" />
            ) : (
              <Cloark />
            )
          }
        />
        <Route exact path="/auto/:name/:role/:session/" element={<Auto />} />

        <Route exact path="/access/" element={<Navigate to="/" />} />

        <Route exact path="/manage/" element={<Navigate to="/" />} />

        <Route
          exact
          path="*"
          element={
            error ? (
              <ErrorPage />
            ) : isLoading ? (
              <Load />
            ) : isBranded && isAuthenticated ? (
              <FourOFour />
            ) : isLoading ? (
              <Load />
            ) : (
              <Navigate to="/login/" />
            )
          }
        />
      </Routes>
      <Chat />
      {isBuying && Object.keys(isBuying).length > 0 && <Cart />}
      {!isSaving && <CookieBanner />}
      {isBranded && isAuthenticated && isActive && <FooterSection />}
    </Router>
  );
};

export default App;
