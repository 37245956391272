import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Top from "../../includes/topping";
import { Link, useParams } from "react-router-dom";
import {
  localData,
  runData,
} from "../../../jsx/backend";
import Loader from "../../includes/loader";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  faBars,
  faChartLine,
  faChevronCircleUp,
  faCogs,
  faFolderPlus,
  faPencilAlt,
  faPlusCircle,
  faPlusSquare,
  faSearch,
  faSortDown,
  faSortUp,
  faTimes,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import getIcon from "../../includes/icons";
import Select from "react-select";

Modal.setAppElement("#root");

const Package = () => {
  const [categories, setFolders] = useState([]);
  const [packageName, setPackage] = useState("Package");
  const [loaded, setLoaded] = useState(false);
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [running, setRunning] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [report, setReporting] = useState(false);

  const [creator, setCreator] = useState(false);
  const [newTitle, setTitle] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(12);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const [stats, setStats] = useState(false);

  const currentDate = new Date();
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );

  const [startDate, setStartDate] = useState(firstDayOfMonth);
  const [endDate, setEndDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const fetchOptions = (inputValue) => {
    getProfiles();
  };


  const handleSelectChange = (option) => {
    if (option) {
      const optionParts = {
        'label': option.label,
        'value': option.value,
      }
      setRunning(true);
      localData('save', 'profile', optionParts);
      setRunning(false);
    } else {
      localData('remove', 'profile');
      setRunning(true);
      setRunning(false);
    }
  };

  //const [data, setData] = useState([]);
  const data = [
    { date: '2023-11-07', Users: 4000, Errors: 2400, Clones: 2400, Downloads: 4000 },
    { date: '2023-11-08', Users: 3000, Errors: 1398, Clones: 2210, Downloads: 3000 },
    { date: '2023-11-09', Users: 2000, Errors: 9800, Clones: 2290, Downloads: 2000 },
    { date: '2023-11-10', Users: 2780, Errors: 3908, Clones: 2000, Downloads: 2780 },
    { date: '2023-11-11', Users: 1890, Errors: 4800, Clones: 2181, Downloads: 1890 },
    { date: '2023-11-12', Users: 2390, Errors: 3800, Clones: 2500, Downloads: 2390 },
    { date: '2023-11-13', Users: 3490, Errors: 4300, Clones: 2100, Downloads: 3490 },
    { date: '2023-11-14', Users: 4000, Errors: 2400, Clones: 2400, Downloads: 4000 },
    { date: '2023-11-15', Users: 3000, Errors: 1398, Clones: 2210, Downloads: 3000 },
    { date: '2023-11-16', Users: 2000, Errors: 9800, Clones: 2290, Downloads: 2000 },
    { date: '2023-11-17', Users: 2780, Errors: 3908, Clones: 2000, Downloads: 2780 },
    { date: '2023-11-18', Users: 1890, Errors: 4800, Clones: 2181, Downloads: 1890 },
    { date: '2023-11-19', Users: 2390, Errors: 3800, Clones: 2500, Downloads: 2390 },
    { date: '2023-11-20', Users: 3490, Errors: 4300, Clones: 2100, Downloads: 3490 },
    { date: '2023-11-21', Users: 4000, Errors: 2400, Clones: 2400, Downloads: 4000 },
  ];

  const filteredData = data.filter((item) => {
    const itemDate = new Date(item.date);
    return itemDate >= startDate && itemDate <= endDate;
  });

  const openEditModal = (item) => {
    setSelectedItem(item);
    setEditModalIsOpen(true);
  };

  const closeEditModal = () => {
    setSelectedItem(null);
    setEditModalIsOpen(false);
  };

  const openDeleteModal = (item) => {
    setSelectedItem(item);
    setDeleteModalIsOpen(true);
  };

  const closeDeleteModal = () => {
    setSelectedItem(null);
    setDeleteModalIsOpen(false);
  };

  const listFolders = async () => {
    const data = {};
    const responseData = await runData(
      data,
      `/query/policies/${params.package}/`
    );
    setLoading(false);
    setError(false);
    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          setFolders(responseData.response.data.categories);
          setPackage(responseData.response.data.title);
          setLoaded(true);
          break;

        default:
          setMessage(responseData.response.data.message);
          setError(true);
      }
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setError(true);
    } else {
      setMessage("An error occured while processing your request.");
      setError(true);
    }
  };

  const handleEdit = async (newTitle) => {
    closeEditModal();
    setRunning(true);
    const data = {
      title: newTitle,
    };
    const responseData = await runData(
      data,
      `/query/policies/${params.package}/${selectedItem.reference}/edit/`
    );
    setRunning(false);
    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          listFolders();
          setMessage(
            <span>
              Your edit has been successful!{" "}
              <Link
                to={`/policies/${params.package}/${selectedItem.reference}/`}
              >
                Click here to manage category policies.
              </Link>
            </span>
          );
          setReporting(true);
          break;

        default:
          setMessage(responseData.response.data.message);
          setReporting(true);
      }
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setReporting(true);
    } else {
      setMessage("An error occured while processing your request.");
      setReporting(true);
    }
  };

  const handleDelete = async () => {
    closeDeleteModal();
    setRunning(true);
    const data = {};
    const responseData = await runData(
      data,
      `/query/policies/${params.package}/${selectedItem.reference}/delete/`
    );
    setRunning(false);
    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          listFolders();
          setMessage("You have successfully deleted the selected category.");
          setReporting(true);
          break;

        default:
          setMessage(responseData.response.data.message);
          setReporting(true);
      }
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setReporting(true);
    } else {
      setMessage("An error occured while processing your request.");
      setReporting(true);
    }
  };

  const getProfiles = async () => {
    const data = {};

    const responseData = await  runData(
    data,
    `/query/policies/${params.package}/unspecified/unspecified/subscriptions/`
    );

    if (responseData.response) {
    switch (responseData.response.data.code) {
    case 200:
    setOptions(responseData.response.data.options);
    break;
    default:
    setMessage(responseData.response.message);
    setReporting(true);
    }
    } else if (responseData.error) {
    setMessage(responseData.error.message);
    setReporting(true);
    } else {
    setMessage("An error occured while processing your request.");
    setReporting(true);
    }
  }

  useEffect(() => {
    listFolders();
    getProfiles();
  }, []);

  const handleReload = () => {
    listFolders();
  };

  const createFolder = async (event) => {
    event.preventDefault();
    setCreator(false);
    setRunning(true);
    const data = {
      title: newTitle,
    };
    const responseData = await runData(
      data,
      `/query/policies/${params.package}/create/category/`
    );
    setRunning(false);
    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          setMessage(
            <span>
              New category has been created!{" "}
              <Link
                to={`/policies/${params.package}/${responseData.response.data.reference}/`}
              >
                Click here to manage category policies.
              </Link>
            </span>
          );
          listFolders();
          setTitle("");
          setReporting(true);
          break;

        default:
          setMessage(responseData.response.data.message);
          setReporting(true);
      }
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setReporting(true);
    } else {
      setMessage("An error occured while processing your request.");
      setReporting(true);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  let filteredAndSortedFolders = [];
  if (Array.isArray(categories)) {
    filteredAndSortedFolders = categories
      .filter((ifolder) =>
        ifolder.title.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .sort((a, b) => {
        if (sortOrder === "asc") {
          return a.title > b.title ? 1 : -1;
        } else {
          return a.title < b.title ? 1 : -1;
        }
      });
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  let currentItems = filteredAndSortedFolders.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const pageNumbers = [];
  for (
    let i = 1;
    i <= Math.ceil(filteredAndSortedFolders.length / itemsPerPage);
    i++
  ) {
    pageNumbers.push(i);
  }

  const handleClick = (event) => {
    setCurrentPage(Number(event.target.id));
  };

  const handleSort = () => {
    if (sortOrder === "asc") {
      setSortOrder("desc");
    } else {
      setSortOrder("asc");
    }
  };

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      zIndex: 10000,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "transparent",
      border: "none",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minWidth: "30VW",
    },
  };

  return (
    <div
      className='main mm-container mm-row-padding"'
      style={{ minHeight: "95VH", wordWrap: "break-word" }}
    >
      <Helmet>
        <title>
          {packageName} - {localData("get", "branding").company || "Portal"}
        </title>
      </Helmet>

      <div className="mm-threequarter mm-padding">
        {loading && !error && (
          <div style={{ marginTop: "100px" }}>
            {" "}
            <Loader />{" "}
          </div>
        )}

        {error && !loading && (
          <div className="mm-display-container" style={{ height: "50VH" }}>
            <div className="mm-display-middle mm-center">
              {message ? (
                <h4>{message}</h4>
              ) : (
                <h4>Processing your request was not successful!</h4>
              )}
              <br />
              <button
                onClick={handleReload}
                className="mm-btn my-color"
                style={{ borderRadius: "30px" }}
              >
                RELOAD
              </button>
            </div>
          </div>
        )}

        {loaded &&
          !error &&
          !loading &&
          (categories && categories.length > 0 ? (
            <div className="mm-section">
              <div
                className="mm-container"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "10px",
                  backgroundColor: "#f5f5f5",
                  borderRadius: "5px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexGrow: 1,
                    marginRight: "10px",
                    alignItems: "center",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faSearch}
                    style={{ marginRight: "10px", color: "#888" }}
                  />
                  <input
                    className="mm-input"
                    style={{
                      width: "100%",
                      padding: "10px",
                      borderRadius: "5px",
                      border: "1px solid #ddd",
                      outline: "none",
                    }}
                    type="text"
                    placeholder={`Search from ${categories.length} categories...`}
                    value={searchTerm}
                    onChange={handleSearch}
                  />
                  {searchTerm && (
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      style={{
                        marginLeft: "10px",
                        color: "#888",
                        cursor: "pointer",
                      }}
                      onClick={() => setSearchTerm("")}
                    />
                  )}
                </div>
                <button
                  onClick={handleSort}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "10px 20px",
                    backgroundColor:
                      localData("get", "branding").shade || "#eeeeee",
                    color: "#fff",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  {sortOrder === "asc" ? (
                    <FontAwesomeIcon
                      icon={faSortDown}
                      style={{ marginRight: "10px" }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faSortUp}
                      style={{ marginRight: "10px" }}
                    />
                  )}
                  Sorting
                </button>
              </div>
              <br />
              {pageNumbers.length > 1 && (
                <div className="pagination">
                  {currentPage > 1 && (
                    <button onClick={() => setCurrentPage(currentPage - 1)}>
                      Back
                    </button>
                  )}
                  {pageNumbers.map((number) => (
                    <button
                      key={number}
                      id={number}
                      onClick={handleClick}
                      className={currentPage === number ? "active" : ""}
                    >
                      {number}
                    </button>
                  ))}
                  {currentPage < pageNumbers.length && (
                    <button onClick={() => setCurrentPage(currentPage + 1)}>
                      Next
                    </button>
                  )}
                </div>
              )}
              <br />
              <div className="category-container">
                {currentItems.map((ifolder) => (
                  <div className="category zoom-on-hover" key={ifolder.reference}>
                    <ContextMenuTrigger id={ifolder.reference}>
                      <Link
                        to={`/policies/${params.package}/${ifolder.reference}/`}
                        style={{ textDecoration: "none" }}
                        title="Category"
                        className="parent-img"
                      >
                       <div className="mm-padding mm-margin" style={{height: "200px", width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>{getIcon('category')}</div>

                        <div className="category-name">{ifolder.title}</div>

                      </Link>
                    </ContextMenuTrigger>
                    <ContextMenu
                      className="mm-card my-shade mm-padding mm-text-white"
                      style={{ borderRadius: "10px", zIndex: "99999" }}
                      id={ifolder.reference}
                    >
                      <Link
                        to={`/policies/${ifolder.reference}/`}
                        style={{ textDecoration: "none" }}
                        title="Category"
                      >
                        <MenuItem>Open</MenuItem>
                      </Link>
                      <hr />
                      <span
                        onClick={() => openEditModal(ifolder)}
                        style={{ cursor: "pointer" }}
                        title="Edit"
                      >
                        <MenuItem>Edit</MenuItem>
                      </span>
                      <hr />
                      <span style={{ cursor: "pointer" }} title="Delete">
                        <MenuItem onClick={() => openDeleteModal(ifolder)}>
                          Delete
                        </MenuItem>
                      </span>
                    </ContextMenu>
                    <div className="dropdown">
                      <span className="dropbtn" style={{ padding: "20px" }}>
                        <FontAwesomeIcon icon={faBars} />
                      </span>
                      <div
                        className="dropdown-content mm-card my-shade mm-padding mm-text-white"
                        style={{ borderRadius: "10px" }}
                        id={ifolder.reference}
                      >
                        <Link
                          to={`/policies/${ifolder.reference}/`}
                          style={{ textDecoration: "none" }}
                          title="Category"
                        >
                          Open
                        </Link>
                        <hr />
                        <span
                          onClick={() => openEditModal(ifolder)}
                          style={{ cursor: "pointer" }}
                          title="Edit"
                        >
                          Edit
                        </span>
                        <hr />
                        <span
                          onClick={() => openDeleteModal(ifolder)}
                          style={{ cursor: "pointer" }}
                          title="Delete"
                        >
                          Delete
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
                <Modal
                  isOpen={editModalIsOpen}
                  onRequestClose={closeEditModal}
                  style={customStyles}
                >
                  <div
                    className="mm-center"
                    style={{
                      width: "100%",
                      backgroundColor: "#f8f9fa",
                      padding: "20px",
                      borderRadius: "10px",
                      boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                    }}
                  >
                    <h4>Edit Title: {selectedItem?.title}</h4>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleEdit(e.target.elements.title.value);
                      }}
                      style={{ width: "100%", marginTop: "30px" }}
                    >
                      <input
                        name="title"
                        placeholder="Category title..."
                        type="text"
                        className="mm-input mm-border"
                        style={{
                          width: "100%",
                          boxSizing: "border-box",
                          display: "block",
                          borderRadius: "10px",
                          outline: "none",
                        }}
                        defaultValue={selectedItem?.title}
                        required
                      />
                      <br />
                      <button
                        onClick={closeEditModal}
                        className="mm-left"
                        type="button"
                        style={{
                          backgroundColor: "red",
                          width: "48%",
                          color: "#fff",
                          padding: "10px",
                          border: "none",
                          borderRadius: "5px",
                          cursor: "pointer",
                          transition: "background-color 0.3s ease",
                        }}
                      >
                        CANCEL
                      </button>
                      <button
                        className="mm-right"
                        type="submit"
                        style={{
                          backgroundColor: "green",
                          width: "48%",
                          color: "#fff",
                          padding: "10px",
                          border: "none",
                          borderRadius: "5px",
                          cursor: "pointer",
                          transition: "background-color 0.3s ease",
                        }}
                      >
                        UPDATE
                      </button>
                    </form>
                  </div>
                </Modal>

                <Modal
                  isOpen={deleteModalIsOpen}
                  onRequestClose={closeDeleteModal}
                  style={customStyles}
                >
                  <div
                    className="mm-center"
                    style={{
                      width: "100%",
                      backgroundColor: "#f8f9fa",
                      padding: "20px",
                      borderRadius: "10px",
                      boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                    }}
                  >
                    <h4>Delete Category: {selectedItem?.title}</h4>
                    <br />
                    <div className="mm-container" style={{ width: "100%" }}>
                      <p>Are you sure you want to delete this item?</p>
                      <br />
                      <button
                        onClick={closeDeleteModal}
                        className="mm-left"
                        type="button"
                        style={{
                          backgroundColor: "red",
                          width: "48%",
                          color: "#fff",
                          padding: "10px",
                          border: "none",
                          borderRadius: "5px",
                          cursor: "pointer",
                          transition: "background-color 0.3s ease",
                        }}
                      >
                        CANCEL
                      </button>
                      <button
                        onClick={handleDelete}
                        className="mm-right my-color"
                        type="submit"
                        style={{
                          width: "48%",
                          color: "#fff",
                          padding: "10px",
                          border: "none",
                          borderRadius: "5px",
                          cursor: "pointer",
                          transition: "background-color 0.3s ease",
                        }}
                      >
                        DELETE
                      </button>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
          ) : (
            <h4 className="mm-center" style={{ paddingTop: "30VH" }}>
              You have no categories!
              <br />
              <button
                onClick={() => setCreator(true)}
                className="mm-small my-color mm-text-white zoom-on-hover"
                style={{
                  padding: "10px 20px",
                  borderRadius: "5px",
                  border: "none",
                  cursor: "pointer",
                  marginTop: "30px",
                }}
              >
                <FontAwesomeIcon icon={faPlusCircle} /> CREATE FIRST CATEGORY
              </button>
            </h4>
          ))}
      </div>

      <div
        className="mm-quarter mm-padding"
        style={{ top: "75px", position: "sticky" }}
      >
        <div
          className="mm-card mm-padding in-sidenav"
          style={{ borderRadius: "10px", width: "100%" }}
        >
          <h4 className="mm-text-black">Package Options
          <span
              className="mm-right"
              style={{ textDecoration: "none" }}
            >
              <FontAwesomeIcon icon={faCogs} />
            </span>
            </h4>
            <hr />
            <span style={{ marginBottom: "30px" }}>
            <Select
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? "grey" : "grey",
                  outline: "none",
                  boxShadow: "none",
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isSelected
                    ? localData("get", "branding").shade || "green"
                    : state.isFocused
                    ? "lightgray"
                    : "white",
                  color: "black",
                  cursor: "pointer",
                }),
              }}
              isClearable
              defaultValue={localData("get", "profile") || null}
              options={options}
              onChange={handleSelectChange}
              onInputChange={fetchOptions}
              placeholder='Choose a profile...'
              isLoading={isLoading}
              noOptionsMessage={() => "No subscriptions found"}
            />
          </span>
          <br />
          <section className="menu-wrapper" onClick={() => setCreator(true)}>
            <span>
              <FontAwesomeIcon icon={faFolderPlus} color="grey" /> Category
            </span>
            <FontAwesomeIcon icon={faPencilAlt} />
          </section>
          <br />
          <section className="menu-wrapper" onClick={() => setStats(true)}>
            <span>
              <FontAwesomeIcon icon={faChartLine} color="orange" /> Statistics
            </span>
            <FontAwesomeIcon icon={faPlusSquare} />
          </section>
            <hr />
        
         <Link to={`/policies/`}>
                <button
                  className="mm-small my-color zoom-on-hover mm-text-white"
                  style={{
                    padding: "10px 20px",
                    borderRadius: "5px",
                    border: "none",
                    cursor: "pointer",
                    width: "100%",
                  }}
                >
                  GO ONE LEVEL UP <FontAwesomeIcon icon={faChevronCircleUp} />
                </button>
            </Link>
          <br />
        </div>
      </div>

      <Modal
        isOpen={stats}
        onRequestClose={() => setStats(false)}
        style={customStyles}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
            <div
              className="mm-light-grey mm-padding"
              style={{ marginBottom: "30px" }}
            >
              <div className="mm-container">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                  }}
                >
                  <div style={{ position: "relative", width: "45%" }}>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      className="date-picker"
                    />
                    <label for="startDate" className="date-label">
                      Start Date
                    </label>
                  </div>
                  <div style={{ position: "relative", width: "45%" }}>
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      className="date-picker"
                    />
                    <label for="endDate" className="date-label">
                      End Date
                    </label>
                  </div>
                </div>
                <ResponsiveContainer width="100%" height={400}>
                  <LineChart data={filteredData}>
                    <CartesianGrid strokeDasharray="1 1" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line
                      type="monotone"
                      dataKey="Users"
                      stroke="#8884d8"
                      activeDot={{ r: 8 }}
                    />
                    <Line type="monotone" dataKey="Errors" stroke="#82ca9d" />
                    <Line type="monotone" dataKey="Clones" stroke="#ffc658" />
                    <Line
                      type="monotone"
                      dataKey="Downloads"
                      stroke="#ff9933"
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
<h4 >Package: { packageName }</h4>
<br />
<button onClick={() => setStats(false)}
                  className="mm-small my-color zoom-on-hover mm-text-white"
                  style={{
                    padding: "10px 20px",
                    borderRadius: "5px",
                    border: "none",
                    cursor: "pointer",
                    width: "100%",
                  }}
                >
                  CLOSE STATISTICS
                </button>
</div>
</Modal>

      <Modal
        isOpen={creator}
        onRequestClose={() => setCreator(false)}
        style={customStyles}
      >
        <form
          onSubmit={createFolder}
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <h4
            style={{
              textAlign: "center",
              color: "#343a40",
              marginBottom: "20px",
            }}
          >
            Create Category
          </h4>
          <input
            type="text"
            id="newtitle"
            name="newtitle"
            className="mm-input"
            style={{
              width: "100%",
              boxSizing: "border-box",
              display: "block",
              borderRadius: "10px",
              outline: "none",
              padding: "10px",
              fontSize: "16px",
              border: "1px solid #ced4da",
            }}
            placeholder="New category title..."
            value={newTitle}
            onChange={(event) => setTitle(event.target.value)}
            required
          />
          <br />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
            }}
          >
            <button
              onClick={() => setCreator(false)}
              className="mm-left"
              type="button"
              style={{
                backgroundColor:
                  localData("get", "branding").shade || "#eeeeee",
                width: "48%",
                color: "#fff",
                padding: "10px",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                transition: "background-color 0.3s ease",
                fontSize: "16px",
              }}
            >
              CANCEL
            </button>
            <button
              className="mm-right"
              type="submit"
              style={{
                backgroundColor:
                  localData("get", "branding").color || "#eeeeee",
                width: "48%",
                color: "#fff",
                padding: "10px",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                transition: "background-color 0.3s ease",
                fontSize: "16px",
              }}
            >
              CREATE
            </button>
          </div>
        </form>
      </Modal>
      <Modal isOpen={running} style={customStyles}>
        <div
          className="mm-center"
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <h4 style={{ color: "#343a40" }}>Please wait...</h4>
          <br />
          <Loader />
        </div>
      </Modal>


      <Modal
        isOpen={report}
        onRequestClose={() => setReporting(false)}
        style={customStyles}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <h4>
            Message{" "}
            <span
              onClick={() => setReporting(false)}
              title="Close"
              className="mm-right"
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={faTimes} color="red" />
            </span>
          </h4>

          <hr />

          <p className="mm-center">{message}</p>

          <br />
          <button
            className="mm-btn mm-red"
            onClick={() => setReporting(false)}
            style={{ borderRadius: "30px", width: "100%" }}
          >
            CLOSE
          </button>
        </div>
      </Modal>

      <Top />
    </div>
  );
};

export default Package;