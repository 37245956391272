import React, { useState, useEffect, useRef } from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { Helmet } from "react-helmet";
import Top from "../../includes/topping";
import { Link, useParams } from "react-router-dom";
import { localData, runData } from "../../../jsx/backend";
import Loader from "../../includes/loader";
import moment from "moment";
import { saveAs } from "file-saver";
import { slugify } from "../../includes/cleaning";
import { unescape } from "validator";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faFilePdf,
  faTimes,
  faBars,
  faChevronCircleUp,
  faDownload,
  faDotCircle,
  faToggleOn,
  faToggleOff,
  faPlusSquare,
  faChartLine,
  faFileArchive,
  faBoxOpen,
  faCogs,
  faFile,
  faCloudUploadAlt,
  faSave,
  faShieldBlank,
  faMinus,
  faTrashAlt,
  faEraser,
  faHistory,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import BundledEditor from "../../includes/editor";

Modal.setAppElement("#root");

const PolicyEditor = () => {
  const params = useParams();
  const [newTitle, setNewTitle] = useState("");
  const [policy, setPolicy] = useState("");
  const [originalDoc, setOridoc] = useState("");
  const [policyTitle, setPolicyTitle] = useState("");
  const [isActive, setIsActive] = useState(1);
  const [isCleared, setIsCleared] = useState(false);
  const [titling, setTitling] = useState(false);
  const [date, setDate] = useState("0");
  const [template, setTemplate] = useState(null);
  const [lastExecuted, setLastExecuted] = useState(0);
  const editorRef = useRef(null);

  const [category, setFolder] = useState("Category");
  const [packageName, setPackageName] = useState("Package");
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const [loading, setLoading] = useState(true);
  const [running, setRunning] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [report, setReporting] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [isOn, setIsOn] = useState(1);
  const [stats, setStats] = useState(false);

  const currentDate = new Date();
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );

  const [startDate, setStartDate] = useState(firstDayOfMonth);
  const [endDate, setEndDate] = useState(new Date());

  //const [data, setData] = useState([]);
  const data = [];

  const filteredData = data.filter((item) => {
    const itemDate = new Date(item.date);
    return itemDate >= startDate && itemDate <= endDate;
  });

  const downloadPDF = async () => {
    setRunning(true);
    const data = {};

    const responseData = await runData(
      data,
      `/query/policies/${params.package}/${params.category}/${params.policy}/download/pdf/`,
      "blob"
    );
    setRunning(false);
    if (responseData.response && responseData.response.status === 200) {
      setMessage("Your download has been successful!");
      const filename = slugify(policyTitle);
      const blob = new Blob([responseData.response.data], {
        type: responseData.response.headers["content-type"],
      });
      saveAs(blob, filename || "download");
      setReporting(true);
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setReporting(true);
    } else {
      setMessage("An error occured while processing your request.");
      setReporting(true);
    }
  };

  const handleClick = () => {
    if (editorRef.current) {
      editorRef.current.setContent(originalDoc);
    }
  };

  const viewPolicy = async () => {
    setLoading(true);
    const data = {};
    const responseData = await runData(
      data,
      `/query/policies/${params.package}/${params.category}/${params.policy}/current/`
    );
    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          setPackageName(responseData.response.data.package);
          setFolder(responseData.response.data.category);
          setPolicyTitle(responseData.response.data.title);
          setNewTitle(responseData.response.data.title);
          setPolicy(unescape(responseData.response.data.details));
          setOridoc(unescape(responseData.response.data.actual));
          setDate(responseData.response.data.date);
          setIsOn(responseData.response.data.autosave);
          setTemplate(unescape(responseData.response.data.template));
          setLoading(false);
          setLoaded(true);
          break;
        default:
          setMessage(responseData.response.data.message);
          setError(true);
      }
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setError(true);
    } else {
      setMessage("An error occured while processing your request.");
      setError(true);
    }
  };

  useEffect(() => {
    viewPolicy();
  }, []);

  const handleStatus = async () => {
    setRunning(true);
    const data = {
      status: isActive,
    };
    const responseData = await runData(
      data,
      `/query/policies/${params.package}/${params.category}/${params.policy}/status/`
    );
    setRunning(false);
    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          setIsActive(responseData.response.data.status);
          setReporting(true);
          setMessage("Your request has been processed succesfully!");
          break;

        default:
          setMessage(responseData.response.data.message);
          setError(true);
      }
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setReporting(true);
    } else {
      setMessage("An error occured while processing your request.");
      setReporting(true);
    }
  };

  const handleChange = async () => {
    if (editorRef.current) {
      const currentTime = Date.now();
      if (currentTime - lastExecuted >= 30000) {
        const data = {
          details: editorRef.current.getContent(),
        };
        const responseData = await runData(
          data,
          `/query/policies/${params.package}/${params.category}/${params.policy}/autosave/`
        );

        if (responseData.response) {
          switch (responseData.response.data.code) {
            case 200:
              setIsCleared(false);
              break;
            default:
              setMessage(responseData.response.data.message);
              setReporting(true);
          }
        } else if (responseData.error) {
          setMessage(responseData.error.message);
          setReporting(true);
        } else {
          setMessage("An error occured while processing your request.");
          setReporting(true);
        }
        setLastExecuted(currentTime);
      }
    }
  };

  const save = async () => {
    if (editorRef.current) {
      setRunning(true);
      const data = {
        details: editorRef.current.getContent(),
      };
      const responseData = await runData(
        data,
        `/query/policies/${params.package}/${params.category}/${params.policy}/save/`
      );
      setRunning(false);
      if (responseData.response) {
        switch (responseData.response.data.code) {
          case 200:
            setIsCleared(false);
            setDate(responseData.response.data.date);
            setMessage("Your policy has been updated succesfully!");
            setReporting(true);
            break;
          default:
            setReporting(true);
            setMessage(responseData.response.data.message);
        }
      } else if (responseData.response.error) {
        setReporting(false);
        setMessage(responseData.response.error);
      } else {
        setMessage("An error occured while processing your request.");
        setReporting(true);
      }
    }
  };

  const handleCleared = async () => {
    setRunning(true);
    const data = {};
    const responseData = await runData(
      data,
      `/query/policies/${params.package}/${params.category}/${params.policy}/clear/`
    );
    setRunning(false);
    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          setMessage(
            "Your autosave has been cleared succesfully! Use Restore Editor from templates to restore editor content."
          );
          setReporting(true);
          break;
        default:
          setMessage(responseData.response.data.message);
          setReporting(true);
      }
    } else if (responseData.response.error) {
      setError(responseData.response.error);
      setReporting(true);
    } else {
      setMessage("An error occured while processing your request.");
      setReporting(true);
    }
  };

  const handleAuto = async () => {
    setRunning(true);
    const data = {
      autosave: isOn,
    };
    const responseData = await runData(data, `/query/settings/autosave/`);
    setRunning(false);
    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          setIsOn(responseData.response.data.autosave);
          setReporting(true);
          setMessage("Your request has been processed succesfully!");
          break;

        default:
          setMessage(responseData.response.data.message);
          setError(true);
      }
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setError(true);
    } else {
      setMessage("An error occured while processing your request.");
      setError(true);
    }
  };

  const handleReload = () => {
    viewPolicy();
  };

  const handleTitling = () => {
    setTitling(!titling);
  };

  const handlenewTitle = async (event) => {
    event.preventDefault();
    setRunning(true);
    setDropdownVisible(false);

    const data = {
      title: newTitle,
    };
    const responseData = await runData(
      data,
      `/query/policies/${params.package}/${params.category}/${params.policy}/edit/title/`
    );
    setRunning(false);
    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          setPolicyTitle(newTitle);
          setTitling(!titling);
          setMessage("Your title has been updated succesfully!");
          setReporting(true);
          break;
        default:
          setMessage(responseData.response.data.message);
          setError(true);
      }
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setError(true);
    } else {
      setMessage("An error occured while processing your request.");
      setError(true);
    }
  };

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      zIndex: 10000,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "transparent",
      border: "none",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minWidth: "30VW",
    },
  };

  return (
    <div
      className='main mm-container mm-row-padding"'
      style={{ minHeight: "95VH", wordWrap: "break-word" }}
    >
      <Helmet>
        <title>
          Edit:: {policyTitle} -{" "}
          {localData("get", "branding").company || "Portal"}
        </title>
      </Helmet>

      <div className="mm-threequarter mm-padding">
        {loading && !error && (
          <div style={{ marginTop: "100px" }}>
            {" "}
            <Loader />{" "}
          </div>
        )}

        {error && !loading && (
          <div className="mm-display-container" style={{ height: "50VH" }}>
            <div className="mm-display-middle mm-center">
              {message ? (
                <h4>{message}</h4>
              ) : (
                <h4>Processing your request was not successful!</h4>
              )}
              <br />
              <button
                onClick={handleReload}
                className="mm-btn my-color"
                style={{ borderRadius: "30px" }}
              >
                RELOAD
              </button>
            </div>
          </div>
        )}

        {loaded && !error && !loading && (
          <div className="mm-panel">
            {titling ? (
              <form
                className="mm-container mm-border mm-padding"
                style={{ width: "100%" }}
                onSubmit={handlenewTitle}
              >
                <input
                  type="text"
                  id="newtitle"
                  name="newtitle"
                  placeholder="Policy new title..."
                  value={newTitle}
                  onChange={(event) => setNewTitle(event.target.value)}
                  style={{
                    width: "100%",
                    padding: "10px",
                    marginBottom: "20px",
                    border: "none",
                    borderRadius: "5px",
                    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
                  }}
                  title="Update policy title!"
                  required
                />
                <br />
                <button
                  className="mm-left"
                  type="submit"
                  style={{
                    backgroundColor: "green",
                    width: "48%",
                    color: "#fff",
                    padding: "10px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                  }}
                >
                  UPDATE
                </button>

                <button
                  onClick={handleTitling}
                  className="mm-right"
                  type="button"
                  style={{
                    backgroundColor: "red",
                    width: "48%",
                    color: "#fff",
                    padding: "10px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                  }}
                >
                  CANCEL
                </button>
              </form>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "10px",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "30px",
                  }}
                >
                  <h4 style={{ flexWrap: "wrap" }}>{policyTitle}</h4>
                </div>
                <div>
                  <div className="dropdownList">
                    {!dropdownVisible ? (
                      <FontAwesomeIcon
                        onClick={() => setDropdownVisible(!dropdownVisible)}
                        className="dropbtn"
                        icon={faBars}
                        size="2x"
                        style={{ cursor: "pointer" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        onClick={() => setDropdownVisible(!dropdownVisible)}
                        className="dropbtn"
                        icon={faTimes}
                        size="2x"
                        style={{ cursor: "pointer" }}
                      />
                    )}
                    {dropdownVisible && (
                      <div
                        className="dropdown-content mm-card my-shade mm-padding"
                        style={{ borderRadius: "10px" }}
                      >
                        <Link
                          onClick={() => setDropdownVisible(!dropdownVisible)}
                          to={`/policies/${params.package}/${params.category}/${params.policy}/forms/`}
                          style={{ textDecoration: "none" }}
                          title="Manage Forms"
                        >
                          Forms
                        </Link>
                        <hr />
                        <Link
                          onClick={() => setDropdownVisible(!dropdownVisible)}
                          to={`/policies/${params.package}/${params.category}/${params.policy}/`}
                          style={{ textDecoration: "none" }}
                          title="Preview Policy"
                        >
                          Preview
                        </Link>
                        <hr />
                        <span
                          title="Download PDF"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setDropdownVisible(!dropdownVisible);
                            downloadPDF();
                          }}
                        >
                          Download
                        </span>
                        <hr />
                        <span
                          title="Edit Title"
                          style={{ cursor: "pointer" }}
                          onClick={handleTitling}
                        >
                          Edit Title
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            <br />

            <BundledEditor
              onInit={(evt, editor) => (editorRef.current = editor)}
              initialValue={policy}
              onEditorChange={handleChange}
              init={{
                branding: false,
                height: '75VH',
                menubar: true,
                plugins: [
                  "advlist",
                  "autolink",
                  "link",
                  "image",
                  "lists",
                  "charmap",
                  "preview",
                  "anchor",
                  "pagebreak",
                  "searchreplace",
                  "wordcount",
                  "visualblocks",
                  "visualchars",
                  "code",
                  "fullscreen",
                  "insertdatetime",
                  "media",
                  "table",
                  "emoticons",
                  "accordion",
                  "autosave",
                ],
                toolbar:
                  "undo redo | styles | fontfamily | fontsize | bold italic | alignleft aligncenter alignright alignjustify | " +
                  "bullist numlist outdent indent | link image | print preview media fullscreen | " +
                  "forecolor backcolor emoticons accordion restoredraft searchreplace",
                content_style:
                  "body { font-family:Verdana,sans-serif; font-size:12px }",
                file_picker_types: "image",
                file_picker_callback: function (cb, value, meta) {
                  var input = document.createElement("input");
                  input.setAttribute("type", "file");
                  input.setAttribute("accept", "image/*");
                  input.onchange = function () {
                    var file = this.files[0];
                    var reader = new FileReader();
                    reader.onload = function () {
                      cb(reader.result, { title: file.name });
                    };
                    reader.readAsDataURL(file);
                  };
                  input.click();
                },
              }}
            />
            <br />
            <button
              onClick={save}
              className="mm-btn mm-hover-grey mm-text-white"
              style={{
                width: "100%",
                borderRadius: "5px",
                background: localData("get", "branding").color || "#8b7f83",
              }}
            >
              UPDATE POLICY
            </button>
          </div>
        )}
      </div>

      <div
        className="mm-quarter mm-padding"
        style={{ top: "75px", position: "sticky" }}
      >
        <div
          className="mm-card mm-padding in-sidenav"
          style={{ borderRadius: "10px", width: "100%" }}
        >
          <h4 className="mm-text-black">
            File Options
            <span className="mm-right" style={{ textDecoration: "none" }}>
              <FontAwesomeIcon icon={faCogs} />
            </span>
          </h4>

          <hr />
          {isActive === 1 ? (
            <section className="menu-wrapper" onClick={handleStatus}>
              <span>
                <FontAwesomeIcon icon={faDotCircle} color="green" /> Activated
              </span>
              <FontAwesomeIcon icon={faToggleOff} color="red" />
            </section>
          ) : (
            <section className="menu-wrapper" onClick={handleStatus}>
              <span>
                <FontAwesomeIcon icon={faDotCircle} color="red" /> Suspended
              </span>
              <FontAwesomeIcon icon={faToggleOn} color="green" />
            </section>
          )}
          <br />
          {isOn === 1 ? (
            <section className="menu-wrapper" onClick={handleAuto}>
              <span>
                <FontAwesomeIcon icon={faDotCircle} color="green" /> Autosave:
                ON
              </span>
              <FontAwesomeIcon icon={faToggleOff} color="red" />
            </section>
          ) : (
            <section className="menu-wrapper" onClick={handleAuto}>
              <span>
                <FontAwesomeIcon icon={faDotCircle} color="red" /> Autosave: OFF
              </span>
              <FontAwesomeIcon icon={faToggleOn} color="green" />
            </section>
          )}
          <br />
          {isCleared ? (
            <section className="menu-wrapper" onClick={handleCleared}>
              <span>
                <FontAwesomeIcon icon={faCloudUploadAlt} color="green" />{" "}
                Autosave: Empty
              </span>
              <FontAwesomeIcon icon={faMinus} color="orange" />
            </section>
          ) : (
            <section className="menu-wrapper" onClick={handleCleared}>
              <span>
                <FontAwesomeIcon icon={faCloudUploadAlt} color="green" />{" "}
                Autosave: Loaded
              </span>
              <FontAwesomeIcon icon={faTrashAlt} color="red" />
            </section>
          )}
          <br />
          <section className="menu-wrapper" onClick={downloadPDF}>
            <span>
              <FontAwesomeIcon icon={faFilePdf} color="red" /> Download
            </span>
            <FontAwesomeIcon icon={faDownload} />
          </section>
          <br />
          <section className="menu-wrapper" onClick={() => setStats(true)}>
            <span>
              <FontAwesomeIcon icon={faChartLine} color="orange" /> Statistics
            </span>
            <FontAwesomeIcon icon={faPlusSquare} />
          </section>
          <br />
          <section className="menu-wrapper" onClick={handleClick}>
            <span>
              <FontAwesomeIcon icon={faEraser} color="orange" /> Remove Changes
            </span>
            <FontAwesomeIcon icon={faHistory} />
          </section>
          <br />
          <Link
            to={`/policies/${params.package}/${params.category}/${params.policy}/forms/`}
            className="menu-wrapper"
          >
            <span>
              <FontAwesomeIcon icon={faFileArchive} color="grey" /> Forms
            </span>
            <FontAwesomeIcon icon={faBoxOpen} />
          </Link>
          <br />
          <Link
            to={`/policies/${params.package}/${params.category}/${params.policy}/`}
            className="menu-wrapper"
          >
            <span>
              <FontAwesomeIcon icon={faFile} color="grey" /> Preview
            </span>
            <FontAwesomeIcon icon={faEye} />
          </Link>
          <hr />

          <Link to={`/policies/${params.package}/${params.category}/`}>
            <button
              className="mm-small my-color zoom-on-hover mm-text-white"
              style={{
                padding: "10px 20px",
                borderRadius: "5px",
                border: "none",
                cursor: "pointer",
                width: "100%",
              }}
            >
              GO ONE LEVEL UP <FontAwesomeIcon icon={faChevronCircleUp} />
            </button>
          </Link>
          <br />
        </div>
      </div>

      <Modal
        isOpen={stats}
        onRequestClose={() => setStats(false)}
        style={customStyles}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <div
            className="mm-light-grey mm-padding"
            style={{ marginBottom: "30px" }}
          >
            <div className="mm-container">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <div style={{ position: "relative", width: "45%" }}>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    className="date-picker"
                  />
                  <label for="startDate" className="date-label">
                    Start Date
                  </label>
                </div>
                <div style={{ position: "relative", width: "45%" }}>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    className="date-picker"
                  />
                  <label for="endDate" className="date-label">
                    End Date
                  </label>
                </div>
              </div>
              <ResponsiveContainer width="100%" height={400}>
                <LineChart data={filteredData}>
                  <CartesianGrid strokeDasharray="1 1" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="Users"
                    stroke="#8884d8"
                    activeDot={{ r: 8 }}
                  />
                  <Line type="monotone" dataKey="Errors" stroke="#82ca9d" />
                  <Line type="monotone" dataKey="Clones" stroke="#ffc658" />
                  <Line type="monotone" dataKey="Downloads" stroke="#ff9933" />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
          <h4>Package: {packageName}</h4>
          <p>Category: {category}</p>
          <p>
            Update: {moment(date).format("dddd Do of MMMM, YYYY - h:mm:ss a")}
          </p>
          <br />
          <button
            onClick={() => setStats(false)}
            className="mm-small my-color zoom-on-hover mm-text-white"
            style={{
              padding: "10px 20px",
              borderRadius: "5px",
              border: "none",
              cursor: "pointer",
              width: "100%",
            }}
          >
            CLOSE STATISTICS
          </button>
        </div>
      </Modal>

      <Modal isOpen={running} style={customStyles}>
        <div
          className="mm-center"
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <h4 style={{ color: "#343a40" }}>Please wait...</h4>
          <br />
          <Loader />
        </div>
      </Modal>

      <Modal
        isOpen={report}
        onRequestClose={() => setReporting(false)}
        style={customStyles}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <h4>
            Message{" "}
            <span
              onClick={() => setReporting(false)}
              title="Close"
              className="mm-right"
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={faTimes} color="red" />
            </span>
          </h4>

          <hr />

          <p className="mm-center">{message}</p>

          <br />
          <button
            className="mm-btn mm-red"
            onClick={() => setReporting(false)}
            style={{ borderRadius: "30px", width: "100%" }}
          >
            CLOSE
          </button>
        </div>
      </Modal>

      <Top />
    </div>
  );
};

export default PolicyEditor;
