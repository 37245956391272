import React from 'react';
import {Helmet} from "react-helmet";
import Top from '../includes/topping';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

const ErrorPage = () => {

return (
<div style={{minHeight: "100VH", paddingTop: "40px", wordWrap : "break-word"}}>
<Helmet>
<title>Error</title>
</Helmet>
<div className="mm-container mm-center" style={{marginTop: '20VH', paddingBottom: '5VH'}}>
      <div className="modules">
  <div title="Error">
    <div className="mm-btn module zoom-on-hover">
      <div className="icon-container">
      <FontAwesomeIcon icon={faTriangleExclamation} size='5x' color='orange' />
      </div>
      <div className="title-container">
        <p style={{color: "black"}}>ERROR!</p>
      </div>
    </div>
  </div>
</div>
</div>
<Top />
</div>
);}

export default ErrorPage;