import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Top from "../../includes/topping";
import { useParams, Link } from "react-router-dom";
import { localData, runData } from "../../../jsx/backend";
import Loader from "../../includes/loader";
import { saveAs } from "file-saver";
import { slugify } from "../../includes/cleaning";
import { unescape } from "validator";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  faFileAlt,
  faFilePdf,
  faHistory,
  faPencilAlt,
  faThList,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import {
  Card,
  Col,
  Container,
  Dropdown,
  Form,
  Pagination,
  Row,
  Table,
  Button,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

import Modal from "react-modal";
import SideBar from "../../sections/sidebar";

Modal.setAppElement("#root");

const PolicyCustomer = () => {
  const [policy, setPolicy] = useState("");
  const [policyTitle, setPolicyTitle] = useState("");
  const params = useParams();
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [running, setRunning] = useState(false);
  const [message, setMessage] = useState("");
  const [logs, setLogs] = useState(false);
  const [report, setReporting] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [stats, setStats] = useState(false);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const filteredSubPolicies = reports.filter((log) =>
    log.name.toLowerCase().includes(search.toLowerCase())
  );

  const totalPages = Math.ceil(filteredSubPolicies.length / itemsPerPage);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const currentDate = new Date();
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );

  const [startDate, setStartDate] = useState(firstDayOfMonth);
  const [endDate, setEndDate] = useState(new Date());

  const data = [];

  const filteredData = data.filter((item) => {
    const itemDate = new Date(item.date);
    return itemDate >= startDate && itemDate <= endDate;
  });

  const downloadPDF = async () => {
    setRunning(true);
    const data = {};
    const responseData = await runData(
      data,
      `/query/customer/policies/${params.package}/categories/${params.category}/${params.policy}/pdf/`,
      "blob"
    );
    setRunning(false);
    if (responseData.response && responseData.response.status === 200) {
      setMessage("Your download has been successful!");
      const filename = slugify(policyTitle);
      const blob = new Blob([responseData.response.data], {
        type: responseData.response.headers["content-type"],
      });
      saveAs(blob, filename || "download");
      setReporting(true);
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setReporting(true);
    } else {
      setMessage("An error occured while processing your request.");
      setReporting(true);
    }
  };

  const accessLog = async () => {
    setRunning(true);
    const responseData = await runData(
      data,
      `/query/customer/policies/${params.package}/categories/${params.category}/${params.policy}/logs/`
    );
    setRunning(false);
    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          setReports(responseData.response.data.reports);
          setLogs(true);
          break;
        default:
          setMessage(responseData.response.data.message);
          setReporting(true);
      }
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setReporting(true);
    } else {
      setMessage("An error occured while processing your request.");
      setReporting(true);
    }
  };
  const viewPolicy = async () => {
    const data = {};
    const responseData = await runData(
      data,
      `/query/customer/policies/${params.package}/categories/${params.category}/${params.policy}/`
    );
    setLoading(false);
    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          setPolicy(responseData.response.data.details);
          setPolicyTitle(responseData.response.data.title);
          setLoaded(true);
          break;
        default:
          setMessage(responseData.response.data.message);
          setReporting(true);
      }
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setReporting(true);
    } else {
      setMessage("An error occured while processing your request.");
      setReporting(true);
    }
  };

  useEffect(() => {
    viewPolicy();
  }, [params.policy]);

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      zIndex: 10000,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "transparent",
      border: "none",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minWidth: "30VW",
    },
  };

  return (
    <Container style={{ minHeight: "95VH", wordWrap: "break-word" }} fluid>
      <Helmet>
        <title>
          {policyTitle} - {localData("get", "branding").company || "Portal"}
        </title>
      </Helmet>

      <Row className="mt-3 mm-padding-large">
        <Col md={9}>
          <Card id="policies" className="mb-4">
            <Card.Header>
              <Row className="d-flex align-items-center justify-content-between">
                <Col md={6}>
                  <h6>You're reading: {policyTitle}</h6>
                </Col>
                <Col
                  md={6}
                  className="d-flex align-items-start justify-content-end"
                >
                  {" "}
                  <Button variant="light" onClick={downloadPDF}>
                    <FontAwesomeIcon icon={faFilePdf} color="red" /> Download
                  </Button>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <div
                style={{ padding: "20px", marginBottom: "30px" }}
                dangerouslySetInnerHTML={{ __html: unescape(policy) }}
              />
            </Card.Body>
            <Card.Footer
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              {(localData("get", "session").role === 1 ||
                localData("get", "session").role === 2) && (
                <Link
                  to={`/access/policies/${params.package}/${params.category}/${params.policy}/edit/`}
                  style={{
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                >
                  <FontAwesomeIcon icon={faPencilAlt} /> Edit Online
                </Link>
              )}
              {(localData("get", "session").role === 1 ||
                localData("get", "session").role === 2) && (
                <Button variant="light" onClick={accessLog}>
                  <FontAwesomeIcon icon={faThList} /> Access Log
                </Button>
              )}
              <Link
                to={`/access/policies/${params.package}/${params.category}/${params.policy}/forms/`}
                style={{
                  cursor: "pointer",
                  textDecoration: "none",
                }}
              >
                <FontAwesomeIcon icon={faFileAlt} /> Policy Forms
              </Link>
            </Card.Footer>
          </Card>
        </Col>
        <SideBar />
      </Row>

      <Modal
        isOpen={stats}
        onRequestClose={() => setStats(false)}
        style={customStyles}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <div
            className="mm-light-grey mm-padding"
            style={{ marginBottom: "30px" }}
          >
            <div className="mm-container">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <div style={{ position: "relative", width: "45%" }}>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    className="date-picker"
                  />
                  <label for="startDate" className="date-label">
                    Start Date
                  </label>
                </div>
                <div style={{ position: "relative", width: "45%" }}>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    className="date-picker"
                  />
                  <label for="endDate" className="date-label">
                    End Date
                  </label>
                </div>
              </div>
              <ResponsiveContainer width="100%" height={400}>
                <LineChart data={filteredData}>
                  <CartesianGrid strokeDasharray="1 1" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="Users"
                    stroke="#8884d8"
                    activeDot={{ r: 8 }}
                  />
                  <Line type="monotone" dataKey="Errors" stroke="#82ca9d" />
                  <Line type="monotone" dataKey="Clones" stroke="#ffc658" />
                  <Line type="monotone" dataKey="Downloads" stroke="#ff9933" />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
          <h4>Policy: {policyTitle}</h4>
          <br />
          <button
            onClick={() => setStats(false)}
            className="mm-small my-color zoom-on-hover mm-text-white"
            style={{
              padding: "10px 20px",
              borderRadius: "5px",
              border: "none",
              cursor: "pointer",
              width: "100%",
            }}
          >
            CLOSE STATISTICS
          </button>
        </div>
      </Modal>
      <Modal isOpen={running} style={customStyles}>
        <div
          className="mm-center"
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <h4 style={{ color: "#343a40" }}>Please wait...</h4>
          <br />
          <Loader />
        </div>
      </Modal>

      <Modal
        isOpen={report}
        onRequestClose={() => setReporting(false)}
        style={customStyles}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <h4>
            Message{" "}
            <span
              onClick={() => setReporting(false)}
              title="Close"
              className="mm-right"
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={faTimes} color="red" />
            </span>
          </h4>

          <hr />

          <p className="mm-center">{message}</p>

          <br />
          <button
            className="mm-btn mm-red"
            onClick={() => setReporting(false)}
            style={{ borderRadius: "30px", width: "100%" }}
          >
            CLOSE
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={logs}
        onRequestClose={() => setLogs(false)}
        style={customStyles}
      >
        <Card id="reports" className="mb-4" style={{ width: "100%" }}>
          <Card.Header>
            <Row className="d-flex align-items-center justify-content-between">
              <Col md={6}>
                <h6>Access Logs</h6>
              </Col>
              <Col
                md={6}
                className="d-flex align-items-start justify-content-end"
              >
                <FontAwesomeIcon
                  icon={faTimes}
                  color="red"
                  onClick={() => setLogs(false)}
                  style={{ cursor: "pointer" }}
                />
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <Form.Control
              type="text"
              className="no-outline mm-pale-green"
              style={{ borderRadius: "0px" }}
              placeholder={`Type to filter from ${filteredSubPolicies.length} items...`}
              value={search}
              onChange={handleSearchChange}
            />

            <Table className="no-border" striped borderless responsive>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Date</th>
                  <th className="mm-center">Type</th>
                </tr>
              </thead>
              <tbody style={{ marginBottom: "20px" }}>
                {filteredSubPolicies
                  .slice(
                    (currentPage - 1) * itemsPerPage,
                    currentPage * itemsPerPage
                  )
                  .map((log, index) => (
                    <tr key={index}>
                      <td
                        style={{
                          height: "50px",
                          verticalAlign: "middle",
                        }}
                      >
                        {log.name}
                      </td>
                      <td>
                        {moment(log.date).format("Do of MMMM YYYY, h:mm")}
                      </td>
                      <td>{log.type}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>

            <Pagination className="mm-pagination">
              <Pagination.First
                onClick={() => handlePageClick(1)}
                disabled={currentPage === 1}
              />
              <Pagination.Prev
                onClick={() => handlePageClick(currentPage - 1)}
                disabled={currentPage === 1}
              />
              {currentPage > 2 && (
                <Pagination.Item
                  onClick={() => handlePageClick(currentPage - 2)}
                >
                  {currentPage - 2}
                </Pagination.Item>
              )}
              {currentPage > 1 && (
                <Pagination.Item
                  onClick={() => handlePageClick(currentPage - 1)}
                >
                  {currentPage - 1}
                </Pagination.Item>
              )}
              <Pagination.Item active>{currentPage}</Pagination.Item>
              {currentPage < totalPages && (
                <Pagination.Item
                  onClick={() => handlePageClick(currentPage + 1)}
                >
                  {currentPage + 1}
                </Pagination.Item>
              )}
              {currentPage < totalPages - 1 && (
                <Pagination.Item
                  onClick={() => handlePageClick(currentPage + 2)}
                >
                  {currentPage + 2}
                </Pagination.Item>
              )}
              <Pagination.Next
                onClick={() => handlePageClick(currentPage + 1)}
                disabled={currentPage === totalPages}
              />
              <Pagination.Last
                onClick={() => handlePageClick(totalPages)}
                disabled={currentPage === totalPages}
              />
            </Pagination>
          </Card.Body>
        </Card>
      </Modal>

      <Top />
    </Container>
  );
};

export default PolicyCustomer;
