import { faMinus, faPlus, faRemove } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useRef, useEffect } from 'react';
import { cartData } from '../../jsx/backend';

const Cart = () => {
    const [isMinimised, setIsMinimised] = useState(false);
    const cartRef = useRef();
    const [originalLeft, setOriginalLeft] = useState(0);
    const [originalTop, setOriginalTop] = useState(0);
    const [originalHeight, setOriginalHeight] = useState(0);
    const [cartItems, setCartItems] = useState({});

    useEffect(() => {
      const cart = cartRef.current;
      setOriginalLeft(cart.getBoundingClientRect().left);
      setOriginalTop(cart.getBoundingClientRect().top);
    
      const handleMouseDown = (event) => {
        event.preventDefault();
    
        let shiftX = event.clientX - cart.getBoundingClientRect().left;
        let shiftY = event.clientY - cart.getBoundingClientRect().top;
    
        const handleMouseMove = (event) => {
          let newLeft = event.pageX - shiftX;
          let newTop = event.pageY - shiftY;
    
          // Constrain the cart to the viewport
          newLeft = Math.min(Math.max(newLeft, 0), document.policyElement.clientWidth - cart.offsetWidth);
          newTop = Math.min(Math.max(newTop, 0), window.innerHeight - cart.offsetHeight);
    
          cart.style.left = newLeft + 'px';
          cart.style.top = newTop + 'px';
        };
    
        document.addEventListener('mousemove', handleMouseMove);
    
        const handleMouseUp = () => {
          document.removeEventListener('mousemove', handleMouseMove);
          document.removeEventListener('mouseup', handleMouseUp);
        };
    
        // Handle the case where the mouse button is released outside the cart
        document.addEventListener('mouseup', handleMouseUp);
      };
    
      cart.addEventListener('mousedown', handleMouseDown);
    
      return () => {
        cart.removeEventListener('mousedown', handleMouseDown);
      };
    }, []);
    
    const handleMinimiseClick = () => {
      setIsMinimised(!isMinimised);

      if (!isMinimised) {
        const cart = cartRef.current;
        cartRef.current.style.left = originalLeft + 'px';
        cartRef.current.style.top = originalTop + 'px';
        const rect = cart.getBoundingClientRect();
        setOriginalHeight(rect.height);
        const newTop = rect.top + rect.height - 50; // 50 is the height of the cart when minimised
        cart.style.top = newTop + 'px';
      } else {
        // If the cart is being restored, reset its top position
        const cart = cartRef.current;
        const rect = cart.getBoundingClientRect();
        const newTop = rect.top + 50 - originalHeight; // 50 is the height of the cart when minimised
        cart.style.top = newTop + 'px';
      }
    };


    const updateCartItems = () => {
        const items = cartData('get');
        setCartItems(items);
      };

    
    useEffect(() => {
        updateCartItems();

            // Create a function to handle the 'cartChange' event
    const handleCartChange = () => {
        updateCartItems();
      };
  
      // Add an event listener for the 'cartChange' event
      window.addEventListener('cartChange', handleCartChange);
  
      // Remove the event listener when the component unmounts
      return () => {
        window.removeEventListener('cartChange', handleCartChange);
      };
      }, []);

      const renderCartItems = () => {
        return Object.keys(cartItems).map((key) => {
          const item = cartItems[key];
          return (
            <div key={key} className="cart-item mm-small" style={{ display: 'flex', justifyContent: 'space-between', padding: '10px', borderBottom: '1px solid #ddd' }}>
              <div className="cart-item-name" style={{ flex: 1, textAlign: 'center' }}>{item.name}</div>
              <div className="cart-item-price" style={{ flex: 1, textAlign: 'center' }}>${item.price}</div>
              <div className="cart-item-quantity" style={{ flex: 1, textAlign: 'center' }}>
                <button onClick={() => cartData('increase', key, 1)} style={{ marginRight: '5px', backgroundColor: 'green', color: 'white', border: 'none', borderRadius: '4px', padding: '5px 10px', cursor: 'pointer' }} title='Increase Quantity'>+</button>
                {item.quantity}
                <button onClick={() => cartData('decrease', key, 1)} style={{ marginLeft: '5px', backgroundColor: 'orange', color: 'white', border: 'none', borderRadius: '4px', padding: '5px 10px', cursor: 'pointer' }} title='Decrease Quantity'>-</button>
              </div>
              <div className="cart-item-total" style={{ flex: 1, textAlign: 'center' }}>${item.price * item.quantity}</div>
              <button onClick={() => cartData('remove', key)} style={{ backgroundColor: '#f44336', color: 'white', border: 'none', borderRadius: '4px', padding: '5px 10px', cursor: 'pointer' }} title='Delete'><FontAwesomeIcon icon={faRemove} /></button>
            </div>
          );
        });
      };

    const calculateTotalPrice = () => {
        let total = 0;
        Object.keys(cartItems).forEach((key) => {
          const item = cartItems[key];
          total += item.price * item.quantity;
        });
        return total;
      };

  return (
<div id="cart" ref={cartRef} className="cart" style={{height: isMinimised ? '50px' : 'auto'}}>
    <div className="cart-header">
        <h4><span className='mm-red mm-circle mm-badge'>{ Object.keys(cartItems).length }</span> Shopping Cart</h4>
        <button onClick={handleMinimiseClick}>
          {isMinimised ? <FontAwesomeIcon icon={faPlus} /> : <FontAwesomeIcon icon={faMinus} />}
        </button>
      </div>
      {!isMinimised && (
        <div className="cart-body-container">
  <div className="cart-content">
    <div className="cart-body">
      {renderCartItems()}
    </div>
    <div className="cart-total">
      Total Price: <span className='mm-right'>{calculateTotalPrice().toFixed(2)} USD</span>
    </div>
    <button className="cart-checkout-button">CHECKOUT</button>
  </div>
</div>
      )}
    </div>
  );
};

export default Cart;