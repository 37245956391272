import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavLink, useLocation } from "react-router-dom";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faEdit,
  faExclamationTriangle,
  faFileAlt,
  faHomeLgAlt,
  faList,
  faRobot,
  faSignOutAlt,
  faSpinner,
  faTimes,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  Badge,
  Dropdown,
  Offcanvas,
  Spinner,
  Button,
  Container,
  FloatingLabel,
  Row,
} from "react-bootstrap";
import { apiData, localData, runData } from "../../jsx/backend";
import getIcon from "../includes/icons";
import Modal from "react-modal";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import { slugify } from "../includes/cleaning";
import Loader from "../includes/loader";
import { saveAs } from "file-saver";

Modal.setAppElement("#root");
let today = new Date();
today.setHours(0, 0, 0, 0);

const Header = () => {
  const [searchState, setStateSearch] = useState(false);
  const [taskState, setStateTask] = useState(true);
  const [reportState, setStateReport] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [searchPolicies, setSearchPolicies] = useState([]);
  const [searchTemplates, setSearchTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [tasks, setTasks] = useState([]);
  const [completedTasks, setCompletedTasks] = useState([]);
  const [task, setTask] = useState("");
  const [priority, setPriority] = useState("High");
  const [deadline, setDeadline] = useState(new Date());
  const [error, setError] = useState(null);
  const [tasking, setTasking] = useState(false);
  const [taskTotal, setTaskTotal] = useState(0);
  const [errorSearch, setErrorSearch] = useState(null);

  const [running, setRunning] = useState(false);
  const [message, setMessage] = useState("");
  const [report, setReporting] = useState(false);

  const location = useLocation();
  const [downloadRef, setDownloadRef] = useState("");
  const [downloadName, setDownloadName] = useState("Attachment");
  const [triggerDownload, setTriggerDownload] = useState(false);

  const handleDownload = (ref) => {
    setDownloadRef(ref);
    setTriggerDownload(true);
  };

  const downloadFile = async () => {
    setRunning(true);

    let links = {};

    switch (localData("get", "branding").level) {
      case 1:
        links.template = "/";
        break;

      case 2:
        links.template = "/";

        break;

      case 3:
        links.template = "/customer/";
        break;
    }

    const data = {};

    const responseData = await runData(
      data,
      `/query${links.template}templates/download/${downloadRef}/`,
      "blob"
    );
    setRunning(false);
    if (responseData.response && responseData.response.status === 200) {
      setMessage("Your download has been successful!");
      const filename = slugify(downloadName);
      const blob = new Blob([responseData.response.data], {
        type: responseData.response.headers["content-type"],
      });
      saveAs(blob, filename || "download");
      setReporting(true);
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setReporting(true);
    } else {
      setMessage("An error occured while processing your request.");
      setReporting(true);
    }
  };

  useEffect(() => {
    if (triggerDownload) {
      downloadFile();
      setTriggerDownload(false);
    }
  }, [triggerDownload, downloadRef]);

  useEffect(() => {
    const interval = setInterval(() => {
      localData("save", "historical_path", location.pathname);
    }, 1);

    return () => clearInterval(interval);
  }, [location.pathname]);
  const handleTaskChange = (e) => {
    setError(null);
    setTask(e.target.value);
  };

  const handlePriorityChange = (e) => {
    setError(null);
    setPriority(e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      const newTask = {
        date: moment(selectedDate).format("YYYY-MM-DD"),
      };

      const responseData = await runData(newTask, "/query/tasks/list/");

      setTasking(false);

      if (responseData.response) {
        switch (responseData.response.data.code) {
          case 200:
            setTasks(responseData.response.data.pendingTasks);
            setCompletedTasks(responseData.response.data.completedTasks);
            break;
        }
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchTasks = async () => {
      const newTask = {
        date: moment(today).format("YYYY-MM-DD"),
      };
      const responseData = await runData(newTask, "/query/tasks/list/");
      setTasking(false);
      if (responseData.response) {
        switch (responseData.response.data.code) {
          case 200:
            setTaskTotal(responseData.response.data.pendingTasks.length);
            setTasks(responseData.response.data.pendingTasks);
            setCompletedTasks(responseData.response.data.completedTasks);
            break;
        }
      }
    };
    fetchTasks();
    const intervalId = setInterval(fetchTasks, 30000);
    return () => clearInterval(intervalId);
  }, []);

  const addTask = async (e) => {
    e.preventDefault();
    setError(null);
    if (task.trim() === "" || deadline === "") {
      setError(`Please enter a task and select a valid deadline.`);
      return;
    }

    if (deadline <= today) {
      setError("Please select a future date for the deadline.");
      return;
    }
    setTasking(true);
    const newTask = {
      date: moment(selectedDate).format("YYYY-MM-DD"),
      task,
      priority,
      deadline: moment(deadline).format("YYYY-MM-DD"),
    };
    const responseData = await runData(newTask, "/query/tasks/add/");
    setTasking(false);
    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          setTasks(responseData.response.data.pendingTasks);
          setCompletedTasks(responseData.response.data.completedTasks);
          setTask("");
          setPriority("High");
          setDeadline(new Date());
          break;

        default:
          setError(`${responseData.response.data.message}`);
      }
    } else if (responseData.error) {
      setError(responseData.error.message);
    } else {
      setError("An error occured while processing your request.");
    }
  };

  const markDone = async (reference) => {
    setError(null);

    setTasking(true);
    const newTask = {
      reference: reference,
      date: moment(selectedDate).format("YYYY-MM-DD"),
    };
    const responseData = await runData(newTask, "/query/tasks/complete/");
    setTasking(false);
    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          setTasks(responseData.response.data.pendingTasks);
          setCompletedTasks(responseData.response.data.completedTasks);
          break;

        default:
          setError(`${responseData.response.data.message}`);
      }
    } else if (responseData.error) {
      setError(responseData.error.message);
    } else {
      setError("An error occured while processing your request.");
    }
  };

  const handleDateChange = async (date) => {
    setSelectedDate(date);
    const newTask = {
      date: date,
    };
    const responseData = await runData(newTask, "/query/tasks/list/");
    setTasking(false);
    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          setTasks(responseData.response.data.pendingTasks);
          setCompletedTasks(responseData.response.data.completedTasks);
          break;
      }
    }
  };

  const handleSearchInput = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setStateSearch(true);
    setErrorSearch(null);
    const data = {
      search: searchInput,
    };
    const responseData = await runData(data, "/query/search/policies/");
    const responseDataTemplates = await runData(
      data,
      "/query/search/templates/"
    );

    setIsLoading(false);

    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          setSearchPolicies(responseData.response.data.policies);
          break;

        default:
          setErrorSearch(responseData.response.data.message);
      }
    } else if (responseData.error) {
      setErrorSearch(responseData.error.message);
    } else {
      setErrorSearch("There was an error while processing your request.");
    }

    if (responseDataTemplates.response) {
      switch (responseDataTemplates.response.data.code) {
        case 200:
          setSearchTemplates(responseDataTemplates.response.data.templates);
          break;

        default:
          setErrorSearch(responseDataTemplates.response.data.message);
      }
    } else if (responseDataTemplates.error) {
      setErrorSearch(responseDataTemplates.error.message);
    } else {
      setErrorSearch("There was an error while processing your request.");
    }
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCancelSearch = () => {
    setSearchInput("");
    setStateSearch(false);
    setIsLoading(false);
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
    if (event.target.value.length < 1) {
      setStateSearch(false);
      setIsLoading(false);
    }
  };
  const deleteTask = async (reference) => {
    setError(null);

    setTasking(true);
    const newTask = {
      reference: reference,
      date: moment(selectedDate).format("YYYY-MM-DD"),
    };
    const responseData = await runData(newTask, "/query/tasks/delete/");
    setTasking(false);
    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          setTasks(responseData.response.data.pendingTasks);
          setCompletedTasks(responseData.response.data.completedTasks);
          break;

        default:
          setError(`${responseData.response.data.message}`);
      }
    } else if (responseData.error) {
      setError(responseData.error.message);
    } else {
      setError("An error occured while processing your request.");
    }
  };

  const handleLogout = () => {
    localData("remove", "messages");
    localData("remove", "expiry");
    localData("remove", "session");
  };

  const calendarStyles = {
    border: "1px solid #ddd",
    borderRadius: "5px",
    marginBottom: "10px",
    width: "100%",
    padding: "10px",
  };

  let links = {};

  switch (localData("get", "branding").level) {
    case 1:
      links.policies = "/policies/";
      links.templates = "/templates/";
      links.composer = "/composer/";
      links.projects = "/staff/projects/";
      links.drive = "/drive/";
      links.records = "/records/";
      break;

    case 2:
      links.policies = "/manage/policies/";
      links.templates = "/manage/templates/";
      links.composer = "/composer/";
      links.projects = "/staff/projects/";
      links.drive = "/drive/";
      links.records = "/records/";

      break;

    case 3:
      links.policies = "/access/policies/";
      links.templates = "/access/templates/";
      links.composer = "/composer/";
      links.projects = "/staff/projects/";
      links.drive = "/drive/";
      links.records = "/records/";
      break;
  }
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      zIndex: 10000,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "transparent",
      border: "none",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minWidth: "30VW",
    },
  };

  return (
    <>
      <Navbar
        expand="lg"
        sticky="top"
        className="bg-body-tertiary px-3 py-2 mm-card mm-light-grey justify-content-between"
      >
        <Button
          variant="light"
          onClick={handleShow}
          className="me-2 order-lg-1 position-relative"
        >
          <FontAwesomeIcon icon={faCalendarAlt} size="2x" />
          <Badge
            pill
            variant="danger"
            bg="danger"
            className="position-absolute top-0 translate-middle p-2"
            style={{ transform: "translate(70%, -50%)", fontSize: "0.6rem" }}
          >
            {taskTotal}
          </Badge>
        </Button>
        <Navbar.Brand href="/" className="mx-auto order-lg-0">
          <div className="logo">
            {localData("get", "branding").logo ? (
              <div>
                <img
                  src={`${apiData.defaults.baseURL}${
                    localData("get", "branding").logo
                  }`}
                  alt="MENU"
                  style={{
                    borderWidth: "3px !important",
                    flexShrink: 0,
                    maxWidth: "100%",
                    maxHeight: "55px",
                  }}
                />{" "}
              </div>
            ) : (
              <div
                style={{
                  textDecoration: "none",
                  height: "55px",
                  width: "100%",
                }}
                className="parent-img"
              >
                <div
                  className="mm-padding mm-margin"
                  style={{
                    height: "200px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {getIcon("logo")}
                </div>
              </div>
            )}
          </div>
        </Navbar.Brand>

        <Navbar.Toggle
          aria-controls="navbarScroll"
          className="order-lg-2 no-outline btn bg-white"
        />
        <Navbar.Collapse id="navbarScroll" className="ml-3 ml-md-0">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "300px" }}
            navbarScroll
          >
            <Nav.Link
              className="d-none d-md-block"
              style={{ width: "150px" }}
            ></Nav.Link>
            <NavLink to="/" className="nav-link custom-nav-link">
              <FontAwesomeIcon icon={faHomeLgAlt} /> Home
            </NavLink>
            <NavLink to={links.composer} className="nav-link custom-nav-link">
              <FontAwesomeIcon icon={faRobot} /> Composer
            </NavLink>

            <NavLink to={links.policies} className="nav-link custom-nav-link">
              <FontAwesomeIcon icon={faFileAlt} /> Policies
            </NavLink>

            <NavLink to={links.templates} className="nav-link custom-nav-link">
              <FontAwesomeIcon icon={faEdit} /> Templates
            </NavLink>
          </Nav>
          <div style={{ position: "relative" }}>
            <Dropdown.Menu
              style={{
                width: "100%",
                maxHeight: "75vh",
                overflowY: "auto",
                overflowWrap: "break-word",
              }}
              className="mm-padding"
              show={searchState}
            >
              {isLoading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Spinner animation="border" />
                </div>
              ) : (
                <div>
                  {errorSearch ? (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <h4>Error</h4>
                        <FontAwesomeIcon icon={faExclamationTriangle} />
                      </div>
                      <hr />
                      <p className="mm-center">{errorSearch}</p>
                    </div>
                  ) : (
                    <>
                      {searchPolicies.length > 0 && (
                        <>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <h4>Policies</h4>
                            <FontAwesomeIcon icon={faList} />
                          </div>
                          {searchPolicies.map((result) => (
                            <ListGroup
                              key={result.reference}
                              style={{ margin: "10px" }}
                            >
                              <ListGroupItem>
                                <NavLink
                                  to={`${links.policies}${result.package}/${result.category}/${result.reference}/`}
                                  style={{
                                    wordBreak: "break-all",
                                    textDecoration: "none",
                                    color: "black",
                                  }}
                                >
                                  <FontAwesomeIcon icon={faFileAlt} />{" "}
                                  {result.title}
                                </NavLink>
                              </ListGroupItem>
                            </ListGroup>
                          ))}
                        </>
                      )}
                      {searchTemplates.length > 0 && (
                        <>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <h4>Templates</h4>
                            <FontAwesomeIcon icon={faEdit} />
                          </div>
                          {searchTemplates.map((result) => (
                            <ListGroup
                              key={result.reference}
                              style={{ margin: "10px" }}
                            >
                              <ListGroupItem
                                style={{ cursor: "pointer" }}
                                title="Download"
                                onClick={() => {
                                  handleDownload(result.reference);
                                  setDownloadName(result.title);
                                }}
                              >
                                {result.title}
                              </ListGroupItem>
                            </ListGroup>
                          ))}
                        </>
                      )}

                      {searchPolicies.length < 1 &&
                        searchTemplates.length < 1 && (
                          <p className="mm-center">No results found!</p>
                        )}
                    </>
                  )}
                </div>
              )}
            </Dropdown.Menu>
            <Form className="d-flex mm-padding" onSubmit={handleSearchInput}>
              <div
                style={{ display: "flex", alignItems: "center", gap: "20px" }}
              >
                <Dropdown>
                  <Dropdown.Toggle
                    variant="light"
                    className="mm-color no-outline"
                    id="dropdown-basic"
                    style={{ marginRight: "15px" }}
                  >
                    <FontAwesomeIcon icon={faUserAlt} /> Account
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="dropdown-menu-left">
                    <Button
                      onClick={handleLogout}
                      className="dropdown-item text-danger"
                    >
                      <FontAwesomeIcon icon={faSignOutAlt} /> Logout
                    </Button>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <Form.Control
                type="search"
                placeholder="Search policy or template..."
                className="me-2 no-outline"
                aria-label="Search"
                value={searchInput}
                onChange={handleSearchInputChange}
              />
              <Button
                variant="light"
                className="me-2 mm-color-outline"
                onClick={handleSearchInput}
                disabled={!searchInput}
              >
                {isLoading ? "Searching..." : "Search"}
              </Button>
              {searchInput && (
                <Button
                  variant="outline-danger"
                  className="me-2"
                  onClick={handleCancelSearch}
                >
                  Cancel
                </Button>
              )}
            </Form>
          </div>
        </Navbar.Collapse>
      </Navbar>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton className="mm-light-grey">
          <Offcanvas.Title>MY CALENDAR</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <Calendar
            onClickDay={handleDateChange}
            value={selectedDate}
            style={calendarStyles}
          />
          <div
            className="d-flex justify-content-between"
            style={{ marginTop: "30px" }}
          >
            <Button
              onClick={() => {
                setStateReport(false);
                setStateTask(true);
              }}
              variant={taskState ? "success" : "light"}
              className="border"
              style={{
                width: "50%",
                borderTopRightRadius: "0px",
                borderBottomRightRadius: "0px",
              }}
            >
              Manage Tasks
            </Button>
            <Button
              onClick={() => {
                setStateTask(false);
                setStateReport(true);
              }}
              variant={reportState ? "success" : "light"}
              className="border"
              style={{
                width: "50%",
                borderTopLeftRadius: "0px",
                borderBottomLeftRadius: "0px",
              }}
            >
              Manage Reports
            </Button>
          </div>

          {taskState && (
            <div style={{ marginTop: "30px" }}>
              {selectedDate && (
                <div className="tasks">
                  <div
                    className="d-flex justify-content-between"
                    style={{ marginBottom: "30px" }}
                  >
                    <h5>Task Scheduler</h5>{" "}
                    <h5>
                      {formatDate(selectedDate) === formatDate(new Date()) ? (
                        <>Today</>
                      ) : (
                        <>{selectedDate.toLocaleDateString()}</>
                      )}
                    </h5>
                  </div>
                  {error && (
                    <div className="mm-center text-danger">{error}</div>
                  )}
                  {selectedDate >= today && (
                    <Container className="mt-5">
                      {tasking ? (
                        <FontAwesomeIcon icon={faSpinner} size="3x" spin />
                      ) : (
                        <Form onSubmit={addTask}>
                          <FloatingLabel
                            controlId="floatingTextarea"
                            label="Task"
                            className="mb-3"
                          >
                            <Form.Control
                              style={{
                                boxShadow: "none",
                                border: "solid #aaa 1px",
                              }}
                              as="textarea"
                              placeholder="Enter task..."
                              value={task}
                              onChange={handleTaskChange}
                            />
                          </FloatingLabel>
                          <FloatingLabel
                            controlId="floatingSelect"
                            label="Priority"
                            className="mb-3"
                          >
                            <Form.Select
                              value={priority}
                              onChange={handlePriorityChange}
                              style={{
                                boxShadow: "none",
                                border: "solid #aaa 1px",
                              }}
                            >
                              <option value="">Select Priority</option>
                              <option value="High">High</option>
                              <option value="medium">Medium</option>
                              <option value="low">Low</option>
                            </Form.Select>
                          </FloatingLabel>
                          <Row className="mm-padding">
                            <h5>Deadline</h5>
                            <DatePicker
                              selected={deadline}
                              onChange={(date) => {
                                setDeadline(date);
                                setError(null);
                              }}
                              className="date-picker"
                              minDate={new Date()}
                              style={{
                                boxShadow: "none",
                                border: "solid #aaa 1px",
                                width: "100%",
                              }}
                            />
                          </Row>

                          <Button
                            variant="light"
                            type="submit"
                            className="mm-color no-outline"
                            style={{ width: "100%", height: "55px" }}
                          >
                            Add Task
                          </Button>
                        </Form>
                      )}
                    </Container>
                  )}
                  <div
                    className="mm-padding"
                    style={{ marginBottom: "30px", marginTop: "30px" }}
                  >
                    <div
                      className="d-flex justify-content-between"
                      style={{ marginBottom: "30px" }}
                    >
                      <h6 className="heading">Pending Tasks</h6>{" "}
                      <h6>{tasks.length}</h6>
                    </div>
                    <table
                      className="table table-striped table-bordered table-hover task-list"
                      id="task-list"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th className="mm-center">View</th>
                          <th className="mm-center">Priority</th>
                          <th className="mm-center">Deadline</th>
                          <th className="mm-center">Action</th>
                        </tr>
                      </thead>
                      <tbody className="mm-center">
                        {tasks.map((t) => (
                          <tr key={t.reference}>
                            <td>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="light"
                                  id="dropdown-basic"
                                >
                                  Task
                                </Dropdown.Toggle>

                                <Dropdown.Menu
                                  style={{
                                    width: "300px",
                                    overflowWrap: "break-word",
                                  }}
                                  className="mm-padding"
                                >
                                  <p>{t.task}</p>
                                  <br />
                                  <hr />
                                  <NavLink
                                    to={`/projects/${t.project}/${t.reference}/`}
                                  >
                                    <button
                                      style={{ width: "100%" }}
                                      type="button"
                                      className="btn mm-color"
                                    >
                                      Go to Project
                                    </button>
                                  </NavLink>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                            <td>{t.priority}</td>
                            <td>
                              {moment(t.deadline).format("DD MMMM, YYYY")}
                            </td>
                            <td>
                              {!t.done && (
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="success"
                                    id="dropdown-basic"
                                  >
                                    Mark
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      onClick={() => markDone(t.reference)}
                                    >
                                      Done
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() => deleteTask(t.reference)}
                                    >
                                      Delete
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="completed-task-list mm-padding">
                    <div
                      className="d-flex justify-content-between"
                      style={{ marginBottom: "30px" }}
                    >
                      <h6 className="heading">Completed Tasks</h6>{" "}
                      <h6>{completedTasks.length}</h6>
                    </div>
                    <table
                      className="table table-striped table-bordered table-hover"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th className="mm-center">View</th>
                          <th className="mm-center">Priority</th>
                          <th className="mm-center">Completed</th>
                        </tr>
                      </thead>
                      <tbody className="mm-center">
                        {completedTasks.map((ct) => (
                          <tr key={ct.reference}>
                            <td>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="light"
                                  id="dropdown-basic"
                                >
                                  Task
                                </Dropdown.Toggle>

                                <Dropdown.Menu
                                  style={{
                                    width: "300px",
                                    overflowWrap: "break-word",
                                  }}
                                  className="mm-padding"
                                >
                                  <p>{ct.task}</p>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                            <td>{ct.priority}</td>
                            <td>
                              {moment(ct.completed).format("DD MMMM, YYYY")}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          )}

          {reportState && <div style={{ marginTop: "30px" }}></div>}
          <div className="mm-padding" style={{ marginTop: "30px" }}>
            <span style={{ textAlign: "center" }}>
              &copy;{new Date().getFullYear()}{" "}
              <a
                href={localData("get", "branding").website || "/"}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                {localData("get", "branding").company || "Portal"}
              </a>{" "}
              - All Rights Reserved
            </span>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <Modal isOpen={running} style={customStyles}>
        <div
          className="mm-center"
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <h4 style={{ color: "#343a40" }}>Please wait...</h4>
          <br />
          <Loader />
        </div>
      </Modal>

      <Modal
        isOpen={report}
        onRequestClose={() => setReporting(false)}
        style={customStyles}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <h4>
            Message{" "}
            <span
              onClick={() => setReporting(false)}
              title="Close"
              className="mm-right"
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={faTimes} color="red" />
            </span>
          </h4>

          <hr />

          <p className="mm-center">{message}</p>

          <br />
          <button
            className="mm-btn mm-red"
            onClick={() => setReporting(false)}
            style={{ borderRadius: "30px", width: "100%" }}
          >
            CLOSE
          </button>
        </div>
      </Modal>
    </>
  );
};

export default Header;
