import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Top from "../../includes/topping";
import { localData, runData } from "../../../jsx/backend";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-datepicker/dist/react-datepicker.css";
import {
  faArrowRight,
  faNetworkWired,
  faPlusSquare,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import Loader from "../../includes/loader";
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  Form,
  Pagination,
  Row,
  Table,
} from "react-bootstrap";
import RenderModifyOption from "./staff.modify";
import RenderDeleteOption from "./staff.delete";
import RenderAssignOption from "./staff.assign";
import { Link } from "react-router-dom";
import SideBar from "../../sections/sidebar";

Modal.setAppElement("#root");

const Staff = () => {
  const [subStaff, setSubStaff] = useState([]);
  const [running, setRunning] = useState(false);
  const [message, setMessage] = useState("");
  const [report, setReporting] = useState(false);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const userRole = localData("get", "session").role;
  const filteredSubStaff = subStaff.filter((user) =>
    `${user.name} ${user.surname}`.toLowerCase().includes(search.toLowerCase())
  );

  const totalPages = Math.ceil(filteredSubStaff.length / itemsPerPage);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };
  const [addUser, setAddUser] = useState(false);

  const [user, setUser] = useState({
    name: "",
    surname: "",
    occupation: "",
    phone: "",
    email: "",
  });

  const handleChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const createUser = async (e) => {
    e.preventDefault();
    setAddUser(false);
    setRunning(true);

    const data = {
      name: user.name,
      surname: user.surname,
      email: user.email,
    };

    for (let key in data) {
      if (data[key] === "") {
        setRunning(false);
        setMessage(`Please fill in the ${key} field. It is required`);
        setReporting(true);
        return;
      }
    }

    const responseData = await runData(data, `/query/staff/invite/`);

    setRunning(false);

    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          setMessage(
            "You have successfully invited staff to your organisation!"
          );
          setReporting(true);
          break;
        default:
          setMessage(responseData.response.data.message);
          setReporting(true);
      }
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setReporting(true);
    } else {
      setMessage("An error occurred while processing your request.");
      setReporting(true);
    }
  };

  const listStaff = async () => {
    const data = {};
    const responseData = await runData(data, "/query/staff/");

    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          setSubStaff(responseData.response.data.users);
          break;

        default:
          setMessage(responseData.response.data.message);
          setReporting(true);
      }
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setReporting(true);
    } else {
      setMessage("An error occured while processing your request.");
      setReporting(true);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (localData("get", "staff_refresh") === 1) {
        listStaff();
        localData("save", "staff_refresh", 0);
      }
    }, 1);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    listStaff();
    const intervalId = setInterval(() => {
      listStaff();
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  const customStyles = {
    overlay: {
      backgroundColor: "#ffffffeb",
      zIndex: 10000,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "transparent",
      border: "none",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minWidth: "30VW",
    },
  };

  return (
    <Container style={{ minHeight: "95VH", wordWrap: "break-word" }} fluid>
      <Helmet>
        <title>
          Staff - {localData("get", "branding").company || "Company"}
        </title>
      </Helmet>

      <Row className="mt-3 mm-padding-large">
        <Col md={9}>
          <Card id="users" className="mb-4">
            <Card.Header>
              <Row className="d-flex align-items-center justify-content-between">
                <Col md={6}>
                  <h6>Staff</h6>
                </Col>
                <Col
                  md={6}
                  className="d-flex align-items-start justify-content-end"
                >
                  <Button
                    variant="link"
                    onClick={() =>
                      userRole === 1 || userRole === 2 ? setAddUser(true) : null
                    }
                    style={{ color: "black", textDecoration: "none" }}
                    title={
                      userRole === 1 || userRole === 2
                        ? "Invite Staff"
                        : "Coordinate"
                    }
                  >
                    <FontAwesomeIcon
                      icon={
                        userRole === 1 || userRole === 2
                          ? faPlusSquare
                          : faNetworkWired
                      }
                    />
                    {userRole === 1 || userRole === 2
                      ? " Invite Staff"
                      : " Coordinate"}
                  </Button>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <Form.Control
                type="text"
                className="no-outline mm-pale-green"
                style={{ borderRadius: "0px" }}
                placeholder="Search staff..."
                value={search}
                onChange={handleSearchChange}
              />

              <Table className="no-border" striped borderless responsive>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Occupation</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Role</th>
                    <th className="mm-center">Action</th>
                  </tr>
                </thead>
                <tbody style={{ marginBottom: "20px" }}>
                  {filteredSubStaff
                    .slice(
                      (currentPage - 1) * itemsPerPage,
                      currentPage * itemsPerPage
                    )
                    .map((user, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            backgroundColor:
                              user.status === 2 && "rgb(213, 184, 184)",
                            verticalAlign: "middle",
                          }}
                        >
                          {user.name} {user.surname}
                        </td>
                        <td
                          style={{
                            backgroundColor:
                              user.status === 2 && "rgb(213, 184, 184)",
                            verticalAlign: "middle",
                          }}
                        >
                          {user.occupation}
                        </td>
                        <td
                          style={{
                            backgroundColor:
                              user.status === 2 && "rgb(213, 184, 184)",
                            verticalAlign: "middle",
                          }}
                        >
                          {user.email}
                        </td>
                        <td
                          style={{
                            backgroundColor:
                              user.status === 2 && "rgb(213, 184, 184)",
                            verticalAlign: "middle",
                          }}
                        >
                          {user.phone}
                        </td>
                        <td
                          style={{
                            backgroundColor:
                              user.status === 2 && "rgb(213, 184, 184)",
                            verticalAlign: "middle",
                          }}
                        >
                          {user.role === 1
                            ? "Admin"
                            : user.role === 2
                            ? "Manager"
                            : user.role === 3
                            ? "Supervisor"
                            : "Staff"}
                        </td>
                        <td
                          style={{
                            backgroundColor:
                              user.status === 2 && "rgb(213, 184, 184)",
                            verticalAlign: "middle",
                          }}
                        >
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="light"
                              id="dropdown-basic"
                              className="mm-color no-outline"
                              style={{ width: "100%" }}
                            >
                              Options
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{ width: "100%" }}>
                              <RenderModifyOption user={user} />
                              <RenderAssignOption user={user} />
                              <Link
                                to={`/staff/projects/${user.reference}/`}
                                className="dropdown-item"
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  Projects
                                  <FontAwesomeIcon icon={faArrowRight} />
                                </div>
                              </Link>
                              <RenderDeleteOption user={user} />
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>

              <Pagination className="mm-pagination">
                <Pagination.First
                  onClick={() => handlePageClick(1)}
                  disabled={currentPage === 1}
                />
                <Pagination.Prev
                  onClick={() => handlePageClick(currentPage - 1)}
                  disabled={currentPage === 1}
                />
                {currentPage > 2 && (
                  <Pagination.Item
                    onClick={() => handlePageClick(currentPage - 2)}
                  >
                    {currentPage - 2}
                  </Pagination.Item>
                )}
                {currentPage > 1 && (
                  <Pagination.Item
                    onClick={() => handlePageClick(currentPage - 1)}
                  >
                    {currentPage - 1}
                  </Pagination.Item>
                )}
                <Pagination.Item active>{currentPage}</Pagination.Item>
                {currentPage < totalPages && (
                  <Pagination.Item
                    onClick={() => handlePageClick(currentPage + 1)}
                  >
                    {currentPage + 1}
                  </Pagination.Item>
                )}
                {currentPage < totalPages - 1 && (
                  <Pagination.Item
                    onClick={() => handlePageClick(currentPage + 2)}
                  >
                    {currentPage + 2}
                  </Pagination.Item>
                )}
                <Pagination.Next
                  onClick={() => handlePageClick(currentPage + 1)}
                  disabled={currentPage === totalPages}
                />
                <Pagination.Last
                  onClick={() => handlePageClick(totalPages)}
                  disabled={currentPage === totalPages}
                />
              </Pagination>
            </Card.Body>
          </Card>
        </Col>
        <SideBar />
      </Row>

      <Modal
        isOpen={addUser}
        onRequestClose={() => setAddUser(false)}
        style={customStyles}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
            maxWidth: "500px",
          }}
        >
          <form
            onSubmit={createUser}
            className="mm-padding"
            style={{ marginBottom: "30px" }}
          >
            <h6 className="mm-center" style={{ marginBottom: "30px" }}>
              Invite Staff
            </h6>
            <label>First Name</label>
            <input
              className="marmasco-input no-outline"
              style={{
                width: "100%",
                marginBottom: "10px",
                padding: "10px",
                borderRadius: "5px",
              }}
              type="text"
              name="name"
              value={user.name}
              onChange={handleChange}
              placeholder="Name"
            />
            <label>Last Name</label>
            <input
              className="marmasco-input no-outline"
              style={{
                width: "100%",
                marginBottom: "10px",
                padding: "10px",
                borderRadius: "5px",
              }}
              type="text"
              name="surname"
              value={user.surname}
              onChange={handleChange}
              placeholder="Surname"
            />
            <label>Email</label>
            <input
              className="marmasco-input no-outline"
              style={{
                width: "100%",
                marginBottom: "10px",
                padding: "10px",
                borderRadius: "5px",
              }}
              type="email"
              name="email"
              value={user.email}
              onChange={handleChange}
              placeholder="Email"
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <Button
                onClick={() => setAddUser(false)}
                variant="danger"
                className="mm-left"
                type="button"
                style={{
                  width: "48%",
                  color: "#fff",
                  padding: "10px",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                  transition: "background-color 0.3s ease",
                  fontSize: "16px",
                }}
              >
                Cancel
              </Button>
              <button
                className="mm-right"
                type="submit"
                style={{
                  backgroundColor:
                    localData("get", "branding").color || "#eeeeee",
                  width: "48%",
                  color: "#fff",
                  padding: "10px",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                  transition: "background-color 0.3s ease",
                  fontSize: "16px",
                }}
              >
                Send Invitation
              </button>
            </div>
          </form>
        </div>
      </Modal>

      <Modal isOpen={running} style={customStyles}>
        <div
          className="mm-center"
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <h6 style={{ color: "#343a40" }}>Please wait...</h6>
          <br />
          <Loader />
        </div>
      </Modal>

      <Modal
        isOpen={report}
        onRequestClose={() => setReporting(false)}
        style={customStyles}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <h6>
            Message{" "}
            <span
              onClick={() => setReporting(false)}
              title="Close"
              className="mm-right"
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={faTimes} color="red" />
            </span>
          </h6>

          <hr />

          <p className="mm-center">{message}</p>

          <br />
          <button
            className="mm-btn mm-red"
            onClick={() => setReporting(false)}
            style={{ borderRadius: "30px", width: "100%" }}
          >
            CLOSE
          </button>
        </div>
      </Modal>
      <Top />
    </Container>
  );
};

export default Staff;
